import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import List from "../../../../components/Table/List";
import AuthService from "../../../../services/auth.service";
import { useToast } from "../../../../hooks";
import GroupsService from "../../../../services/groups.service";
import UserService from "../../../../services/user.service";
import ModalDisplay from "../../../../layouts/ModalDisplay";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "first_name", model: null },
  { text: "Apellido", field: "last_name", model: null },
  { text: "Usuario", field: "user_name", model: null },
  { text: "Email", field: "email", model: null },
  { text: "Rol", field: "profile_id", model: null },
  { text: "", field: null, model: null },
];

const GroupsUsersEdit = (props) => {
  const params = useParams();
  const group_id = params.id;
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });
  const countItems = 999;
  const addToast = useToast();
  const [usersList, setUsersList] = useState([]);
  const [dropdownKey, setDropdownKey] = useState(0);
  const [userToDelete, setUserToDelete] = useState(false);

  const updateSelectUsersList = async () => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    const permissions = AuthService.getCurrentPermissions("USUARIOS");
    setCurrentPermissions(permissions);

    try {
      const response = await UserService.getAll(params);
      if (response.data.count > 0) {
        const filteredUsers = response.data.response
          .map((obj) => ({
            value: obj?.id,
            text: obj?.email,
          }))
          .filter(
            (user) =>
              body.some((userInBody) => userInBody.id === user.value) === false
          );

        setUsersList(filteredUsers);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    updateSelectUsersList();
    // eslint-disable-next-line
  }, [body]);

  const updateGroupUsersList = async () => {
    const params = {
      id: group_id,
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };

    try {
      const response = await GroupsService.getUsersFromGroup(params);
      if (response.data.success) {
        setBody(response.data.data);
        setTotalItems(response.data.data.length);
        const result = Math.ceil(response.data.data.length / countItems);
        setTotalPage(result);
      }
    } catch (error) {
      // Handle any errors here
      console.error("Error fetching group users:", error);
    }
  };

  useEffect(() => {
    updateGroupUsersList();
    // eslint-disable-next-line
  }, [currentPage, currentSort]);

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <button
            onClick={() => setUserToDelete(props.id)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </>
    );
  };

  const handleDelete = async (id) => {
    setUserToDelete(false);

    try {
      await GroupsService.removeUserFromGroup({
        id: group_id,
        userId: id,
      });
      // Add the removed user back to usersList
      const userToRemoveFromGroup = body.find((user) => user.id === id);
      setUsersList((prevList) => [
        ...prevList,
        {
          value: userToRemoveFromGroup?.id,
          text: userToRemoveFromGroup?.email,
        },
      ]);
      // Update the body state without the removed user
      setBody((prevBody) => prevBody.filter((user) => user.id !== id));
      setTotalItems(totalItems - 1);
      const result = Math.ceil((totalItems - 1) / countItems);
      setTotalPage(result);
      addToast({
        variant: "warning",
        header: "Grupos",
        body: "Se quitó al usuario del grupo correctamente",
      });
    } catch (error) {
      console.error("Error removing user from group:", error);
      addToast({
        variant: "danger",
        header: "Grupos",
        body: "No se pudo quitar al usuario del grupo",
      });
    }
  };

  const handleAddUser = async (e) => {
    let selectedUserId;
    if (e.length === 1) selectedUserId = e[0].value;
    else selectedUserId = e.at(-1).value;

    try {
      await GroupsService.addUserToGroup({
        group_id: group_id,
        user_id: selectedUserId,
      });
      await updateGroupUsersList();
      addToast({
        variant: "success",
        header: "Usuarios",
        body: "Se agregó el usuario al grupo correctamente",
      });
      setDropdownKey((prevKey) => prevKey + 1);
    } catch (error) {
      addToast({
        variant: "danger",
        header: "Usuarios",
        body: "No se pudo agregar el usuario al grupo",
      });
    }
  };

  return (
    <>
      <h4>Lista de usuarios</h4>
      <span className="lead fs-6 text-primary">
        Para agregar usuarios al grupo, seleccionar desde la siguiente lista.
      </span>
      <div className="content-filters row pb-4">
        <div className="col-sm-4 col-lg-12">
          <div
            className="form-group"
            style={{ marginRight: "20px", marginBottom: 10 }}
          >
            <Multiselect
              key={dropdownKey}
              name="users"
              options={usersList}
              onSelect={handleAddUser}
              displayValue="text"
              placeholder="Seleccione usuarios"
              closeIcon="cancel"
              hideSelectedList
              showCheckbox={false}
              singleSelect={false}
            />
          </div>
        </div>
      </div>
      <span className="lead fs-6 text-secondary font-italic">
        Los cambios realizados en el listado de usuarios se guardan instantáneamente.
      </span>
      <br />
      <List
        thead={headSort}
        tbody={body}
        actions={actions}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      <ModalDisplay
        content={
          <div className="d-flex justify-content-end gap-4">
            <Button
              className="pull-right btn btn-danger"
              onClick={() => setUserToDelete(false)}
            >
              Cerrar
            </Button>
            <Button
              className="btn-fill pull-right btn btn-danger"
              onClick={() => handleDelete(userToDelete)}
            >
              Eliminar
            </Button>
          </div>
        }
        headerTitle="¿Desear eliminar al usuario del grupo?"
        size="md"
        showModal={userToDelete}
        handleClose={() => setUserToDelete(false)}
      />
    </>
  );
};

export default GroupsUsersEdit;
