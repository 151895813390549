import React, { useEffect, useState } from "react";
import { Badge, Container } from "react-bootstrap";
import Filters from "../../components/Filters";
import Edit from "../../components/Modal/Sells/presentation/edit";
import Observation from "../../components/Modal/Sells/presentation/observation";
import RejectSell from "../../components/Modal/Sells/rejectSell";
import List from "../../components/Table/List.js";
import ExcelButton from "../../components/ExcelButton";
import { useFilters, useGrid, useStatusModal } from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import AuthService from "../../services/auth.service";
import SellsPresentationService from "../../services/sells/sellsPresentation.service";
import userService from "../../services/user.service";
import uploadService from "../../services/upload.service";
import {
  agentProfiles,
  fileSections,
  sells_status,
  sells_status_select,
  yesNoOptions,
} from "../../variables/constants";
import {
  FINANCED_STATUS_NAME,
  providerOptions,
} from "../../variables/sells/index";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  twoBadges,
} from "../../utils/Badges";
import {
  agents,
  issuerEntities,
  sellEmployees,
  zones,
} from "../../utils/Service";
import { downloadAllZip } from "../../utils/File";
import {
  headPresentation,
  headPresentationAgent,
} from "./constants/constants_columns";
import styles from "../Purchase/General/styles.module.scss";
import ButtonIcon from "components/ButtonIcon";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 2,
  },
  presentation_date: {
    type: "select",
    name: "presentation_date",
    options: yesNoOptions,
    placeholder: "Fecha presentación",
    value: "",
    size: 2,
  },
  take_date: {
    type: "select",
    name: "take_date",
    options: yesNoOptions,
    placeholder: "Fecha Retiro",
    value: "",
    size: 2,
  },
  zone: {
    type: "select",
    name: "zone",
    options: zones(),
    placeholder: "Seleccione una zona",
    value: "",
    size: 2,
  },
  agents: {
    type: "select",
    name: "agents",
    options: agents(),
    placeholder: "Gestor que lo presenta",
    value: "",
    size: 2,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: sellEmployees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 2,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 2,
  },
  issuerEntityId: {
    type: "multiSelect",
    name: "issuerEntityId",
    options: issuerEntities(),
    placeholder: "Seleccione un registro",
    value: [],
    size: 4,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: sells_status_select.filter((x) => x.group === "PRESENTATION"),
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};

const observedStatus = [
  sells_status.PRESENTATION_PRESENTED.value,
  sells_status.PRESENTATION_OBSERVED_PRESENTED.value,
  sells_status.PRESENTATION_CAT_RECEIVED.value,
];

const PresentationSellList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const {
    showEditModal,
    handleCloseEditModal,
    handleOpenEditModal,
    showRejectModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showAddModal: showObservationModal,
    handleCloseAddModal: handleCloseObservationModal,
    handleOpenAddModal: handleShowObservationModal,
  } = useStatusModal();
  const {
    body,
    countItems,
    currentPage,
    currentSort,
    refresh,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    totalItems,
    totalPage,
    refreshGrid,
    cleanPage,
  } = useGrid({
    field: "opportunity_id",
    type: "ASC",
    model: null,
  });

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);

  const [dataModal, setDataModal] = useState([]);
  const [profile, setProfile] = useState(null);
  const [agentOptions, setAgentOptions] = useState([]);
  const [agentId, setAgentId] = useState(null);

  //PAGINATE
  const next = {
    link: "/admin/general/list",
    text: "General",
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
    setAgentId(user.data.user.id);

    const params = { currentPage: 0, countItems: 1000, profile: "agent" };
    userService.getAllByProfile(params).then((response) => {
      if (response.data.count > 0) {
        setAgentOptions(
          response.data.response.map((r) => ({ value: r.id, text: r.name }))
        );
      }
    });
  }, []);

  useEffect(() => {
    if (profile) {
      if (agentProfiles.includes(profile)) {
        filters.presentation_date.size = 3;
        filters.take_date.size = 3;
        filters.agents = {
          hide: true,
          value: agentId,
        };
      }
      const params = {
        page: currentPage,
        limit: countItems,
        sortField: currentSort?.field,
        sortType: currentSort?.type,
        sortModel: currentSort?.model,
        filters,
      };

      SellsPresentationService.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        let arraydata = agentProfiles.includes(profile)
          ? sortColumnsAgent(response.data.response)
          : sortColumns(response.data.response);
        handleBody(arraydata);
      });
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters, profile]);

  const openEditModal = (id) => {
    SellsPresentationService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const openObservationModal = (id) => {
    SellsPresentationService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleShowObservationModal();
      }
    });
  };

  const downloadColumn = (data) => {
    const showIcons =
      currentPermissions &&
      currentPermissions.permissions.hasSelect &&
      !agentProfiles.includes(profile) &&
      data.imagenes > 0;
    return showIcons ? (
      <ButtonIcon
        onClick={(e) => handleDownload(data, e)}
        name="download"
        color="secondary"
      />
    ) : null;
  };

  const sortColumns = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      stock_id: e.stock_id,
      status: genericBadge(sells_status, e.status, true),
      financing_provider: e.financing_provider,
      delivery_type: e.delivery_type,
      issuer_entity_tentative: e.issuer_entity_tentative,
      zone: e.zone,
      agent: e.agent,
      waiting_date: e.waiting_date,
      person_in_charge_id: e.person_in_charge_id,
      turn_request_date: e.turn_request_date,
      presented_date: e.presented_date,
      turn_take_date: e.turn_take_date,
      retired_date: e.retired_date,
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
      bacs_id: badgeGenericBoolean(e.bacs_id),
      imagenes: downloadColumn(e.imagenes),
      _status: sells_status[e.status].value,
    }));

  const sortColumnsAgent = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      status: genericBadge(sells_status, e.status, true),
      delivery_type: e.delivery_type,
      issuer_entity_tentative: e.issuer_entity_tentative,
      zone: e.zone,
      waiting_date: e.waiting_date,
      person_in_charge_id: e.person_in_charge_id,
      turn_request_date: e.turn_request_date,
      presented_date: e.presented_date,
      turn_take_date: e.turn_take_date,
      retired_date: e.retired_date,
      aging: diffDateBadge(e.aging),
      diff_days: e.diff_days,
      bacs_id: badgeGenericBoolean(e.bacs_id),
      _status: sells_status[e.status].value,
    }));

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions(
      "PRESENTACION VENTAS"
    );
    setCurrentPermissions(permissions);
  }, []);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <ButtonIcon
            onClick={() => openEditModal(props.opportunity_id)}
            name="file-alt"
            title="Editar"
          />
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasSelect &&
        observedStatus.includes(props._status) ? (
          <ButtonIcon
            onClick={() => openObservationModal(props.opportunity_id)}
            name="eye"
            color="warning"
            title="Observar"
          />
        ) : null}
        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <ButtonIcon
            onClick={() => handleShowRejectModal(props.opportunity_id)}
            name="times"
            color="danger"
            size="lg"
            title="Rechazar"
          />
        ) : null}
      </>
    );
  };

  const handleDownload = (data, e) => {
    e.preventDefault();
    const params = {
      countItems: 999,
      currentPage: 0,
      opportunity_id: data.opportunity_id,
      vehicle_id: null,
      plateNumber: data.plate_number,
      section: fileSections.presentation,
    };
    uploadService.download(params).then((response) => {
      if (response.data.success) {
        downloadAllZip(response.data, data.plate_number);
      }
    });
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton
          type={`ventasPresentacion${
            agentProfiles.includes(profile) ? "Gestor" : ""
          }`}
          filters={filters}
        />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={
          agentProfiles.includes(profile)
            ? headPresentationAgent
            : headPresentation
        }
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              profile={profile}
              agentOptions={agentOptions}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
          headerTitle={
            <Badge bg="warning">
              Metodo de pago{" "}
              {dataModal.response.financed
                ? FINANCED_STATUS_NAME.FINANCED
                : FINANCED_STATUS_NAME.CASH}
            </Badge>
          }
        />
      )}
      {showObservationModal && (
        <Modal
          content={
            <Observation
              data={dataModal}
              profile={profile}
              handleClose={handleCloseObservationModal}
              refresh={refreshGrid}
            />
          }
          showModal={showObservationModal}
          handleShow={openObservationModal}
          handleClose={handleCloseObservationModal}
          headerTitle={
            <div className="d-flex align-items-center">
              {genericBadge(sells_status, dataModal?.response?.status, true)}
              <span className="ms-2 fs-5 fw-semibold">
                Observación - Carga de requisitos
              </span>
            </div>
          }
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default PresentationSellList;
