import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import { Badge, Button } from "react-bootstrap";

import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";
import PresentationService from "../../../services/purchase/presentation.service";

import Summary from "./summary";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import { Comments } from "../../Comments";
import SeeForms from "../../CheckForms";
import {
  constants,
  status08,
  purchaseStatus,
} from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks";
import { purchaseComment } from "../../../utils/Comments";

const received08s = [
  status08.RECEIVED.value,
  status08.OBSERVED_RECEIVED.value,
  status08.COMPLETED.value,
];

const fieldsOptional = [
  "comment",
  "comment_reason_id",
  "final_payment_date",
  // "loss_request",
  // "vehicle_price",
];

const Checklist = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        createdAt,
        sdp,
        form_08,
        status: purchase_file_status,
        transaction_type_id,
        pledge,
        inspector,
        is_recived,
        // vehicle_price,
        // loss_request,
        final_payment_date,
        reception_place_id,
        canceled_status_id,
        restore_status,
        diff_days,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        issuer_entity_id,
        issuer_entity,
      },
    },
    handleModifiedStatus,
    handleModifiedFormsValidated,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    createdAt,
    purchase_file_status,
    transaction_type_id,
    pledge,
    is_recived,
    // vehicle_price,
    comment_reason_id: null,
    comment: null,
    // loss_request,
    final_payment_date,
    reception_place_id,
    canceled_status_id,
    form_08: {
      ...form_08.purchase_file_form,
      has_form_08: received08s.includes(
        form_08.purchase_file_form.current_status
      ),
    },
    // issuer_entity_tentative_id,
    // issuer_entity_tentative,
    issuer_entity_id,
    issuer_entity,
  };

  // Campos de solo lectura para el popup
  const readOnlyfields = {
    vehicle_id,
    plate_number,
    sdp,
    inspector,
    is_recived,
    purchase_file_status,
    forms: {
      form_08,
    },
    diff_days,
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [forms, setForms] = useState([]);

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });

    // Motivos de los comentarios
    ManagementService.getCommentReasons().then((response) => {
      if (response.data.success) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    });

    // Formularios asignados a la toma
    PresentationService.getPurchaseForms(vehicle_id).then((response) => {
      if (response.data.success) {
        setForms(response.data.response.sort((x) => x.code));
      }
    });
    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Validaciones de campos completos obligatorios
    const simplesFields =
      Object.entries(fields).filter(
        (f) =>
          !fieldsOptional.includes(f[0]) &&
          (f[1] === null || f[1] === undefined || f[1] === "")
      ).length === 0;
    const checkboxs = fields.is_recived && fields.form_08.has_form_08;
    const specialConditions =
      // !!fields.issuer_entity_tentative_id &&
      // fields.issuer_entity_tentative_id != "1" &&
      !!fields.issuer_entity_id && fields.issuer_entity_id != "1";

    setAllowSubmit(simplesFields && checkboxs && specialConditions);

    if (submitAction) {
      const dataVehicle = {
        ...fields,
        vehicle_id,
      };
      // sacamos los campos que no son del tramite principal
      delete dataVehicle.form_08;

      const forms = {
        vehicle_id,
        plate_number,
        form_08_id: form_08.purchase_file_form.id,
        has_form_08: fields.form_08.has_form_08,
      };

      handleModifiedStatus(dataVehicle);
      handleModifiedFormsValidated(forms);
      setSubmitAction(false);
      handleClose();
    }
  }, [
    submitAction,
    fields,
    plate_number,
    vehicle_id,
    form_08,
    handleModifiedStatus,
    handleModifiedFormsValidated,
    handleClose,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  // Manejo generico del cambio de un dato complejo de un formulario, como ser un objeto
  const onComplexFieldChange = (primaryKey, valueKey, value) => {
    const { [primaryKey]: item } = fields;
    const field = { [primaryKey]: { ...item, [valueKey]: value } };
    setFields({
      ...fields,
      ...field,
    });
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Summary
        fields={fields}
        readOnlyfields={readOnlyfields}
        onSimpleFieldChange={onSimpleFieldChange}
        onComplexFieldChange={onComplexFieldChange}
        setFields={setFields}
      />
      <SeeForms forms={forms} />
      <Form>
        <Comments
          reasons={reasons}
          comments={purchaseComment(comments)}
          statusList={purchaseStatus}
          onChangeSelect={(e) =>
            onSimpleFieldChange("comment_reason_id", e.target.value)
          }
          onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
        />
      </Form>
      <br />
      <div className="modal-footer">
        <div>
          <Button
            className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
            onClick={() => setSubmitAction(true)}
          >
            {constants.update}
          </Button>
        </div>
        {purchase_file_status !== purchaseStatus.VALIDATION_EXCEPTION.value ? (
          <div className="row">
            <Button
              className="btn-fill pull-right btn btn-warning btn-sm capitalize"
              value={purchaseStatus.VALIDATION_EXCEPTION.value}
              onClick={(e) => {
                handlePreConfirmationPurchaseModal(
                  "purchase_file_status",
                  e.target.value,
                  purchaseStatus.VALIDATION_EXCEPTION.text,
                  handleChangeStatus
                );
              }}
            >
              {purchaseStatus.VALIDATION_EXCEPTION.text}
            </Button>
          </div>
        ) : null}
        {/* <div className="row">
          <Button
            className="btn-fill pull-right btn btn-danger btn-sm capitalize"
            value={
              purchase_file_status !== purchaseStatus.PURCHASE_OBSERVED.value
                ? purchaseStatus.PURCHASE_OBSERVED.value
                : restore_status
            }
            onClick={(e) => {
              handlePreConfirmationPurchaseModal(
                "purchase_file_status",
                e.target.value,
                purchaseStatus[
                  purchase_file_status !==
                  purchaseStatus.PURCHASE_OBSERVED.value
                    ? "PURCHASE_OBSERVED"
                    : restore_status
                ].text,
                handleChangeStatus
              );
            }}
            disabled={
              !fields.comment?.length ||
              fields.comment_reason_id == null ||
              fields.comment_reason_id === constants.dropdownDefault
            }
          >
            {purchase_file_status !== purchaseStatus.PURCHASE_OBSERVED.value
              ? "Observar"
              : "Desobservar"}
          </Button>
        </div> */}
        <div>
          <Button
            className="btn-fill pull-right btn btn-primary btn-sm"
            value={
              purchase_file_status === purchaseStatus.PURCHASE_OBSERVED.value
                ? purchaseStatus.FOLIO_IN_PROGRESS_OBSERVED.value
                : purchaseStatus.FOLIO_IN_PROGRESS.value
            }
            onClick={(e) => {
              handlePreConfirmationPurchaseModal(
                "purchase_file_status",
                e.target.value,
                purchaseStatus[
                  purchase_file_status ===
                  purchaseStatus.PURCHASE_OBSERVED.value
                    ? "FOLIO_IN_PROGRESS_OBSERVED"
                    : "FOLIO_IN_PROGRESS"
                ].text,
                handleChangeStatus
              );
            }}
            disabled={!allowSubmit}
          >
            {constants.accept}
          </Button>
        </div>
      </div>
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default Checklist;
