import { DatePickerField } from "components/Form";
import React from "react";
import { constants, sells_status } from "../../../../../variables/constants";
const Presentation = ({
  fields,
  onSimpleFieldChange,
  status,
  step1,
  agentPresented,
  turnRequestDateDisabled,
  turnRequestScheduleDisabled,
  presented_date,
  turn_request_date,
  rendition_link_presentation,
}) => {
  return (
    <div className="row">
      <div className="col-12">
        <label className="capitalize">
          <strong>Presentación</strong>
        </label>
      </div>
      <div className="col-3">
        <DatePickerField
          label={"Fecha Turno"}
          selected={fields.turn_request_date}
          onChange={(date) => onSimpleFieldChange("turn_request_date", date)}
          dateFormat="dd/MM/yyyy"
          disabled={turnRequestDateDisabled}
        />
      </div>
      <div className={`col-3${!turnRequestDateDisabled ? " hidden" : ""}`}>
        <DatePickerField
          label={constants.reschedule}
          selected={fields.turn_request_schedule}
          onChange={(date) =>
            onSimpleFieldChange("turn_request_schedule", date)
          }
          dateFormat="dd/MM/yyyy"
          disabled={turnRequestScheduleDisabled}
        />
      </div>
      <div className={`col-3${step1.includes(status) ? " hidden" : ""}`}>
        <DatePickerField
          selected={presented_date == null ? null : new Date(presented_date)}
          dateFormat="dd/MM/yyyy"
          disabled
          label={"Fecha Real"}
        />
      </div>
      <div
        className={`col-3${
          step1.includes(status) || agentPresented == null ? " hidden" : ""
        }`}
      >
        <label className="capitalize">
          <strong>{constants.presentedAgent}</strong>
        </label>
        <div>{agentPresented != null ? agentPresented : ""}</div>
      </div>
      <div className="col-6">
        <div className="col">
          <label className="capitalize">
            <strong>Link Rendición</strong>
          </label>
          <div>
            <input
              type="url"
              className="form-control"
              name="rendition_link_presented"
              autoComplete="false"
              onChange={(e) =>
                onSimpleFieldChange("rendition_link_presented", e.target.value)
              }
              value={fields.rendition_link_presented}
              maxLength={200}
              disabled={status === sells_status.PRESENTATION_WAITING.value}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Presentation;
