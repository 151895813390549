import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import Multiselect from "multiselect-react-dropdown";

import { useConfirmationAlert, useToast } from "../../../../hooks/index";
import sellsService from "../../../../services/sells/sells.service";
import userService from "../../../../services/user.service";
import authService from "../../../../services/auth.service";
import sellsGeneralService from "../../../../services/sells/sellsGeneral.service";
import sellRequirementsService from "../../../../services/sells/requirements.service";
import { diffDateFormula } from "../../../../utils/Badges";
import { sellSuspendedRequirements } from "../../../../utils/Service";
import { BadgeField, DataClient } from "../components";
import AdditionalBlock from "../../../AdditionalBlock";
import { Comments } from "../../../Comments";
import ConfirmationAlert from "../../../ConfirmationAlert/confirmationAlert";
import {
  CheckboxField,
  DatePickerField,
  InputField,
  SelectField,
} from "../../../Form/index";
import {
  activate_button_suspended_sell,
  constants,
  sells_status,
  sells_status_select,
  withdrawAddressType,
  yesNoOptions,
  yesOrNotFinancedOptions,
} from "../../../../variables/constants";

const finalStatus = [sells_status.COMPLETED.value, sells_status.REJECTED.value];

const suspended_reasons = [
  ...activate_button_suspended_sell,
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "41",
];

const suspended_status = [
  sells_status.ERP_VENTAS_SUSPENDED.value,
  sells_status.FOLIO_IN_PROGRESS_SUSPENDED.value,
  sells_status.PRESENTATION_SUSPENDED.value,
];

const sells_folio_status_screen = [
  sells_status.FOLIO_IN_PROGRESS.value,
  sells_status.FOLIO_IN_PROGRESS_STAND_BY.value,
];

const show_pending_documentation = [
  sells_status.PENDING_DOCUMENTATION_WITHDRAW.value,
  sells_status.PENDING_DOCUMENTATION_DELIVERED.value,
  sells_status.PENDING_DOCUMENTATION_RECEIVED.value,
  sells_status.COMPLETED.value,
];

const profileAllowSuspend = [
  constants.adminProfile,
  constants.coordinatorProfile,
  constants.sellsSuspendProfile,
  constants.sellsPostSellsProfile,
];

const Content = (props) => {
  const {
    data: {
      response: {
        opportunity_id,
        plate_number,
        financed,
        bacs_id,
        // status,
        owner,
        reception_cat_date,
        financed_date,
        take_licence_date,
        suspended_date,
        canceled,
        comments,
        status_id,
        stock_id,
        sells_withdraw_documentation_id,
        address_type,
        address,
        withdraw_date,
        tracking_number,
        hub_reception_date,
        delivery_in_charge_id,
        notary_office,
        diff_days,
        suspended_days,
        report_vehicle_sale_date,
        transaction_type,
        avoidStampPayment,
      },
    },
    handleClose,
    refresh,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    bacs_id: bacs_id,
    financed: financed,
    take_licence_date:
      take_licence_date == null ? null : new Date(take_licence_date),
    suspended_date: suspended_date === null ? null : new Date(suspended_date),
    financed_date: financed_date == null ? null : new Date(financed_date),
    comment_reason_id: null,
    comment: null,
    stock_id,
    sells_withdraw_documentation_id,
    address_type,
    address,
    withdraw_date: withdraw_date == null ? null : new Date(withdraw_date),
    tracking_number,
    owner: owner != null ? owner : [],
    delivery_in_charge: delivery_in_charge_id,
    report_vehicle_sale_date,
  };

  const [fields, setFields] = useState(initialFormState);
  // const [allowSubmit, setAllowSubmit] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [profile, setProfile] = useState(null);
  const [users, setUsers] = useState([]);
  const [permissionSuspend, setPermissionSuspend] = useState(null);
  const [disabledPendDocDelivered, setDisabledPendDocDelivered] =
    useState(true);
  const addToast = useToast();

  const showSuspendedButton =
    (profileAllowSuspend.includes(profile) ||
      permissionSuspend?.permissions?.hasUpdate) &&
    (sells_folio_status_screen.includes(status_id) ||
      sells_status_select
        .filter(
          (s) =>
            (s.group === "SELLS" || s.group === "PRESENTATION") &&
            s.suspended !== true
        )
        .map((s) => s.value)
        .includes(status_id));

  const { confirmationModal, handleConfirmationModal } = useConfirmationAlert();

  const getReasons = async () => {
    try {
      const response = await sellsService.getAllReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getPersonInCharge = async () => {
    try {
      const response = await userService.getAllByProfile({
        currentPage: 0,
        countItems: 1000,
        profile: "sellEmployees",
      });

      const result = response.data.response.map((r) => ({
        value: r.id,
        text: r.name,
      }));

      setUsers(result);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getReasons();
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
    getPersonInCharge();
    setPermissionSuspend(
      authService.getCurrentPermissions("SUSPENDIDOS VENTAS")
    );
  }, []);

  const submit = async (finished = false, isPresentationRetired = false) => {
    let status = status_id;
    if (isPresentationRetired) {
      status = sells_status.PENDING_DOCUMENTATION_WITHDRAW.value;
    }

    const data = {
      status_id:
        finished && +new Date(fields.withdraw_date) < +new Date()
          ? sells_status.PENDING_DOCUMENTATION_DELIVERED.value
          : status,
      financed: fields.financed,
      comment: fields.comment,
      comment_reason_id: fields.comment_reason_id,
      financed_date: fields.financed_date,
      take_licence_date: fields.take_licence_date,
      sells_withdraw_documentation_id: fields.sells_withdraw_documentation_id,
      address_type: fields.address_type,
      address: fields.address,
      withdraw_date: fields.withdraw_date,
      tracking_number: fields.tracking_number,
      delivery_in_charge_id: fields.delivery_in_charge,
    };

    try {
      const response = await sellsGeneralService.update(opportunity_id, data);
      if (response.status === 200) {
        refresh();
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handlePreConfirmation = () => {
    let current_status = sells_status.ERP_VENTAS_SUSPENDED.value;
    if (sells_folio_status_screen.includes(status_id)) {
      current_status = sells_status.FOLIO_IN_PROGRESS_SUSPENDED.value;
    }
    if (
      sells_status_select
        .filter((s) => s.group === "PRESENTATION" && s.suspended !== true)
        .map((s) => s.value)
        .includes(status_id)
    ) {
      current_status = sells_status.PRESENTATION_SUSPENDED.value;
    }

    const final_status = sells_status_select.filter(
      (x) => x.value === current_status
    )[0].text;

    handleConfirmationModal(final_status, () =>
      handleChangeStatus(current_status)
    );
  };

  const handleChangeStatus = (status) => {
    submit_status(status);
  };

  const submit_status = (status) => {
    const arrPromises = [];

    const data = {
      status_id: status,
      comment: fields.comment,
      comment_reason_id: fields.comment_reason_id,
      past_suspended_date: fields.past_suspended_date || null,
    };

    const promise_opportunity = new Promise((resolve, reject) => {
      sellsGeneralService.update(opportunity_id, data).then((response) => {
        if (response.status === 200) {
          resolve("Se actualizaron los datos");
        }
        reject("Fallo la actualización de los datos");
      });
    });
    arrPromises.push(promise_opportunity);

    // Si estoy suspendiendo y cargo requisitos
    if (suspended_status.includes(status) && fields.requirements?.length) {
      const promise_requirements = new Promise((resolve, reject) => {
        sellRequirementsService
          .insert(opportunity_id, fields.requirements)
          .then((response) => {
            if (response.success) {
              resolve("Se crearon los requisitos");
            } else {
              reject("Fallo la creación de los requisitos");
            }
          });
      });

      arrPromises.push(promise_requirements);
    }

    Promise.all(arrPromises)
      .then((values) => {
        values.map((v) =>
          addToast({
            variant: "success",
            header: "Ventas - Pantalla General",
            body: v,
          })
        );
        refresh();
        handleClose();
      })
      .catch((reason) => {
        addToast({
          variant: "danger",
          header: "Ventas - Pantalla General",
          body: reason,
        });
        refresh();
      });
  };

  useEffect(() => {
    if (fields.financed === false) {
      onSimpleFieldChange("financed_date", null);
    }
    //eslint-disable-next-line
  }, [fields.financed]);

  useEffect(() => {
    setDisabledPendDocDelivered(
      reception_cat_date == null ||
        fields.take_licence_date == null ||
        !fields.address_type ||
        !fields.address ||
        !fields.address.length ||
        !fields.withdraw_date ||
        !fields.delivery_in_charge ||
        (fields.financed && !fields.financed_date)
    );
    //eslint-disable-next-line
  }, [
    fields.take_licence_date,
    fields.address_type,
    fields.address,
    fields.withdraw_date,
    fields.delivery_in_charge,
    fields.financed,
    fields.financed_date,
  ]);

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={"Dominio"}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
            />
            <BadgeField
              label={"Stock ID"}
              text={fields.stock_id}
              badgeClass={`plate_number`}
              variant={"info"}
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days}
              badgeClass={`plate_number`}
              variant={diffDateFormula(diff_days)}
            />
            <BadgeField
              label={"Días suspendidos"}
              text={suspended_days}
              badgeClass={`plate_number`}
              variant={"info"}
            />
          </div>
          <div className="row">
            <BadgeField
              label={"Pago de Sellado"}
              text={`${avoidStampPayment ? "NO" : ""} PAGA SELLOS`}
              badgeClass={`capitalize plate_number`}
              variant={avoidStampPayment ? "warning" : "danger"}
            />
            <BadgeField
              label={"tipo de operación"}
              text={transaction_type || "Sin datos"}
              badgeClass={"plate_number"}
              variant={"secondary"}
            />
            <SelectField
              label={"Prendado"}
              options={yesOrNotFinancedOptions}
              onChange={(e) =>
                onSimpleFieldChange(
                  "financed",
                  !!e.target.value ? e.target.value : null
                )
              }
              value={fields.financed}
              name={"financed"}
            />
            {canceled != null && canceled !== 1 && (
              <CheckboxField
                checked={canceled === 2 ? true : false}
                label={"Baja Completa"}
                disabled={true}
              />
            )}
          </div>
          <hr />
          <div className="row">
            {fields.financed.toString() === "true" ? (
              <DatePickerField
                label={"Recepción Prenda"}
                selected={fields.financed_date}
                onChange={(date) => onSimpleFieldChange("financed_date", date)}
                dateFormat="dd/MM/yyyy"
              />
            ) : null}
            <DatePickerField
              label={"CAT Recibido"}
              selected={
                reception_cat_date == null ? null : new Date(reception_cat_date)
              }
              dateFormat="dd/MM/yyyy"
              disabled
            />
            <DatePickerField
              label={"Cédula Recibida"}
              selected={fields.take_licence_date}
              onChange={(date) =>
                onSimpleFieldChange("take_licence_date", date)
              }
              dateFormat="dd/MM/yyyy"
              disabled={
                fields.financed === true
                  ? fields.financed_date == null || reception_cat_date == null
                  : reception_cat_date == null
              }
            />
            <SelectField
              label={"Responsable de envio"}
              name={"delivery_in_charge"}
              value={fields.delivery_in_charge}
              options={users}
              onChange={(e) =>
                onSimpleFieldChange(
                  "delivery_in_charge",
                  !!e.target.value ? e.target.value : null
                )
              }
            />
          </div>
          <div className="row">
            <InputField
              label={constants.notaryOffice}
              name={"notary_office"}
              value={notary_office}
              disabled={true}
            />
            {fields.bacs_id ? (
              <DatePickerField
                label={"Denuncia de venta"}
                selected={
                  fields.report_vehicle_sale_date
                    ? new Date(fields.report_vehicle_sale_date)
                    : null
                }
                disabled={true}
                dateFormat="dd/MM/yyyy"
              />
            ) : null}
          </div>
          <br />

          {show_pending_documentation.includes(status_id) ? (
            <>
              <h5>Documentación Pendiente de Retiro</h5>
              <div className="row">
                <SelectField
                  label={"Forma de Entrega"}
                  name={"address_type"}
                  value={fields.address_type}
                  options={withdrawAddressType}
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "address_type",
                      !!e.target.value ? e.target.value : null
                    )
                  }
                />
                <InputField
                  label={"Dirección de Retiro"}
                  name={"address"}
                  value={fields.address}
                  onChange={(e) =>
                    onSimpleFieldChange("address", e.target.value)
                  }
                />
              </div>
              <div className="row">
                <DatePickerField
                  label={"Recepción en HUB"}
                  selected={hub_reception_date}
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
                <DatePickerField
                  label={"Fecha Despacho"}
                  selected={fields.withdraw_date}
                  onChange={(date) =>
                    onSimpleFieldChange("withdraw_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <InputField
                  label={"Código de Seguimiento"}
                  name={"tracking_number"}
                  value={fields.tracking_number}
                  onChange={(e) =>
                    onSimpleFieldChange("tracking_number", e.target.value)
                  }
                />
              </div>
              <hr />
            </>
          ) : null}
          <DataClient owner={owner} />
          <AdditionalBlock opportunity_id={opportunity_id} />
          <Comments
            comments={comments}
            reasons={reasons}
            statusList={sells_status}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
            hideReasons={finalStatus.includes(status_id)}
          />
          {suspended_reasons.includes(fields.comment_reason_id) &&
          showSuspendedButton ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>Requisitos pendientes</strong>
                </label>
                <Multiselect
                  options={sellSuspendedRequirements()}
                  onSelect={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  onRemove={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  displayValue="text"
                  placeholder="Requisitos"
                  closeIcon="cancel"
                  selectionLimit="-1"
                  showCheckbox={true}
                  singleSelect={false}
                />
              </div>
            </div>
          ) : null}
        </div>
        <br />
        <div className="modal-footer">
          {/* <div className="row"> */}
          {!finalStatus.includes(status_id) ? (
            <div className="col-2 pull-left">
              <Button
                className="btn-fill btn btn-secundary btn-sm capitalize"
                onClick={() => submit(false)}
              >
                {constants.update}
              </Button>
            </div>
          ) : null}
          {!finalStatus.includes(status_id) && showSuspendedButton ? (
            <>
              <div className="col-4">
                <DatePickerField
                  placeholderText={"Fecha Suspensión (opcional)"}
                  selected={fields.past_suspended_date}
                  onChange={(date) =>
                    onSimpleFieldChange("past_suspended_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  colClassName=""
                  disabled={
                    !suspended_reasons.includes(fields.comment_reason_id)
                  }
                />
              </div>
              <div className="col-2">
                <Button
                  className="btn-fill btn btn-danger btn-sm capitalize"
                  style={{ marginRight: "100px" }}
                  value={constants.suspend}
                  onClick={handlePreConfirmation}
                  disabled={
                    !suspended_reasons.includes(fields.comment_reason_id)
                  }
                >
                  {constants.suspend}
                </Button>
              </div>
            </>
          ) : null}
          {status_id === sells_status.PRESENTATION_RETIRED.value ? (
            <div className="col-2 pull-right w-auto">
              <Button
                className="btn-fill btn btn-warning btn-sm capitalize"
                onClick={() => {
                  handleConfirmationModal(
                    sells_status.PENDING_DOCUMENTATION_WITHDRAW.text,
                    () => submit(false, true)
                  );
                }}
                disabled={fields.take_licence_date == null}
              >
                Pendiente documentación
              </Button>
            </div>
          ) : null}
          {status_id === sells_status.PENDING_DOCUMENTATION_WITHDRAW.value ? (
            <div className="col-2 pull-right w-auto">
              <Button
                className="btn-fill btn btn-warning btn-sm capitalize"
                value={sells_status.PENDING_DOCUMENTATION_DELIVERED.value}
                onClick={() => {
                  submit(true);
                }}
                disabled={disabledPendDocDelivered}
              >
                {constants.sent}
              </Button>
            </div>
          ) : null}
          {status_id === sells_status.PENDING_DOCUMENTATION_DELIVERED.value ? (
            <div className="col-3 pull-right w-auto">
              <Button
                className="btn-fill btn btn-warning btn-sm capitalize"
                onClick={() =>
                  handleChangeStatus(
                    sells_status.PENDING_DOCUMENTATION_RECEIVED.value
                  )
                }
              >
                Recibida por el Cliente
              </Button>
            </div>
          ) : null}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
