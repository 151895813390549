import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import { Button, Stack } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";

import Dropdown from "../../Table/Dropdown";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import SeeForms from "../../CheckForms";
import CustomAccordion from "../../Accordion";
import Requirement from "../../RequirementCheck";
import { Comments } from "../../Comments";
import { BadgeField } from "../Sells/components";
import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";
import purchaseRequirementsService from "../../../services/purchase/requirements.service";
import presentationService from "../../../services/purchase/presentation.service";
import { diffDateFormula, plateNumberBadge } from "../../../utils/Badges";
import { processFiles } from "../../../utils/File";
import { issuerEntities, purchaseRequirements } from "../../../utils/Service";
import { purchaseComment } from "../../../utils/Comments";
import {
  constants,
  agentProfiles,
  purchaseStatus,
  yesNoOptions,
} from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks";

const requirementStatus = [
  purchaseStatus.PRESENTATION_OBSERVED.value,
  purchaseStatus.PRESENTATION_OBSERVED_RETIRED.value,
];

// Pasos para organizar el modal, visualmente
const step1 = [
  purchaseStatus.PRESENTATION_WAITING.value,
  purchaseStatus.PRESENTATION_DELIVERED.value,
];

const step2 = [purchaseStatus.PRESENTATION_PRESENTED.value];

const step3 = [
  purchaseStatus.PRESENTATION_OBSERVED.value,
  purchaseStatus.PRESENTATION_OBSERVED_RETIRED.value,
  purchaseStatus.PRESENTATION_OBSERVED_DELIVERED.value,
];

const step4 = [purchaseStatus.PRESENTATION_OBSERVED_PRESENTED.value];

// const step5 = [
//   purchaseStatus.PRESENTATION_CAT_RECEIVED.value,
// ];

const stepFinal = [purchaseStatus.PRESENTATION_RETIRED.value];

const Content = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        status,
        agent_id,
        agent,
        turn_request_date,
        turn_rerequest_date,
        turn_request_schedule,
        turn_take_date,
        turn_take_schedule,
        order_id,
        issuer_entity_id,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        zone,
        distance,
        ranking_radicacion,
        web_control_id,
        current_location,
        loss_request,
        estimated_date,
        cat_date,
        presented_date,
        retired_date,
        observed_date,
        obs_presented_date,
        diff_days,
        diff_days_presentation,
        extraordinary_date,
        pledge,
        pledge_start,
        pledge_end,
        rendition_link_presented,
        rendition_link_retired,
      },
    },
    profile,
    agentOptions,
    handleUpdate,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    vehicle_id,
    plate_number,
    purchase_file_status: status,
    agent_id,
    issuer_entity_tentative_id,
    loss_request,
    turn_request_date:
      turn_request_date === null ? null : new Date(turn_request_date),
    turn_take_date: turn_take_date === null ? null : new Date(turn_take_date),
    turn_rerequest_date:
      turn_rerequest_date === null ? null : new Date(turn_rerequest_date),
    turn_request_schedule:
      turn_request_schedule === null ? null : new Date(turn_request_schedule),
    turn_take_schedule:
      turn_take_schedule === null ? null : new Date(turn_take_schedule),
    extraordinary_date:
      extraordinary_date === null ? null : new Date(extraordinary_date),
    comment_reason_id: null,
    comment: null,
    images: [],
    pledge,
    pledge_start: pledge_start === null ? null : new Date(pledge_start),
    pledge_end: pledge_end === null ? null : new Date(pledge_end),
    rendition_link_presented,
    rendition_link_retired,
  };

  // Siguiente estado
  const nextStatus = purchaseStatus[status]?.next
    ? purchaseStatus[purchaseStatus[status].next]
    : null;

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState();
  const [agentObserved, setAgentObserved] = useState([]);
  const [agentPresented, setAgentPresented] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [forms, setForms] = useState([]);
  const [currentRequirements, setCurrentRequirements] = useState([]);
  const [totalRequirements, setTotalRequirements] = useState();

  const [turnTakeScheduleDisabled, setTurnTakeScheduleDisabled] =
    useState(true);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();

  // Habilitacion de campos
  const turnRequestDateDisabled =
    purchaseStatus.PRESENTATION_WAITING.value != status;
  const turnRequestScheduleDisabled =
    purchaseStatus.PRESENTATION_DELIVERED.value != status;

  const turn_rerequest_date_disabled =
    !step3.includes(status) && turn_rerequest_date !== null;
  const turn_take_date_disabled = stepFinal.includes(status);

  // REGISTROS
  // Selector de emisor
  const onSelect = (selectedList, selectedItem) => {
    setSelectedIssuer([selectedItem]);
    onSimpleFieldChange("issuer_entity_tentative_id", selectedItem.value);
  };

  // Eliminación del emisor
  const onRemove = () => {
    onSimpleFieldChange("issuer_entity_tentative_id", null);
  };

  const updateRequirements = (id, status) => {
    const idx = currentRequirements.findIndex((r) => r.requirement_id === id);
    currentRequirements[idx].status = status;
    setTotalRequirements(currentRequirements.filter((r) => r.status).length);
  };

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "agent_summary").then((response) => {
      const res = response.data.response;
      setAgentObserved(
        res.filter(
          (a) =>
            a.purchase_file_status ===
            purchaseStatus.PRESENTATION_OBSERVED.value
        )
      );
      setAgentPresented(
        res.filter(
          (a) =>
            a.purchase_file_status ===
            purchaseStatus.PRESENTATION_PRESENTED.value
        )
      );
    });
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });
    ManagementService.getCommentReasons().then((response) => {
      if (response.data.success) {
        setReasons(
          response.data.response.filter((r) => !r.text.includes("[SUSPENDER]"))
        );
      }
    });
    setSelectedIssuer(
      issuer_entity_tentative_id === null
        ? null
        : [
            {
              value: issuer_entity_tentative_id,
              text: issuer_entity_tentative,
            },
          ]
    );
    if (agent_id !== null) {
      setSelectedAgent(
        agentOptions.find((a) => a.value === agent_id)?.text ?? null
      );
    }

    presentationService.getPurchaseForms(vehicle_id).then((response) => {
      if (response.data.success) {
        setForms(response.data.response.sort((x) => x.code));
      }
    });

    purchaseRequirementsService
      .getAllById(vehicle_id, "observed")
      .then((response) => {
        if (response.data.success) {
          setCurrentRequirements(response.data.response);
          setTotalRequirements(
            response.data.response.filter((r) => r.status).length
          );
        }
      });
    // eslint-disable-next-line
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Habilitacion de campos schedule, Presentación y Retiro
    // Motivo 20: Turno no alcanzado
    // Se habilita si el motivo de comentario es diferente al 20 o
    // no tiene fecha de turno o no esta retidado
    setTurnTakeScheduleDisabled(
      fields.comment_reason_id !== constants.schedule_allow_comment_reason_id ||
        turn_take_date === null ||
        retired_date !== null
    );

    // Condiciones para el submit - Cambio de estado

    // const isAgentInternal = selectedAgent?.indexOf("[Gestor Interno]") !== -1;

    // Estado en Espera => agente, registro y fecha turno de presentación si es gestor interno
    const conditionOnWaiting =
      status === purchaseStatus.PRESENTATION_WAITING.value &&
      fields.agent_id !== null &&
      fields.issuer_entity_tentative_id !== null &&
      !agentProfiles.includes(profile);
    // Estado entregado => agente, registro, fecha turno de presentación, y si es gestor algún adjunto
    const conditionOnDelivery =
      status === purchaseStatus.PRESENTATION_DELIVERED.value &&
      fields.agent_id !== null &&
      fields.issuer_entity_tentative_id !== null &&
      // fields.rendition_link_presented?.length &&
      (fields.turn_request_date !== null ||
        fields.turn_request_schedule !== null);
    // Ahora es opcional el adjunto
    /*
      &&
      ((agentProfiles.includes(profile) && fields.images.length > 0) ||
        !agentProfiles.includes(profile));
    */
    // Estado presentado o re-presentado y no es gestor
    const conditionOnPresented =
      !agentProfiles.includes(profile) &&
      step2.concat(step4).includes(status) &&
      fields.agent_id !== null;
    // Estado CAT => fecha turno de retiro obligatoria
    const conditionOnCat =
      status === purchaseStatus.PRESENTATION_CAT_RECEIVED.value &&
      fields.agent_id !== null &&
      // fields.rendition_link_retired?.length &&
      fields.turn_take_date !== null;
    // Estado Observado => agente, registro y fecha turno de re-presentación si es gestor interno
    const conditionOnObserved =
      requirementStatus.includes(status) &&
      fields.agent_id !== null &&
      fields.issuer_entity_tentative_id !== null &&
      !agentProfiles.includes(profile) &&
      currentRequirements.length === totalRequirements;
    // Estado re-entregado => agente, registro, fecha turno de re-presentación, y si es gestor algún adjunto
    const conditionOnObservedDelivery =
      status === purchaseStatus.PRESENTATION_OBSERVED_DELIVERED.value &&
      fields.agent_id !== null &&
      fields.issuer_entity_tentative_id !== null &&
      fields.turn_rerequest_date !== null;
    // Ahora es opcional el adjunto
    /* 
      &&
      ((agentProfiles.includes(profile) && fields.images.length > 0) ||
        !agentProfiles.includes(profile));
    */

    setAllowSubmit(
      conditionOnWaiting ||
        conditionOnDelivery ||
        conditionOnPresented ||
        conditionOnCat ||
        conditionOnObserved ||
        conditionOnObservedDelivery
    );
    if (submitAction) {
      handleUpdate(fields);
      setSubmitAction(false);
      handleClose();
    }
  }, [
    submitAction,
    selectedAgent,
    fields,
    profile,
    status,
    handleUpdate,
    handleClose,
    turn_request_date,
    presented_date,
    turn_take_date,
    retired_date,
    totalRequirements,
  ]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files);
    setFields({
      ...fields,
      observation: true,
      images: [...newImages],
    });
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.domain}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
              containerClass="col-2"
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days ?? 0}
              badgeClass={`capitalize plate_number`}
              variant={diffDateFormula(diff_days)}
              containerClass="col-3"
            />
            <BadgeField
              label={"Días en logístico"}
              text={diff_days_presentation}
              badgeClass={`capitalize plate_number`}
              variant={"info"}
              containerClass="col-3"
            />
            <div className="col-4">
              <label className="capitalize">
                <strong>{constants.estimatedDate}</strong>
              </label>
              <div>
                <DatePicker
                  selected={
                    estimated_date === null ? null : new Date(estimated_date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
            </div>
          </div>
          {!agentProfiles.includes(profile) ? (
            <div className="row">
              <BadgeField
                label={"registro radicación"}
                text={current_location}
                badgeClass={`capitalize plate_number`}
                variant={"info"}
              />
              <BadgeField
                label={"ranking radicación"}
                text={ranking_radicacion}
                badgeClass={`capitalize plate_number`}
                variant={"secondary"}
              />
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.lossRequest}</strong>
                </label>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    name="loss_request"
                    placeholder={constants.lossRequest}
                    autoComplete="false"
                    onChange={(e) =>
                      onSimpleFieldChange("loss_request", e.target.value)
                    }
                    value={fields.loss_request}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.pledge}</strong>
              </label>
              <div>
                <Dropdown
                  className="form-control"
                  name="pledge"
                  placeholder="Condición"
                  options={yesNoOptions}
                  selected={
                    fields.pledge?.toLowerCase() ?? constants.dropdownDefault
                  }
                  onSelect={(e) =>
                    onSimpleFieldChange(
                      "pledge",
                      e.target.value === constants.dropdownDefault
                        ? null
                        : e.target.value?.toUpperCase()
                    )
                  }
                  disabled={fields.pledge !== "SI" || fields.pledge_end}
                />
              </div>
            </div>

            <div className="col">
              <label className="capitalize">
                <strong>{`${constants.pledge} Inicio`}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.pledge_start}
                    onChange={(date) =>
                      onSimpleFieldChange("pledge_start", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={fields.pledge !== "SI" || fields.pledge_end}
                  />
                </span>
              </div>
            </div>

            <div className="col">
              <label className="capitalize">
                <strong>{`${constants.pledge} Concluido`}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.pledge_end}
                    onChange={(date) => onSimpleFieldChange("pledge_end", date)}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={fields.pledge !== "SI" || !fields.pledge_start}
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            {!agentProfiles.includes(profile) ? (
              !stepFinal.includes(status) ? (
                <div className="col">
                  <label className="capitalize">
                    <strong>{constants.agentPresenting}</strong>
                  </label>
                  <div>
                    <Dropdown
                      className="form-control"
                      name="agent"
                      placeholder={constants.agentPresenting}
                      onSelect={(e) => {
                        onSimpleFieldChange(
                          "agent_id",
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value
                        );
                        setSelectedAgent(
                          e.target.options[e.target.selectedIndex].text
                        );
                      }}
                      selected={fields.agent_id}
                      options={agentOptions}
                    />
                  </div>
                </div>
              ) : (
                <BadgeField
                  label={constants.agentPresenting}
                  text={agent}
                  badgeClass={`capitalize plate_number`}
                  variant={"dark"}
                />
              )
            ) : null}
            {stepFinal.includes(status) || agentProfiles.includes(profile) ? (
              <BadgeField
                label={"Registro donde se Presenta"}
                text={issuer_entity_tentative}
                badgeClass={`capitalize plate_number`}
                variant={"info"}
              />
            ) : (
              <div className="col-6">
                <label className="capitalize">
                  <strong>Registro donde se Presenta</strong>
                </label>
                <Multiselect
                  name="issuer_entity_id"
                  options={issuerEntities()}
                  selectedValues={selectedIssuer}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="text"
                  placeholder=""
                  closeIcon="cancel"
                  selectionLimit="1"
                  showCheckbox={false}
                  singleSelect={false}
                  selectedValueDecorator={(v) => v.substr(0, 40)}
                />
              </div>
            )}
            {agentProfiles.includes(profile) ? (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.estimatedDate}</strong>
                </label>
                <div>
                  <DatePicker
                    selected={
                      estimated_date === null ? null : new Date(estimated_date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled
                  />
                </div>
              </div>
            ) : (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.extraordinaryDate}</strong>
                </label>
                <div>
                  <DatePicker
                    selected={fields.extraordinary_date}
                    onChange={(date) =>
                      onSimpleFieldChange("extraordinary_date", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={stepFinal.includes(status)}
                  />
                </div>
              </div>
            )}
          </div>

          {issuer_entity_tentative_id !== null &&
          !agentProfiles.includes(profile) ? (
            <div className="row">
              <BadgeField
                label={constants.zone}
                text={zone}
                badgeClass={`capitalize plate_number`}
                variant={"secondary"}
                containerClass={"col-3"}
              />
              <BadgeField
                label={"Distancia"}
                text={distance}
                badgeClass={`capitalize plate_number`}
                variant={"secondary"}
                containerClass={"col-3"}
              />
            </div>
          ) : null}

          <hr />
          <div className="row">
            <div className="col-12">
              <label className="capitalize">
                <strong>Presentación</strong>
              </label>
            </div>
            <div className="col-3">
              <label className="capitalize">
                <strong>Fecha Turno</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.turn_request_date}
                    onChange={(date) =>
                      onSimpleFieldChange("turn_request_date", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={turnRequestDateDisabled}
                  />
                </span>
              </div>
            </div>
            <div
              className={`col-3${!turnRequestDateDisabled ? " hidden" : ""}`}
            >
              <label className="capitalize">
                <strong>{constants.reschedule}</strong>
              </label>
              <div>
                <DatePicker
                  selected={fields.turn_request_schedule}
                  onChange={(date) =>
                    onSimpleFieldChange("turn_request_schedule", date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled={turnRequestScheduleDisabled}
                />
              </div>
            </div>
            <div className={`col-3${step1.includes(status) ? " hidden" : ""}`}>
              <label className="capitalize">
                <strong>Fecha Real</strong>
              </label>
              <div>
                <DatePicker
                  selected={
                    presented_date === null ? null : new Date(presented_date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
            </div>
            <BadgeField
              label={constants.presentedAgent}
              text={
                agentPresented.length > 0
                  ? agentPresented[agentPresented.length - 1].agent
                  : ""
              }
              badgeClass={`capitalize plate_number`}
              variant={"dark"}
              containerClass={`col-3${
                step1.includes(status) || agentPresented.length === 0
                  ? " hidden"
                  : ""
              }`}
            />
            <div className="col-6">
              <label className="capitalize">
                <strong>Link Rendición</strong>
              </label>
              <div>
                <input
                  type="url"
                  className="form-control"
                  name="rendition_link_presented"
                  autoComplete="false"
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "rendition_link_presented",
                      e.target.value
                    )
                  }
                  value={fields.rendition_link_presented}
                  maxLength={200}
                  disabled={
                    !nextStatus ||
                    ![...step2, ...step4].includes(nextStatus?.value)
                  }
                />
              </div>
            </div>
          </div>

          {step1.includes(status) && agentProfiles.includes(profile) ? null : (
            <>
              <hr />
              <div className="row">
                <div className="col-12">
                  <label className="capitalize">
                    <strong>Retiro</strong>
                  </label>
                </div>
                <div className="col-3">
                  <label className="capitalize">
                    <strong>Fecha Turno</strong>
                  </label>
                  <div>
                    <DatePicker
                      selected={fields.turn_take_date}
                      onChange={(date) =>
                        onSimpleFieldChange("turn_take_date", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      disabled={turn_take_date_disabled}
                    />
                  </div>
                </div>
                <div
                  className={`col-3${
                    !turn_take_date_disabled ? " hidden" : ""
                  }`}
                >
                  <label className="capitalize">
                    <strong>{constants.reschedule}</strong>
                  </label>
                  <div>
                    <DatePicker
                      selected={fields.turn_take_schedule}
                      onChange={(date) =>
                        onSimpleFieldChange("turn_take_schedule", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      disabled={turnTakeScheduleDisabled}
                    />
                  </div>
                </div>
                <div
                  className={`col-3${
                    !stepFinal.includes(status) ? " hidden" : ""
                  }`}
                >
                  <label className="capitalize">
                    <strong>Fecha Real</strong>
                  </label>
                  <div>
                    <DatePicker
                      selected={
                        retired_date === null ? null : new Date(retired_date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-6">
                  <label className="capitalize">
                    <strong>Link Rendición</strong>
                  </label>
                  <div>
                    <input
                      type="url"
                      className="form-control"
                      name="rendition_link_retired"
                      autoComplete="false"
                      onChange={(e) =>
                        onSimpleFieldChange(
                          "rendition_link_retired",
                          e.target.value
                        )
                      }
                      value={fields.rendition_link_retired}
                      maxLength={200}
                      disabled={
                        !nextStatus ||
                        nextStatus?.value !==
                          purchaseStatus.PRESENTATION_RETIRED.value
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          <hr />
          {!agentProfiles.includes(profile) ? (
            <div className="row">
              <div
                className={`col-6${
                  step1.concat(step2).includes(status) ||
                  agentObserved.length === 0
                    ? " hidden"
                    : ""
                }`}
              >
                <label className="capitalize">
                  <strong>{constants.observedDate}</strong>
                </label>
                <div>
                  <DatePicker
                    selected={
                      observed_date === null ? null : new Date(observed_date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled
                  />
                </div>
              </div>
              <BadgeField
                label={constants.observedAgent}
                text={
                  agentObserved.length > 0
                    ? agentObserved[agentObserved.length - 1].agent
                    : ""
                }
                badgeClass={`capitalize plate_number`}
                variant={"dark"}
                containerClass={`col-6${
                  step1.concat(step2).includes(status) ||
                  agentObserved.length === 0
                    ? " hidden"
                    : ""
                }`}
              />
            </div>
          ) : null}
          <div className="row">
            <div
              className={`col-6${
                step1.concat(step2).includes(status) ||
                agentObserved.length === 0
                  ? " hidden"
                  : ""
              }`}
            >
              <label className="capitalize">
                <strong>{constants.turnReRequestDate}</strong>
              </label>
              <div>
                <DatePicker
                  selected={fields.turn_rerequest_date}
                  onChange={(date) =>
                    onSimpleFieldChange("turn_rerequest_date", date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled={turn_rerequest_date_disabled}
                />
              </div>
            </div>
            <div
              className={`col-6${
                step1.concat(step2, step3).includes(status) ||
                agentObserved.length === 0
                  ? " hidden"
                  : ""
              }`}
            >
              <label className="capitalize">
                <strong>{constants.realObservedPresentedDate}</strong>
              </label>
              <div>
                <DatePicker
                  selected={
                    obs_presented_date === null
                      ? null
                      : new Date(obs_presented_date)
                  }
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className={`col-6${cat_date === null ? " hidden" : ""}`}>
              <label className="capitalize">
                <strong>{constants.receptionCatDate}</strong>
              </label>
              <div>
                <DatePicker
                  selected={cat_date === null ? null : new Date(cat_date)}
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  disabled
                />
              </div>
            </div>
          </div>
          <SeeForms forms={forms} />
          {!agentProfiles.includes(profile) ? (
            <Comments
              reasons={reasons}
              comments={purchaseComment(comments)}
              statusList={purchaseStatus}
              onChangeSelect={(e) =>
                onSimpleFieldChange("comment_reason_id", e.target.value)
              }
              onChangeObs={(e) =>
                onSimpleFieldChange("comment", e.target.value)
              }
              hideReasons={
                stepFinal.includes(status) ||
                constants.agentExternalProfile === profile
              }
            />
          ) : null}

          {!stepFinal.includes(status) ? (
            <>
              {requirementStatus.includes(status) ? (
                <>
                  <CustomAccordion
                    title={`Requisitos Pendientes (${totalRequirements}/${currentRequirements.length})`}
                  >
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="d-flex flex-wrap"
                    >
                      {currentRequirements.map((c) => (
                        <div>
                          <Requirement
                            id={vehicle_id}
                            data={c}
                            onChange={updateRequirements}
                            serviceName={purchaseRequirementsService.updateOne}
                          />
                        </div>
                      ))}
                    </Stack>
                  </CustomAccordion>
                  <div className="row">
                    <div className="col">
                      <label className="capitalize">
                        <strong>Requisitos Adicionales</strong>
                      </label>
                      <Multiselect
                        options={purchaseRequirements().filter(
                          (r) =>
                            !currentRequirements
                              .map((c) => c.requirement_id)
                              .includes(r.value)
                        )}
                        onSelect={(selectedItem) => {
                          onSimpleFieldChange("requirements", selectedItem);
                        }}
                        onRemove={(selectedItem) => {
                          onSimpleFieldChange("requirements", selectedItem);
                        }}
                        displayValue="text"
                        placeholder="Requisitos"
                        closeIcon="cancel"
                        selectionLimit="-1"
                        showCheckbox={true}
                        singleSelect={false}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {agentProfiles.includes(profile) ? (
                <div className="row">
                  <div className="col">
                    <label className="capitalize">
                      <strong>{constants.files}</strong>
                    </label>
                    <div>
                      <span>
                        <input
                          type="file"
                          accept="image/*"
                          className="form-control"
                          name="images"
                          encType="multipart/form-data"
                          multiple={true}
                          onChange={handleFiles}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </div>
        <br />
        {issuer_entity_id && order_id && web_control_id && (
          <>
            <hr />
            <div className="container">
              <div className="row">
                <div className="col">
                  <a
                    className="badge badge-link download-certificate"
                    href={`${constants.url_certificate_of_title}/${issuer_entity_id}/${order_id}/${web_control_id}?valid=false`}
                  >
                    Descargar informe de dominio
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
        {stepFinal.includes(status) ? null : (
          <div className="modal-footer">
            <div>
              <Button
                className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
                onClick={() => setSubmitAction(true)}
              >
                {constants.update}
              </Button>
            </div>
            {status === purchaseStatus.PRESENTATION_OBSERVED.value ? (
              <div>
                <Button
                  className="btn-fill pull-right btn btn-danger btn-sm capitalize"
                  onClick={() => {
                    handlePreConfirmationPurchaseModal(
                      "purchase_file_status",
                      purchaseStatus.PRESENTATION_OBSERVED_RETIRED.value,
                      purchaseStatus.PRESENTATION_OBSERVED_RETIRED.text,
                      handleChangeStatus
                    );
                  }}
                  disabled={agentProfiles.includes(profile)}
                >
                  {purchaseStatus.PRESENTATION_OBSERVED_RETIRED.text.substr(15)}
                </Button>
              </div>
            ) : null}
            {!!nextStatus && (
              <div className="row">
                <Button
                  className="btn-fill pull-right btn btn-warning btn-sm capitalize"
                  onClick={() => {
                    handlePreConfirmationPurchaseModal(
                      "purchase_file_status",
                      nextStatus?.value,
                      nextStatus?.text,
                      handleChangeStatus
                    );
                  }}
                  disabled={!allowSubmit}
                >
                  {nextStatus?.text.substr(15)}
                </Button>
              </div>
            )}
          </div>
        )}
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
