import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import FunctionService from "../../../services/function.service";
import { Card } from "../../../components/Card/Card.jsx";
import NotFound from "../../../components/Errors/NotFound.js";
import { useToast } from "../../../hooks";
import styles from "../../Purchase/General/styles.module.scss";
import { updateLocalStorageCache } from "../../../utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const FunctionsEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const function_id = params.id;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    FunctionService.getById(function_id)
      .then((response) => {
        if (response.data.success && response.data.data) {
          setName(response.data.data.name);
          setDescription(response.data.data.description);
          setTitle(response.data.data.title);
          setPosition(response.data.data.position);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [function_id]);

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      const params = {
        id: function_id,
        name,
        description,
        title,
        position,
      };
      FunctionService.edit(params).then(
        () => {
          addToast({
            variant: "success",
            header: "Funciones",
            body: "Se actualizó la función correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/functions/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Funciones",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Row>
          <Col md={12}>
            <div className={`content-filters form-group ${styles.lessHeight}`}>
              <Button href="/admin/functions/list" variant="outline-primary">
                Volver
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="form-group">
                      <label htmlFor="email">Nombre</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Descripción</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="description"
                        placeholder="description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Título</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="Título"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Posición</label>
                      <Input
                        type="number"
                        className="form-control"
                        name="position"
                        placeholder="Posición"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <button
                        className="btn btn-primary btn-fill pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar Función</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FunctionsEdit;
