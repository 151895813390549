import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getAll = async (props) => {
  const {
    limit = 1000,
    page = 0,
    available = 1,
    use_for_purchase = null,
    use_for_sell = null,
  } = props;
  const url = `${API_URL}/form/list?limit=${limit}&page=${page}&available=${available}&use_for_purchase=${use_for_purchase}&use_for_sell=${use_for_sell}`;
  return await axios.get(url, { headers: authHeader() });
};

const addToFolio = (params) => {
  return axios
    .put(`${API_URL}/folio/add-form`, { ...params }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const removeFromFolio = (params) => {
  return axios
    .put(
      `${API_URL}/folio/remove-form`,
      { ...params },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

const saveConfig = (data) => {
  return axios
    .post(`${API_URL}/form/save-config`, data, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      }
      throw new Error(response.data.message);
    });
};

export default {
  getAll,
  addToFolio,
  removeFromFolio,
  saveConfig,
};
