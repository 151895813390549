import jsPDF from "jspdf";
import PrintService from "../services/print/print.service";
import { form08 } from "../variables/constants";

const shareMapping = (vehicle) => {
  if (!vehicle?.car || !vehicle?.engine) {
    throw new Error("Invalid vehicle data: missing car or engine information");
  }
  return [
    { field: "vehicle_plate_number_top", value: vehicle.plate_number },
    { field: "vehicle_plate_number", value: vehicle.plate_number },
    { field: "vehicle_brand", value: vehicle.car.brand },
    { field: "vehicle_type", value: vehicle.car.type },
    { field: "vehicle_model", value: vehicle.car.model },
    { field: "engine_brand", value: vehicle.engine.engineBrand },
    { field: "engine_number", value: vehicle.engine.engineNumber },
    { field: "chasis_brand", value: vehicle.engine.chasisBrand },
    { field: "chasis_number", value: vehicle.engine.chasisNumber },
    { field: "issuer_entity_id", value: vehicle.issuer_entity_id },
  ];
};

const mapAddress = (address, prefix) => {
  if (!prefix) {
    throw new Error("prefix parameter is required for address mapping");
  }
  if (!address) {
    return [];
  }
  return [
    { field: `${prefix}_street`, value: address?.street },
    { field: `${prefix}_number`, value: address?.number },
    { field: `${prefix}_floor`, value: address?.floor },
    { field: `${prefix}_apartment`, value: address?.apartment },
    { field: `${prefix}_zip_code`, value: address?.zipCode },
    { field: `${prefix}_city`, value: address?.city },
    { field: `${prefix}_province`, value: address?.province },
  ];
};

const filterAddressFields = (fields, removedFields) =>
  fields.filter((f) => !removedFields.includes(f.field));

// Map marital status
const MARITAL_STATUS_MAP = {
  SOLTERO: "single",
  CASADO: "married",
  VIUDO: "widower",
  DIVORCIADO: "divorced",
};

const mapOwnerInfo = (owner, index) => {
  if (!owner) return [];

  const percentage = `${owner.holder_percentage}`.split(".");
  const prefix = `seller${index + 1}`;

  const mapping = [
    { field: `${prefix}_percentage_number`, value: percentage[0] },
    { field: `${prefix}_percentage_fraction`, value: percentage[1] || "0" },
    { field: `${prefix}_full_name`, value: owner.holder_name },
    { field: `${prefix}_document_number`, value: owner.document_number },
    { field: `${prefix}_nationality`, value: owner.nationality },
  ];

  if (MARITAL_STATUS_MAP[owner.marital_status]) {
    mapping.push({
      field: `${prefix}_marital_${MARITAL_STATUS_MAP[owner.marital_status]}`,
      value: "x",
    });
  }

  // Map document type
  if (owner.document_type === "D.N.I.") {
    mapping.push({ field: `${prefix}_document_dni`, value: "x" });
  } else if (owner.document_type === "D.N.I.Ex") {
    mapping.push({ field: `${prefix}_document_dni_ex`, value: "x" });
  }

  return mapping;
};

export const formPDF = async (dataVehicle, form, place = "1", functionId) => {
  try {
    const res = await PrintService.getFormDetail(form);
    let data = null;
    switch (res.data.data.data_id) {
      // Check Movilidad S.A. Comerciante Habitualista
      case 1: {
        data = form08.buyerInfo;
        break;
      }
      // BACS Banco de Credito y Securitizacion S.A
      case 2: {
        data = form08.bacsInfo;
        break;
      }
      default:
        break;
    }

    switch (res.data.data.type_id) {
      // 08-Compra-Frente
      case 1: {
        const {
          plate_number,
          car: { use },
        } = dataVehicle;

        const mapping = [
          ...shareMapping(dataVehicle),
          { field: "vehicle_use", value: use },
        ];

        if (!!data) {
          const pushing = [
            {
              field: "buyer_percentage_number",
              value: data.percentage?.number,
            },
            {
              field: "buyer_percentage_fraction",
              value: data?.percentage?.fraction,
            },
            { field: "buyer_first_name", value: data?.fullName },
            { field: "buyer_last_name", value: data?.lastName },
            { field: "buyer_email", value: data?.email },
            { field: "buyer_phone", value: data?.phone },
            ...mapAddress(data?.address, "buyer_legal_address"),
            ...mapAddress(data?.address, "buyer_real_address"),
            { field: "buyer_profession", value: data?.profession },
            { field: "buyer_cuit", value: data?.cuit },
            { field: "buyer_personeria", value: data?.personeria },
            {
              field: "buyer_inscription_number",
              value: data?.inscriptionData?.number,
            },
            {
              field: "buyer_inscription_day",
              value: data?.inscriptionData?.date?.day,
            },
            {
              field: "buyer_inscription_month",
              value: data?.inscriptionData?.date?.month,
            },
            {
              field: "buyer_inscription_year",
              value: data?.inscriptionData?.date?.year,
            },
          ];
          mapping.push(...pushing);
        }
        await formPrintOut(form, place, mapping, plate_number);
        break;
      }
      // 08-Compra-Dorso
      case 2: {
        const { plate_number, owners } = dataVehicle;
        const mapping = [
          ...mapOwnerInfo(owners[0], 0),
          ...mapOwnerInfo(owners[1], 1),
        ];
        await formPrintOut(form, place, mapping, plate_number);
        break;
      }
      // 04-Compra-Frente
      case 3: {
        const { plate_number } = dataVehicle;

        const mapping = [...shareMapping(dataVehicle)];

        if (form === 8 && !!data?.address) {
          const removedFields = [
            "buyer_legal_address_apartment",
            "buyer_legal_address_province",
          ];
          const pushing = filterAddressFields(
            mapAddress(data.address, "buyer_legal_address"),
            removedFields
          );
          mapping.push(...pushing);
        }
        await formPrintOut(form, place, mapping, plate_number);
        break;
      }
      // 02-Compra-Frente
      case 4: {
        const { plate_number } = dataVehicle;
        const mapping = [...shareMapping(dataVehicle)];
        await formPrintOut(form, place, mapping, plate_number);
        break;
      }
      // Solicitud de Extravío
      case 5: {
        const { plate_number } = dataVehicle;
        const mapping = [...shareMapping(dataVehicle)];
        await formPrintOut(form, place, mapping, plate_number);
        break;
      }
      default:
        break;
    }
  } catch (error) {
    console.log("Create PDF Failed", error);
  } finally {
    const payload = {
      plate_number: dataVehicle.plate_number,
      reception_place_id: place,
      form_id: form,
      function_id: functionId,
    };
    try {
      const response = await PrintService.logPrint(payload);
      console.log("Print Log Success");
    } catch (error) {
      console.log("Print Log Failed", error);
    }
  }
};

const formPrintOut = async (
  print_form_id,
  reception_place_id,
  mapping,
  plate_number
) => {
  if (!print_form_id || !reception_place_id || !mapping || !plate_number) {
    throw new Error("Missing required parameters for PDF generation");
  }
  if (!Array.isArray(mapping)) {
    throw new Error("mapping parameter must be an array");
  }

  const FORM_9_RECTANGLE = {
    x: 2,
    y: 4,
    width: 25,
    height: 12,
  };

  const renderText = (doc, coordinate, text) => {
    if (!coordinate?.pos_x || !coordinate?.pos_y) {
      console.warn(`Invalid coordinates for text: ${text}`);
      return;
    }
    const safeText = text == null ? "" : String(text);
    doc.text(coordinate.pos_x, coordinate.pos_y, safeText);
  };

  try {
    const response = await PrintService.getConfig(
      print_form_id,
      reception_place_id
    );

    const { config, coordinates } = response?.data?.response || {};
    const labels = coordinates.filter((c) => c.field.endsWith("_label"));

    const doc = new jsPDF({
      orientation: config.orientation,
      unit: config.unit,
      format: [config.format_x, config.format_y],
    });
    doc.setFont(config.font_name);
    doc.setFontSize(config.font_size);

    // Excepcion para la solicitud de extravío, el recuadro
    if (print_form_id === 9) {
      doc.setLineWidth(0);
      doc.rect(
        FORM_9_RECTANGLE.x,
        FORM_9_RECTANGLE.y,
        FORM_9_RECTANGLE.width,
        FORM_9_RECTANGLE.height
      );
    }

    // Valores dinamicos
    for (let index = 0; index < mapping.length; index++) {
      const element = mapping[index];
      const coordinate = coordinates.find((c) => c.field === element.field);
      if (!coordinate) {
        console.warn(`No se encontraron coordenadas para: ${element.field}`);
        continue;
      }
      renderText(doc, coordinate, element.value);
    }

    // Labels - Texto estatico como esta en la DB
    for (let index = 0; index < labels.length; index++) {
      renderText(doc, labels[index], labels[index].field_description);
    }

    doc.save(
      `${config.reception_place}-formulario-${config.form}-${plate_number}.pdf`
    );
  } catch (error) {
    console.error(error);
  }
};

export default {};
