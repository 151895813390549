import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";

import AuthService from "../../../services/auth.service";
import FolioService from "../../../services/folio.service";
import ManagementService from "../../../services/management.service";

import ButtonIcon from "../../../components/ButtonIcon";
import Filters from "../../../components/Filters/index.js";
import Content from "../../../components/Modal/FolioInProgress/content.js";
import RejectContent from "../../../components/Modal/Reject/content";
import List from "../../../components/Table/List.js";
import ExcelButton from "../../../components/ExcelButton/index.js";

import {
  useFilters,
  useGrid,
  useStatusModal,
  useToast,
} from "../../../hooks/index";
import Modal from "../../../layouts/ModalDisplay.js";
import {
  diffDateBadge,
  // remainingTimeBadge,
  SDPBadge,
  statusBadge,
} from "../../../utils/Badges.js";
import styles from "../General/styles.module.scss";
import { headSort } from "./constants/constant.columns";
import { filtersFolioInProgress } from "./constants/constant_filters";
import { purchaseStatus, serviceTypes } from "../../../variables/constants";

const FolioInProgressList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState(null);
  const addToast = useToast();

  //PAGINATE
  const next = {
    link: "/admin/presentation/list",
    text: "Presentación",
  };

  const {
    handleCloseAddModal,
    handleOpenAddModal,
    showAddModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();

  const {
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    handleBody,
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    refresh,
    refreshGrid,
  } = useGrid({
    model: null,
    field: "vehicle_id",
    type: "ASC",
  });

  const { filters, onFilterChange } = useFilters(
    filtersFolioInProgress,
    cleanPage
  );

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      filters,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
    };
    FolioService.getList(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, filters, currentSort, refresh]);

  const sortColumns = (body) =>
    body.map((e) => ({
      vehicle_id: e.vehicle_id,
      plate_number: SDPBadge(e.plate_number, e.sdp),
      status: statusBadge(e.status),
      // remaining_time: remainingTimeBadge(e.remaining_time),
      folio_date: e.folio_date,
      week: e.week,
      transaction_type: e.transaction_type,
      final_payment_date: e.final_payment_date,
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
    }));
  // FIND:FIN

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("APERTURA FOLIO");
    setCurrentPermissions(permissions);
  }, []);

  const handleShowModal = (id, e) => {
    e.preventDefault();
    FolioService.getFolioById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenAddModal(true);
      }
    });
  };

  const handleUpdate = async (s) => {
    try {
      const response = await ManagementService.edit(s);
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma - Apertura Folio",
          body: "Se actualizó la información correctamente",
        });
        refreshGrid();
        return true;
      } else {
        addToast({
          variant: "danger",
          header: "Toma - Apertura Folio",
          body: "Ha ocurrido un error en la actualización de la información. Intente nuevamente.",
        });
        return false;
      }
    } catch (error) {
      console.log(error);
      addToast({
        variant: "danger",
        header: "Toma - Apertura Folio",
        body: "Ha ocurrido un error en la actualización de la información. Intente nuevamente.",
      });
      return false;
    }
  };

  const handleShowModalReject = (id, e) => {
    e.preventDefault();
    handleShowRejectModal(true, id);
  };

  const handleDelete = async (params) => {
    try {
      const response = await ManagementService.edit({
        ...params,
        purchase_file_status: purchaseStatus.REJECTED.value,
      });

      if (response?.success) {
        addToast({
          variant: "success",
          header: "Toma - Apertura Folio",
          body: "Se rechazo el tramite correctamente",
        });
        refreshGrid();
        return true;
      }
      throw new Error("Rejection failed");
    } catch (error) {
      console.error("Failed to reject folio:", error);
      addToast({
        variant: "danger",
        header: "Toma - Apertura Folio",
        body: "Ha ocurrido un error al rechazar. Intente nuevamente.",
      });
      return false;
    }
  };

  // const handleCloseModalReject = () => setShowReject(false);
  // FIN Reject Modal

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <ButtonIcon
            onClick={(e) => handleShowModal(props.vehicle_id, e)}
            name="file-alt"
            title="Editar"
          />
        ) : null}
        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <ButtonIcon
            onClick={(e) => handleShowModalReject(props.vehicle_id, e)}
            name="times"
            color="danger"
            size="lg"
            title="Rechazar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <Container fluid>
        <div className={styles.export}>
          <ExcelButton type="comprasAperturaFolio" filters={filters} />
        </div>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          next={next}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
        {showAddModal && (
          <Modal
            content={
              <Content
                data={dataModal}
                handleUpdate={handleUpdate}
                handleClose={handleCloseAddModal}
              />
            }
            showModal={showAddModal}
            handleShow={handleShowModal}
            handleClose={handleCloseAddModal}
          />
        )}
        {showRejectModal.show && (
          <Modal
            content={
              <RejectContent
                data={showRejectModal}
                handleDelete={handleDelete}
                handleClose={handleCloseRejectModal}
              />
            }
            showModal={showRejectModal.show}
            handleShow={handleShowModalReject}
            handleClose={handleCloseRejectModal}
          />
        )}
      </Container>
    </div>
  );
};

export default FolioInProgressList;
