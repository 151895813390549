import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Select from "react-validation/build/select";
import CheckButton from "react-validation/build/button";
import PermissionService from "../../../services/permission.service";
import ProfileService from "../../../services/profile.service";
import FunctionService from "../../../services/function.service";
import { Card } from "../../../components/Card/Card.jsx";
import Dropdown from "../../../components/Table/Dropdown";
import { useToast } from "../../../hooks";
import { updateLocalStorageCache, profiles } from "../../../utils/Service";
import { constants } from "../../../variables/constants";

const PermissionsCreate = (props) => {
  const navigate = useNavigate();
  const [functions, setFunctions] = useState([]);
  const [profile_id, setProfileId] = useState(0);
  const [function_id, setFunctionId] = useState(0);
  const [has_select, setSelect] = useState(0);
  const [has_create, setCreate] = useState(0);
  const [has_update, setUpdate] = useState(0);
  const [has_delete, setDelete] = useState(0);
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    FunctionService.getAll({
      countItems: 999,
      currentPage: 0,
      sortField: "name",
      sortType: "ASC",
    }).then((response) => {
      if (response.data.count > 0) {
        setFunctions(response.data.response);
      }
    });
  }, []);

  const onChangeDelete = (e) => {
    const del = e.target.value === "enabled" ? 1 : 0;
    setDelete(del);
  };

  const onChangeUpdate = (e) => {
    const update = e.target.value === "enabled" ? 1 : 0;
    setUpdate(update);
  };

  const onChangeCreate = (e) => {
    const create = e.target.value === "enabled" ? 1 : 0;
    setCreate(create);
  };

  const onChangeSelect = (e) => {
    let select = e.target.value === "enabled" ? 1 : 0;
    setSelect(select);
  };

  const onChangeFunctionId = (e) => {
    const functionId = e.target.value;
    setFunctionId(functionId);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      PermissionService.create(
        profile_id,
        function_id,
        has_select,
        has_create,
        has_update,
        has_delete
      ).then(
        () => {
          addToast({
            variant: "success",
            header: "Permisos",
            body: "Se creó un nuevo permiso correctamente",
          });
          updateLocalStorageCache().then(() => {
            navigate("/admin/permissions/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Permisos",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/permissions/list"
          className="btn-fill btn btn-info capitalize"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Profile</label>
                    <Dropdown
                      className="form-control"
                      name="user_type"
                      placeholder="Seleccionar..."
                      onSelect={(e) =>
                        setProfileId(
                          e.target.value === constants.dropdownDefault
                            ? null
                            : e.target.value
                        )
                      }
                      options={profiles()}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Function</label>
                    <Select
                      className="form-control"
                      name="user_type"
                      onChange={onChangeFunctionId}
                      // validations={[required]}
                    >
                      <option value="">Seleccionar...</option>
                      {functions.map((prop, key) => {
                        return (
                          <option key={key} value={prop.id}>
                            {prop.name}
                          </option>
                        );
                      })}
                    </Select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Select</label>
                    <Select
                      className="form-control"
                      name="user_type"
                      onChange={onChangeSelect}
                    >
                      <option value="disabled">Inhabilitado</option>
                      <option value="enabled">Habilitado</option>
                    </Select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Create</label>
                    <Select
                      className="form-control"
                      name="user_type"
                      onChange={onChangeCreate}
                    >
                      <option value="disabled">Inhabilitado</option>
                      <option value="enabled">Habilitado</option>
                    </Select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Update</label>
                    <Select
                      className="form-control"
                      name="user_type"
                      onChange={onChangeUpdate}
                    >
                      <option value="disabled">Inhabilitado</option>
                      <option value="enabled">Habilitado</option>
                    </Select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">Delete</label>
                    <Select
                      className="form-control"
                      name="user_type"
                      onChange={onChangeDelete}
                    >
                      <option value="disabled">Inhabilitado</option>
                      <option value="enabled">Habilitado</option>
                    </Select>
                  </div>

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Crear Permiso</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PermissionsCreate;
