import { yesNoOptions } from "../../../../variables/constants";
import { profiles } from "../../../../utils/Service";

export const filterFields = {
  profile: {
    type: "select",
    name: "profile",
    options: profiles(),
    placeholder: "Seleccionar Perfil",
    value: "",
    size: 3,
  },
  status: {
    type: "select",
    name: "status",
    options: yesNoOptions,
    placeholder: "Estado",
    value: "si",
    size: 3,
  },
};
