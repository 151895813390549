import React, { useEffect, useState } from "react";
import Form from "react-validation/build/form";
import Textarea from "react-validation/build/textarea";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import AuditService from "../../../services/audit.service";
import ManagementService from "../../../services/management.service";
import ReceptionPlaceService from "../../../services/receptionPlace.service";
import { diffDateFormula, plateNumberBadge } from "../../../utils/Badges";
import { purchaseComment } from "../../../utils/Comments";
import { purchaseSuspendedRequirements } from "../../../utils/Service";
import {
  constants,
  purchaseStatus,
  purchase_status_select,
  yesNoOptions,
} from "../../../variables/constants";
import { useConfirmationAlert } from "../../../hooks/index";
import AdditionalBlock from "../../AdditionalBlock";
import { Comments } from "../../Comments";
import { DatePickerField, InputField } from "../../Form";
import Dropdown from "../../Table/Dropdown";
import ConfirmationAlert from "../../ConfirmationAlert/confirmationAlert";
import { BadgeField } from "../Sells/components";

const purchase_folio_status_screen = [
  purchaseStatus.FOLIO_IN_PROGRESS.value,
  purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
];

const finalStatus = [
  purchaseStatus.COMPLETED.value,
  purchaseStatus.REJECTED.value,
  purchaseStatus.DISMISSED_KAVAK.value,
];

const Content = (props) => {
  const {
    data: {
      response: {
        vehicle_id,
        plate_number,
        sdp,
        status,
        reception_cat_date,
        final_payment_date,
        take_licence_date,
        extraordinary_date,
        suspended_date,
        presentation_retired_real_date,
        canceled_status_id,
        user_folio_validated,
        delivery_place_id,
        delivery_place_date,
        pledge,
        pledge_start,
        pledge_end,
        issuer_entity_tentative,
        ranking,
        ranking_radicacion,
        transaction_type,
        transaction_type_id,
        tracking_number,
        diff_days,
      },
    },
    handleUpdate,
    handleClose,
  } = props;

  // Objeto con todos los campos editables del popup
  const initialFormState = {
    vehicle_id,
    plate_number,
    sdp,
    purchase_file_status: status,
    take_licence_date:
      take_licence_date === null ? null : new Date(take_licence_date),
    final_payment_date:
      final_payment_date === null ? null : new Date(final_payment_date),
    extraordinary_date:
      extraordinary_date === null ? null : new Date(extraordinary_date),
    suspended_date: suspended_date === null ? null : new Date(suspended_date),
    reception_cat_date:
      reception_cat_date === null ? null : new Date(reception_cat_date),
    comment_reason_id: null,
    comment: null,
    delivery_place_id,
    delivery_place_date:
      delivery_place_date === null ? null : new Date(delivery_place_date),
    pledge,
    pledge_start: pledge_start === null ? null : new Date(pledge_start),
    pledge_end: pledge_end === null ? null : new Date(pledge_end),
    tracking_number,
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [allowSuspend, setAllowSuspend] = useState(false);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [placesOptions, setPlacesOptions] = useState([]);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();

  useEffect(() => {
    AuditService.getHistory(vehicle_id, "comment_summary").then((response) => {
      setComments(response.data.response);
    });
    ReceptionPlaceService.getAll({ sortField: "name", sortType: "ASC" }).then(
      (response) => {
        if (response.data.count > 0) {
          setPlacesOptions(
            response.data.response.map((r) => ({ value: r.id, text: r.name }))
          );
        }
      }
    );

    if (!finalStatus.includes(status)) {
      // Motivos de los comentarios
      ManagementService.getCommentReasons().then((response) => {
        if (response.data.success) {
          setReasons(response.data.response);
        }
      });
    }
  }, [vehicle_id, status]);

  // Al presionar un botón
  useEffect(() => {
    setAllowSubmit(
      reception_cat_date !== null && fields.take_licence_date !== null
    );
    setAllowSuspend(
      !!fields.comment_reason_id &&
        !!fields.comment &&
        parseInt(fields.comment_reason_id) > 28 &&
        parseInt(fields.comment_reason_id) < 44
    );
    if (submitAction) {
      handleUpdate(fields);
      setSubmitAction(false);
      handleClose();
    }
  }, [reception_cat_date, submitAction, fields, handleUpdate, handleClose]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  const handlePreConfirmation = () => {
    let current_status = purchaseStatus.PURCHASE_SUSPENDED.value;
    if (purchase_folio_status_screen.includes(fields.purchase_file_status)) {
      current_status = purchaseStatus.FOLIO_SUSPENDED.value;
    }

    if (
      purchase_status_select
        .filter((s) => s.group === "PRESENTATION" && s.suspended !== true)
        .map((s) => s.value)
        .includes(fields.purchase_file_status)
    ) {
      current_status = purchaseStatus.PRESENTATION_SUSPENDED.value;
    }

    const final_status = purchase_status_select.filter(
      (x) => x.value === current_status
    )[0].text;

    handlePreConfirmationPurchaseModal(
      "purchase_file_status",
      current_status,
      final_status,
      handleChangeStatus
    );
  };

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.domain}
              text={plate_number}
              badgeClass={"plate_number"}
              variant={"warning"}
            />
            <BadgeField
              label={"Días en tramite"}
              text={diff_days ?? 0}
              badgeClass={`capitalize plate_number`}
              variant={diffDateFormula(diff_days)}
            />
            <div className="col">
              <label className="capitalize">
                <strong>{constants.sdp}</strong>
              </label>
              <div>
                <span>
                  <input
                    type="checkbox"
                    name="sdp"
                    onChange={(e) =>
                      onSimpleFieldChange("sdp", e.target.checked)
                    }
                    checked={fields.sdp}
                    disabled={finalStatus.includes(status)}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <BadgeField
              label={"Registro donde se Presenta"}
              text={issuer_entity_tentative}
              badgeClass={"plate_number"}
              variant={"secondary"}
            />
            <BadgeField
              label={"Ranking Presentac."}
              text={ranking}
              badgeClass={"plate_number"}
              variant={"info"}
            />
            <BadgeField
              label={"Ranking Radicación"}
              text={ranking_radicacion}
              badgeClass={"plate_number"}
              variant={"info"}
            />
            <BadgeField
              label={"tipo de operación"}
              text={transaction_type}
              badgeClass={"plate_number"}
              variant={"secondary"}
            />
          </div>

          <div className="row">
            <div className="col-3">
              <label className="capitalize">
                <strong>{constants.pledge}</strong>
              </label>
              <div>
                <Dropdown
                  className="form-control"
                  name="pledge"
                  placeholder="Condición"
                  options={yesNoOptions}
                  selected={
                    fields.pledge?.toLowerCase() ?? constants.dropdownDefault
                  }
                  onSelect={(e) =>
                    onSimpleFieldChange(
                      "pledge",
                      e.target.value === constants.dropdownDefault
                        ? null
                        : e.target.value?.toUpperCase()
                    )
                  }
                  disabled={
                    fields.pledge !== "SI" ||
                    fields.pledge_end ||
                    finalStatus.includes(status)
                  }
                />
              </div>
            </div>

            <div className="col-3">
              <label className="capitalize">
                <strong>{`${constants.pledge} Inicio`}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.pledge_start}
                    onChange={(date) =>
                      onSimpleFieldChange("pledge_start", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={fields.pledge !== "SI" || fields.pledge_end}
                  />
                </span>
              </div>
            </div>

            <div className="col-3">
              <label className="capitalize">
                <strong>{`${constants.pledge} Concluido`}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.pledge_end}
                    onChange={(date) => onSimpleFieldChange("pledge_end", date)}
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={fields.pledge !== "SI" || !fields.pledge_start}
                  />
                </span>
              </div>
            </div>
            <InputField
              label={"Código de Seguimiento"}
              name={"tracking_number"}
              value={fields.tracking_number}
              containerClassName="col-3"
              onChange={(e) =>
                onSimpleFieldChange("tracking_number", e.target.value)
              }
            />
          </div>

          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>{constants.receptionCatDate}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.reception_cat_date}
                    onChange={(date) =>
                      onSimpleFieldChange("reception_cat_date", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={
                      status !== purchaseStatus.PRESENTATION_CAT_RECEIVED.value
                    }
                  />
                </span>
              </div>
            </div>
            {sdp ? (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.finalPaymentDate}</strong>
                </label>
                <div>
                  <span>
                    <DatePicker
                      selected={fields.final_payment_date}
                      onChange={(date) =>
                        onSimpleFieldChange("final_payment_date", date)
                      }
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      disabled={
                        suspended_date !== null || finalStatus.includes(status)
                      }
                    />
                  </span>
                </div>
              </div>
            ) : null}
            <div className="col">
              <label className="capitalize">
                <strong>{constants.takeLicenceDate}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.take_licence_date}
                    onChange={(date) =>
                      onSimpleFieldChange("take_licence_date", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={
                      presentation_retired_real_date === null ||
                      suspended_date !== null ||
                      finalStatus.includes(status)
                    }
                  />
                </span>
              </div>
            </div>
            <div className="col">
              <label className="capitalize">
                <strong>{constants.extraordinaryDate}</strong>
              </label>
              <div>
                <span>
                  <DatePicker
                    selected={fields.extraordinary_date}
                    onChange={(date) =>
                      onSimpleFieldChange("extraordinary_date", date)
                    }
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    disabled={
                      suspended_date !== null || finalStatus.includes(status)
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <label className="capitalize">
                <strong>Folio validado por</strong>
              </label>
              <div>
                <span>{user_folio_validated}</span>
              </div>
            </div>
            {status === purchaseStatus.COMPLETED.value ? (
              <>
                <div className="col">
                  <label className="capitalize">
                    <strong>{constants.deliveryPlace}</strong>
                  </label>
                  <div>
                    <span>
                      <Dropdown
                        className="form-control"
                        name="delivery_place_id"
                        placeholder={constants.deliveryPlace}
                        onSelect={(e) => {
                          onSimpleFieldChange(
                            "delivery_place_id",
                            e.target.value === constants.dropdownDefault
                              ? null
                              : e.target.value
                          );
                        }}
                        selected={delivery_place_id}
                        options={placesOptions}
                      />
                    </span>
                  </div>
                </div>
                <div className="col">
                  <label className="capitalize">
                    <strong>{constants.deliveryPlaceDate}</strong>
                  </label>
                  <div>
                    <span>
                      <DatePicker
                        selected={fields.delivery_place_date}
                        onChange={(date) =>
                          onSimpleFieldChange("delivery_place_date", date)
                        }
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                      />
                    </span>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          <AdditionalBlock vehicle_id={vehicle_id} />
          <Comments
            reasons={reasons}
            comments={purchaseComment(comments)}
            statusList={purchaseStatus}
            onChangeSelect={(e) =>
              onSimpleFieldChange("comment_reason_id", e.target.value)
            }
            onChangeObs={(e) => onSimpleFieldChange("comment", e.target.value)}
            hideReasons={finalStatus.includes(status)}
          />
          {allowSuspend ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>Requisitos pendientes</strong>
                </label>
                <Multiselect
                  options={purchaseSuspendedRequirements()}
                  onSelect={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  onRemove={(selectedItem) => {
                    onSimpleFieldChange("requirements", selectedItem);
                  }}
                  displayValue="text"
                  placeholder="Requisitos"
                  closeIcon="cancel"
                  selectionLimit="-1"
                  showCheckbox={true}
                  singleSelect={false}
                />
              </div>
            </div>
          ) : null}
        </div>
        <br />
        <div className="modal-footer">
          <div>
            {status !== purchaseStatus.REJECTED.value ? (
              <Button
                className="btn-fill pull-right btn btn-secundary btn-sm capitalize"
                onClick={() => setSubmitAction(true)}
              >
                {constants.update}
              </Button>
            ) : null}
          </div>
          {!finalStatus.includes(status) ? (
            <>
              <span className="pull-right" style={{ width: "10px" }}>
                &nbsp;
              </span>
              {suspended_date === null && (
                <>
                  <div className="col">
                    <DatePickerField
                      placeholderText={"Fecha Suspensión (opcional)"}
                      selected={fields.past_suspended_date}
                      onChange={(date) =>
                        onSimpleFieldChange("past_suspended_date", date)
                      }
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      colClassName=""
                    />
                  </div>
                  <Button
                    className="btn-fill pull-right btn btn-danger btn-sm capitalize"
                    value={constants.suspend}
                    onClick={() => {
                      handlePreConfirmation();
                    }}
                    disabled={!allowSuspend || !fields.requirements?.length}
                  >
                    {constants.suspend}
                  </Button>
                </>
              )}
            </>
          ) : null}
          {canceled_status_id === 2 ? (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm capitalize"
                onClick={(e) => {
                  onSimpleFieldChange("canceled_status_id", 3);
                  setSubmitAction(true);
                }}
              >
                Confirmar Baja
              </Button>
            </div>
          ) : null}
          {!finalStatus.includes(status) ? (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm capitalize"
                value={purchaseStatus.COMPLETED.value}
                onClick={(e) => {
                  handlePreConfirmationPurchaseModal(
                    "purchase_file_status",
                    e.target.value,
                    purchaseStatus.COMPLETED.text,
                    handleChangeStatus
                  );
                }}
                disabled={!allowSubmit}
              >
                {constants.finalize}
              </Button>
            </div>
          ) : null}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
