import moment from "moment";
import {
  meses,
  purchaseStatus,
  yesNoOptions,
} from "../../../../variables/constants";
import { employees } from "../../../../utils/Service";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const months = [];
for (let index = 1; index <= 12; index++) {
  months.push({ value: index, text: meses[index] });
}

const weeks = [];
for (let index = 1; index <= 53; index++) {
  weeks.push({ value: index, text: index });
}

const statusOptions = Object.entries(purchaseStatus)
  .map((s) => s[1])
  .sort((a, b) => (a.text < b.text ? -1 : 1));

export const filterFields = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 2,
  },
  year: {
    type: "select",
    name: "year",
    options: years,
    placeholder: "Año",
    value: "",
    size: 2,
  },
  month: {
    type: "select",
    name: "month",
    options: months,
    placeholder: "Mes",
    value: "",
    size: 2,
  },
  week: {
    type: "select",
    name: "week",
    options: weeks,
    placeholder: "Semana",
    value: "",
    size: 2,
    hide: true,
  },
  suspended: {
    type: "select",
    name: "suspended",
    options: yesNoOptions,
    placeholder: "Suspendido",
    value: "",
    size: 3,
  },
  estimatedDate: {
    type: "date",
    name: "estimatedDate",
    placeholder: "Estimada Gestoría",
    value: null,
    size: 3,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: employees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: statusOptions,
    placeholder: "Estado",
    value: [],
    size: 9,
  },
};
