import React, { useState, useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import UserService from "../../../services/user.service";
import { Card } from "../../../components/Card/Card.jsx";
import PasswordStrengthMeter from "../../../components/PasswordStrengthMeter/PasswordStrengthMeter";
import { passwordTracker } from "../../../utils/User";
import { useToast } from "../../../hooks";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio!
      </div>
    );
  }
};

const UsersEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const user_id = params.id;
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  const passwordStrength = Object.values(passwordTracker(password)).filter(
    (value) => value
  ).length;

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangePasswordRepeat = (e) => {
    const passwordRepeat = e.target.value;
    setPasswordRepeat(passwordRepeat);
  };

  const verifyPassword = () => {
    if (password !== passwordRepeat) {
      return false;
    }

    return true;
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    if (verifyPassword()) {
      UserService.changePassword(user_id, password).then(
        () => {
          addToast({
            variant: "success",
            header: "Usuarios",
            body: "Se actualizó la contraseña correctamente",
          });
          navigate("/admin/users/list");
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          addToast({
            variant: "danger",
            header: "Usuarios",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
      addToast({
        variant: "danger",
        header: "Usuarios",
        body: "La contraseña es incorrecta",
      });
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href={`/admin/users/${user_id}/edit`}
          className="btn-fill pull-left btn btn-info btn-sm"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                <Form onSubmit={handSubmit} ref={form}>
                  <div className="form-group">
                    <label htmlFor="email">Nueva Contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password"
                      placeholder="Usuario"
                      value={password}
                      onChange={onChangePassword}
                      validations={[required]}
                    />
                  </div>
                  <PasswordStrengthMeter
                    password={password}
                    passwordStrength={passwordStrength}
                  />

                  <div className="form-group">
                    <label htmlFor="email">Repetir Contraseña</label>
                    <Input
                      type="password"
                      className="form-control"
                      name="password_repeat"
                      placeholder="Usuario"
                      value={passwordRepeat}
                      onChange={onChangePasswordRepeat}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group mt-3">
                    <button
                      className="btn btn-primary btn-fill pull-right"
                      disabled={
                        loading ||
                        password.length < 8 ||
                        password !== passwordRepeat
                      }
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Cambiar Constraseña</span>
                    </button>
                  </div>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />

                  <div className="clearfix" />
                </Form>
              }
            />
          </Col>
        </Row>
      </Container>
      <style jsx>
        {`
          .password-strength-meter {
            height: 0.3rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: 0.5rem 0;
          }

          .password-strength-meter::before {
            content: "";
            background-color: ${[
              "red",
              "orange",
              "#03a2cc",
              "#03a2cc",
              "#0ce052",
            ][passwordStrength - 1] || ""};
            height: 100%;
            width: ${(passwordStrength / 5) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
      </style>
    </div>
  );
};

export default UsersEdit;
