import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters/index";
import List from "../../components/Table/List";
import {
  useFilters,
  useGrid,
  useStatusModal,
  useConfiguration,
  useToast,
} from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import VtvService from "../../services/vtv/vtv.service";
import styles from "../Purchase/General/styles.module.scss";
import { head, headOther } from "./constants/constants_columns";
import { filtersVtv } from "./constants/constants_filter";
import { Edit, ShowRecords } from "../../components/Modal/Vtv/index";
import UploadFile from "../../components/Modal/UploadFile/uploadFile";
import RejectSell from "../../components/Modal/Sells/rejectSell";
import ExcelButton from "../../components/ExcelButton";
import { badgeGeneric } from "../../utils/Badges";
import { downloadAllZip } from "../../utils/File";
import { constants } from "../../variables/constants";

const handleDownload = (data, e) => {
  e.preventDefault();
  const params = {
    countItems: 999,
    currentPage: 0,
    schedule_id: data.schedule_id,
  };
  VtvService.download(params).then((response) => {
    if (response.data.success) {
      downloadAllZip(response.data, data.plate_number);
    }
  });
};

const downloadColumn = (data) => {
  const showIcons = data.imagenes > 0 && data.schedule_id;
  return showIcons ? (
    <Button
      onClick={(e) => handleDownload(data, e)}
      className="btn-simple btn btn-xs btn-secondary"
    >
      <i className="fas fa-download" aria-hidden="true"></i>
    </Button>
  ) : null;
};

const sortColumns = (body, profile) => {
  return body.map((e) => {
    const columns = {
      id: e.id,
      vehicle_id: e.vehicle_id,
      plate_number: e.plate_number,
      status_sell: e.status_sell,
      delivery_place: e.delivery_place,
      parking_lot: e.parking_lot,
      ot: e.ot,
      vehicle_year: e.vehicle_year,
      vehicle_make: e.vehicle_make,
      vehicle_model: e.vehicle_model,
      vehicle_mileage: e.vehicle_mileage,
      vehicle_chasis: e.vehicle_chasis,
      expired_date: e.expired_date,
      status_vtv: badgeGeneric(
        e.status_vtv === 'Vigente' ? "success" : "danger",
        e.status_vtv
      ),
      original_schedule_date: e.original_schedule_date,
      schedule_date: e.schedule_date,
      vtv_reception_place: e.vtv_reception_place,
      schedule_real_date: e.schedule_real_date,
      schedule_status: e.schedule_status,
      new_expired_date: e.new_expired_date,
      observation: downloadColumn(e, handleDownload),
      _hidden: e.hidden,
    };

    if (profile && profile !== constants.adminProfile) {
      delete columns.vehicle_mileage;
      delete columns.vehicle_chasis;
      delete columns.vtv_reception_place;
      delete columns.new_expired_date;
    }

    return columns;
  });
};

const sort = {
  model: null,
  field: "vehicle_id",
  type: "ASC",
};

const VtvList = (props) => {
  const {
    handleCloseEditModal,
    handleOpenEditModal,
    showEditModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    cleanPage,
    refresh,
    refreshGrid,
  } = useGrid(sort);

  const [dataModal, setDataModal] = useState([]);
  const [reasons, setReasons] = useState([]);
  const { filters, onFilterChange } = useFilters(filtersVtv, cleanPage);
  const { currentPermissions, profile } = useConfiguration("VTV");
  const [records, setRecords] = useState([]);
  const [showRecordModal, setShowRecordModal] = useState(false);

  const [openUploadModal, setOpenUploadModal] = useState(false);

  const addToast = useToast();

  const next = {
    link: "/admin/management/toma-vehiculo",
    text: "",
  };

  const openEditModal = async (id) => {
    VtvService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });

    await getReasons();
  };

  const openRecordModal = async (id) => {
    try {
      const response = await VtvService.auditVtvSchedule(id);
      if (response.status === 200) {
        const finalRecords = response.data.response.map((record) => {
          return {
            ...record,
            hide: true,
          };
        });
        setRecords(finalRecords);
      }
      setShowRecordModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const getReasons = async () => {
    try {
      const response = await VtvService.getCommentReasons();
      if (response.status === 200) {
        setReasons(response.data.response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleVisibility = async (vtv_id, hidden) => {
    VtvService.toggleHidden({ vtv_id, hidden }).then((response) => {
      if (response.status === 200) {
        addToast({
          variant: "success",
          header: "VTV",
          body: "Se ha actualizado la visibilidad correctamente",
        });
        refreshGrid();
      } else {
        addToast({
          variant: "danger",
          header: "VTV",
          body: "Ha ocurrido un error, intente nuevamente",
        });
      }
    });
  };

  useEffect(() => {
    if (profile) {
      const params = {
        page: currentPage,
        limit: countItems, // like countItems
        sortModel: currentSort?.model,
        sortField: currentSort?.field,
        sortType: currentSort?.type,
        filters,
      };

      VtvService.getAll(params).then((response) => {
        handleTotalItems(response.data.totalItems);
        handleTotalPage(Math.ceil(response.data.totalItems / countItems));
        handleBody(sortColumns(response.data.response, profile));
      });
    }
    //eslint-disable-next-line
  }, [currentPage, currentSort, countItems, filters, refresh, profile]);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect && (
          <>
            <Button
              onClick={(e) => openEditModal(props.id, e)}
              className="btn-simple btn btn-xs btn-secondary"
            >
              <i className="fas fa-file-alt" aria-hidden="true"></i>
            </Button>
            <Button
              onClick={(e) => openRecordModal(props.id, e)}
              className="btn-simple btn btn-xs btn-secondary"
            >
              <i className="fas fa-history" aria-hidden="true"></i>
            </Button>
          </>
        )}
        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <>
            <Button
              onClick={() => handleVisibility(props.id, !props._hidden)}
              className="btn-simple btn btn-xs btn-warning"
            >
              <i className={`fa fa-eye${!props._hidden ? "-slash" : ""}`}></i>
            </Button>
            <Button
              onClick={() => handleShowRejectModal(props.id)}
              className="btn-simple btn btn-xs btn-danger"
            >
              <i className="fas fa-times"></i>
            </Button>
          </>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={`content-filters form-group ${styles.lessHeight}`}>
        <Button
          className="btn-fill btn btn-primary capitalize"
          onClick={handleOpenUploadModal}
        >
          Cargar archivo VTV
        </Button>
        <ExcelButton type="vtv" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={profile && profile !== constants.adminProfile ? headOther : head}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {openUploadModal && (
        <Modal
          content={
            <UploadFile
              processName={"9_read_vtv_xlsx_file"}
              title={"Archivo VTV"}
              handleClose={handleCloseUploadModal}
            />
          }
          showModal={openUploadModal}
          handleShow={openUploadModal}
          handleClose={handleCloseUploadModal}
        />
      )}
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              reasons={reasons}
              profile={profile}
              currentPermissions={currentPermissions}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={handleOpenEditModal}
          handleClose={handleCloseEditModal}
          // headerTitle={statusBadge(dataModal?.response?.status)}
        />
      )}
      {showRecordModal && (
        <Modal
          content={
            <ShowRecords
              data={records}
              handleClose={() => setShowRecordModal(false)}
            />
          }
          showModal={showRecordModal}
          handleShow={() => setShowRecordModal(true)}
          handleClose={() => setShowRecordModal(false)}
          // headerTitle={statusBadge(dataModal?.response?.status)}
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
              getCancelReasons={() => VtvService.getCancelReasons()}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default VtvList;
