import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import FunctionService from "../../../services/function.service";
import AuthService from "../../../services/auth.service";
import styles from "../../Purchase/General/styles.module.scss";
import { useToast } from "../../../hooks";
import List from "../../../components/Table/List.js";
import ButtonIcon from "../../../components/ButtonIcon";

const headSort = [
  { text: "Id", field: "id", model: null },
  { text: "Nombre", field: "name", model: null },
  { text: "Descripción", field: "description", model: null },
  { text: "Título", field: "title", model: null },
  { text: "Posición", field: "position", model: null },
  { text: "", field: null, model: null },
];

const FunctionsList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const addToast = useToast();
  const navigate = useNavigate();

  //PAGINATE
  const countItems = 20;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({
    model: null,
    field: "id",
    type: "ASC",
  });

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  useEffect(() => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    FunctionService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setTotalItems(response.data.totalItems);
        const result = Math.ceil(response.data.totalItems / countItems);
        setTotalPage(result);
        setBody(response.data.response);
      }
    });
  }, [currentPage, currentSort]);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("FUNCIONES");
    setCurrentPermissions(permissions);
  }, []);

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm("¿Está seguro que desea eliminar la función?");
    if (del) {
      FunctionService.del(id).then(() => {
        let list = body.filter((data) => data.id !== parseInt(id));
        setBody(list);
        addToast({
          variant: "success",
          header: "Funciones",
          body: "Se eliminó la función correctamente",
        });
      });
    }
  };

  const actions = (props) => {
    return (
      <>
        {currentPermissions?.permissions?.hasUpdate ? (
          <ButtonIcon
            onClick={() => navigate(`/admin/functions/${props.id}/edit`)}
            name="edit"
            title="Editar"
          />
        ) : null}

        {currentPermissions?.permissions?.hasDelete ? (
          <ButtonIcon
            onClick={(e) => handleDelete(props.id, e)}
            name="times"
            color="danger"
            size="lg"
            title="Eliminar"
          />
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className="content-filters ps-3 mb-4">
        <Button
          onClick={() => navigate(`/admin/functions/create`)}
          className="btn-fill btn btn-primary capitalize"
        >
          Nueva Función
        </Button>
      </div>
      <Container fluid>
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default FunctionsList;
