import { constants } from "../../../../variables/constants";

export const headSortAgent = [
  { text: "Id", field: "vehicle_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: constants.status, field: "purchase_file_status", model: null },
  {
    text: constants.transactionType,
    field: "transaction_type_id",
    model: null,
  },
  {
    text: "Registro donde se Presenta",
    field: "description",
    model: "issuer_entity_tentative",
  },
  { text: constants.zone, field: "zone", model: "issuer_entity" },
  { text: "entrega a logística", field: "updatedAt", model: "purchase_presentation_waiting" },
  { text: constants.turnRequestDate, field: "turn_request_date", model: null },
  {
    text: constants.realPresentationDate,
    field: "updatedAt",
    model: "purchase_presentation_presented",
  },
  { text: constants.turnTakeDate, field: "turn_take_date", model: null },
  { text: constants.realRetiredDate, field: "updatedAt", model: "purchase_presentation_retired" },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: constants.lossRequest, field: "loss_request", model: null },
  { text: "", field: null, model: null },
];

export const headSortOthers = [
  { text: "Id", field: "vehicle_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: constants.status, field: "purchase_file_status", model: null },
  {
    text: constants.transactionType,
    field: "transaction_type_id",
    model: null,
  },
  {
    text: "Registro donde se Presenta",
    field: "description",
    model: "issuer_entity_tentative",
  },
  { text: constants.zone, field: "zone", model: "issuer_entity" },
  { text: constants.agentPresenting, field: "agent_id", model: null },
  { text: "entrega a logística", field: "updatedAt", model: "purchase_presentation_waiting" },
  { text: constants.turnRequestDate, field: "turn_request_date", model: null },
  {
    text: constants.realPresentationDate,
    field: "updatedAt",
    model: "purchase_presentation_presented",
  },
  { text: constants.turnTakeDate, field: "turn_take_date", model: null },
  { text: constants.realRetiredDate, field: "updatedAt", model: "purchase_presentation_retired" },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: constants.lossRequest, field: "loss_request", model: null },
  { text: constants.attachments, field: null, model: null },
  { text: "", field: null, model: null },
];
