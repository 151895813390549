import axios from "axios";
import authHeader from "../auth-header";

const API_URL = process.env.REACT_APP_API_URL;

const getPrintForms = async () => {
  return await axios.get(`${API_URL}/print-forms`, { headers: authHeader() });
};

const logPrint = (payload) => {
  return axios
    .put(`${API_URL}/print`, { ...payload }, { headers: authHeader() })
    .then((response) => {
      if (response.data.success) {
        return response.data;
      } else {
        throw new Error(response.data.message);
      }
    });
};

const getConfig = async (print_form_id, reception_place_id) => {
  return await axios.get(
    `${API_URL}/print-config/${print_form_id}/${reception_place_id}`,
    { headers: authHeader() }
  );
};

const saveConfig = async (print_form_id, reception_place_id, data) => {
  return await axios.post(
    `${API_URL}/print-config/${print_form_id}/${reception_place_id}`,
    data,
    { headers: authHeader() }
  );
};

const saveCoordinates = async (print_form_id, reception_place_id, data) => {
  return await axios.post(
    `${API_URL}/print-coordinates/${print_form_id}/${reception_place_id}`,
    data,
    { headers: authHeader() }
  );
};

const getFormDetail = async (print_form_id) => {
  return await axios.get(`${API_URL}/print-form/${print_form_id}`, {
    headers: authHeader(),
  });
};

export default {
  getConfig,
  getFormDetail,
  getPrintForms,
  logPrint,
  saveConfig,
  saveCoordinates,
};
