import { constants } from "../../../../variables/constants";

export const headSort = [
  { text: "Id", field: "vehicle_id", model: null },
  { text: constants.domain, field: "plate_number", model: null },
  { text: "año toma", field: "createdAt", model: null },
  { text: "mes toma", field: "createdAt", model: null },
  { text: "semana toma", field: "createdAt", model: null },
  { text: "Fecha Firma 08", field: "updatedAt", model: "purchase_file_form08,forms_status_history" },
  { text: "Fecha Recepción 08", field: "updatedAt", model: "purchase_file_form08,forms_08_received" },
  { text: "Fecha confección tramite", field: "updatedAt", model: "purchase_file_history" },
  { text: "Entrega a Logística", field: "updatedAt", model: "purchase_presentation_waiting" },
  { text: "Fecha real presentación", field: "updatedAt", model: "purchase_presentation_presented" },
  { text: "Fecha real retiro", field: "updatedAt", model: "purchase_presentation_retired" },
  { text: `Fecha ${constants.receptionCatDate}`, field: "reception_cat_date", model: null },
  { text: `Fecha ${constants.takeLicenceDate}`, field: "take_licence_date", model: null },
  { text: "Persona Responsable", field: "person_in_charge_id", model: null },
  { text: constants.status, field: "purchase_file_status", model: null },
  { text: "Fecha Suspensión", field: "suspended_date", model: null },
  { text: constants.finalPaymentDate, field: "final_payment_date", model: null },
  { text: constants.estimatedDate, field: "estimated_date", model: null },
  { text: constants.purchaseCanceledStatus, field: "canceled_status_id", model: null },
  { text: "Aging", field: null, model: null },
  { text: "Días en tramite", field: null, model: null },
  { text: constants.attachments, field: null, model: null },
  { text: "", field: null, model: null },
];