import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import Select from "react-validation/build/select";
import PermissionService from "../../../services/permission.service";
import ProfileService from "../../../services/profile.service";
import FunctionService from "../../../services/function.service";
import { Card } from "../../../components/Card/Card.jsx";
import NotFound from "../../../components/Errors/NotFound.js";
import Dropdown from "../../../components/Table/Dropdown";
import { useToast } from "../../../hooks";
import { updateLocalStorageCache, profiles } from "../../../utils/Service";
import { constants } from "../../../variables/constants";

const FunctionsCreate = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const permissionsId = params.id;
  const [functions, setFunctions] = useState([]);
  const [profileId, setProfileId] = useState(0);
  const [functionId, setFunctionId] = useState(0);
  const [hasSelect, setSelect] = useState(0);
  const [hasCreate, setCreate] = useState(0);
  const [hasUpdate, setUpdate] = useState(0);
  const [hasDelete, setDelete] = useState(0);
  const [loading, setLoading] = useState(false);
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    FunctionService.getAll({
      countItems: 999,
      currentPage: 0,
      sortField: "name",
      sortType: "ASC",
    }).then((response) => {
      if (response.data.count > 0) {
        setFunctions(response.data.response);
      }
    });
  }, []);

  useEffect(() => {
    PermissionService.getById(permissionsId)
      .then((response) => {
        if (response.data.success && response.data.data) {
          setFunctionId(response.data.data.function_id);
          setProfileId(response.data.data.profile_id);
          setSelect(response.data.data.has_select);
          setCreate(response.data.data.has_create);
          setUpdate(response.data.data.has_update);
          setDelete(response.data.data.has_delete);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [permissionsId]);

  const onChangeDelete = (e) => {
    const del = e.target.value;
    setDelete(del);
  };

  const onChangeUpdate = (e) => {
    const update = e.target.value;
    setUpdate(update);
  };

  const onChangeCreate = (e) => {
    const create = e.target.value;
    setCreate(create);
  };

  const onChangeSelect = (e) => {
    const select = e.target.value;
    setSelect(select);
  };

  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      PermissionService.edit(
        permissionsId,
        hasSelect,
        hasCreate,
        hasUpdate,
        hasDelete
      ).then(
        () => {
          const localStorageuser = JSON.parse(localStorage.getItem("user"));

          let row = localStorageuser.data.permissions.find(
            (permission) => permission.permissionId === parseInt(permissionsId)
          );

          const objectToUpdate = {
            section: row.section,
            permissionId: parseInt(permissionsId),
            permissions: {
              hasSelect: parseInt(hasSelect),
              hasCreate: parseInt(hasCreate),
              hasUpdate: parseInt(hasUpdate),
              hasDelete: parseInt(hasDelete),
            },
          };

          let filter = localStorageuser.data.permissions.filter(
            (permission) => permission.permissionId !== parseInt(permissionsId)
          );

          localStorageuser.data.permissions = filter;

          localStorageuser.data.permissions.push(objectToUpdate);

          localStorage.setItem("user", JSON.stringify(localStorageuser));

          addToast({
            variant: "success",
            header: "Permisos",
            body: "Se actualizó la información correctamente",
          });

          updateLocalStorageCache().then(() => {
            navigate("/admin/permissions/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          addToast({
            variant: "danger",
            header: "Permisos",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/permissions/list"
          className="btn-fill btn btn-info capitalize"
        >
          Volver
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="form-group">
                      <label htmlFor="email">Profile</label>
                      <Dropdown
                        className="form-control"
                        name="user_type"
                        placeholder="Seleccionar..."
                        onSelect={(e) =>
                          setProfileId(
                            e.target.value === constants.dropdownDefault
                              ? null
                              : e.target.value
                          )
                        }
                        options={profiles()}
                        selected={profileId}
                        disabled
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Function</label>
                      <Select
                        value={functionId}
                        disabled
                        className="form-control"
                        name="user_type"
                      >
                        <option value="">Seleccionar...</option>
                        {functions.map((prop, key) => {
                          return (
                            <option key={key} value={prop.id}>
                              {prop.name}
                            </option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Select</label>
                      <Select
                        value={hasSelect}
                        className="form-control"
                        name="user_type"
                        onChange={onChangeSelect}
                      >
                        <option value="0">Inhabilitado</option>
                        <option value="1">Habilitado</option>
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Create</label>
                      <Select
                        value={hasCreate}
                        className="form-control"
                        name="user_type"
                        onChange={onChangeCreate}
                      >
                        <option value="0">Inhabilitado</option>
                        <option value="1">Habilitado</option>
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Update</label>
                      <Select
                        value={hasUpdate}
                        className="form-control"
                        name="user_type"
                        onChange={onChangeUpdate}
                      >
                        <option value="0">Inhabilitado</option>
                        <option value="1">Habilitado</option>
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">Delete</label>
                      <Select
                        value={hasDelete}
                        className="form-control"
                        name="user_type"
                        onChange={onChangeDelete}
                      >
                        <option value="0">Inhabilitado</option>
                        <option value="1">Habilitado</option>
                      </Select>
                    </div>

                    <div className="form-group mt-3">
                      <button
                        className="btn btn-primary btn-fill pull-right"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar Permiso</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FunctionsCreate;
