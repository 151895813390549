import React, { useState } from "react";
import { Button, ModalFooter } from "react-bootstrap";
import { processFiles } from "../../../../utils/File";
import uploadXlsxFile from "../../../../services/settings/uploadXlsxFile.service";
import { useToast } from "../../../../hooks";

const UploadXlsxFile = (props) => {
  const { title, processName, handleClose } = props;
  const [files, setFiles] = useState(null);
  const addToast = useToast();

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files, processName);
    setFiles({
      images: [...newImages],
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await uploadXlsxFile.saveXlsxFile(files);
      if (response.status === 200 && response.data.success) {
        addToast({
          variant: "success",
          header: title,
          body: "Se ha subido el archivo con exito",
        });
      } else {
        addToast({
          variant: "danger",
          header: title,
          body: "Ha ocurrido un error",
        });
      }
      if (handleClose) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <div className="col">
        <label className="capitalize">
          <strong>{title}</strong>
        </label>
        <div>
          <span>
            <input
              type="file"
              accept=".xlsx"
              className="form-control"
              name="xlsx"
              encType="multipart/form-data"
              multiple={false}
              onChange={handleFiles}
            />
          </span>
        </div>
      </div>
      <div className="p-2">
        <ModalFooter>
          <Button
            className="btn-fill pull-right btn btn-info btn-sm capitalize"
            disabled={files && files.images ? false : true}
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </ModalFooter>
      </div>
    </div>
  );
};

export default UploadXlsxFile;
