import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Multiselect } from "multiselect-react-dropdown";
import { Button } from "react-bootstrap";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import Textarea from "react-validation/build/textarea";

import Dropdown from "../../../components/Table/Dropdown";
import ConfirmationAlert from "../../../components/ConfirmationAlert/confirmationAlert";
import { BadgeField } from "../Sells/components";
import { Comments } from "../../../components/Comments";

import { useConfirmationAlert, useToast } from "../../../hooks";
import AuditService from "../../../services/audit.service";
import AuthService from "../../../services/auth.service";
import ManagementService from "../../../services/management.service";
import NotaryService from "../../../services/notary.service";
import PurchaseReceptionService from "../../../services/purchaseReception.service";
import StatusForm08Service from "../../../services/form08/statusForm08.service";
import UploadService from "../../../services/upload.service";
import NotaryCollegeService from "../../../services/purchase/notaryCollege.service";

import {
  badgeGeneric,
  status08Badge,
  status08BadgeField,
} from "../../../utils/Badges";
import { processFiles } from "../../../utils/File";
import { issuerEntitiesAll } from "../../../utils/Service";
import { formatAmount } from "../../../utils/Numbers";
import {
  constants,
  fileSections,
  formType,
  form_type,
  purchaseStatus,
  status08,
  yesNoOptions,
} from "../../../variables/constants";
import { DatePickerField, InputField, SelectField } from "../../Form/index";

// const resetRemaining = [status08.SIGNED.value, status08.OBSERVED.value];

const finalStatus = [status08.COMPLETED.value, status08.CANCELED.value];

const disabledUpdate = [
  purchaseStatus.COMPLETED.value,
  purchaseStatus.REJECTED.value,
  purchaseStatus.DISMISSED_KAVAK.value,
];

const allowUpdateIssuerEntity = [
  purchaseStatus.PENDING_VALIDATION.value,
  purchaseStatus.VALIDATION_EXCEPTION.value,
  purchaseStatus.SIGNING_FORM_08.value,
  purchaseStatus.FOLIO_IN_PROGRESS.value,
  purchaseStatus.FOLIO_IN_PROGRESS_STAND_BY.value,
];

// Campos opcionales para avanzar en el flujo
const avoidFieldsValidation = [
  "cancel_reason_id",
  "observation",
  "issuer_entity_tentative_id",
  "document_count",
  "notary_college_id",
  "withdrawal_legalization_date",
  "legalization_date",
  "received_certification_date",
  "send_certification_date",
];

const hideReceptionByType = Object.entries(form_type)
  .filter((f) => f[1].hideReception)
  .map((t) => t[1].value);
// Motivos de comentarios que no se pueden observar
const excludeReasons = [6, 7];

const Content = (props) => {
  const {
    data: {
      response: {
        id,
        vehicle_id,
        form_number,
        plate_number,
        current_status,
        notary_office,
        form_type,
        signature_count,
        signature_count_client,
        sworn_statement,
        zero_two,
        enterprise_signature,
        issuer_entity_tentative_id,
        issuer_entity_tentative,
        ranking,
        purchase_file_status,
        updatedAt,
        document_count,
        notary_college_id,
        // remaining,
        sworn_blue_status,
        withdrawal_legalization_date,
        legalization_date,
        received_certification_date,
        send_certification_date,
      },
    },
    handleClose,
    setRefresh,
  } = props;

  // Siguiente estado
  const nextStatus = status08[status08[current_status].next];

  const initialFormState = {
    id,
    plate_number,
    form_code: "08",
    current_status,
    form_type_id: form_type?.id,
    signature_count,
    signature_count_client,
    sworn_statement,
    zero_two,
    enterprise_signature,
    images: [],
    cancel_reason_id: null,
    observation: null,
    issuer_entity_tentative_id,
    sworn_blue_status: sworn_blue_status,
    document_count,
    notary_college_id,
    withdrawal_legalization_date,
    legalization_date,
    send_certification_date,
    received_certification_date,
  };

  const [submitAction, setSubmitAction] = useState(false);
  const [fields, setFields] = useState(initialFormState);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [allowProfile, setAllowProfile] = useState(false);
  const [profile, setProfile] = useState(null);
  const [plateNumberAvailable, setPlateNumberAvailable] = useState("");
  const [disabledPlateNumber, setDisabledPlateNumber] = useState(false);
  const [hasReceptionDate, setHasReceptionDate] = useState(false);
  const [comments, setComments] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [notaryOffices, setNotaryOffices] = useState([]);
  const [selectedIssuer, setSelectedIssuer] = useState(null);
  const [notaryCollege, setNotaryColleges] = useState([]);

  const statusButtonRef = useRef(false);
  const statusRef = useRef(false);
  const issuerEntityUpdateRef = useRef(false);

  const { confirmationModal, handlePreConfirmationPurchaseModal } =
    useConfirmationAlert();
  const addToast = useToast();

  const onSelect = (selectedList, selectedItem) => {
    setSelectedIssuer([selectedItem]);
    onSimpleFieldChange("issuer_entity_tentative_id", selectedItem.value);
  };

  // Eliminación del emisor
  const onRemove = () => {
    onSimpleFieldChange("issuer_entity_tentative_id", null);
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("ESTADOS_08");
    const user = JSON.parse(localStorage.getItem("user")) || null;
    // Verificación del perfil para la accion principal
    setAllowProfile(
      user.data.user.profile.name.toLowerCase() ===
        status08[current_status].profile.toLowerCase() ||
        (user.data.user.profile.name.toLowerCase() !==
          constants.notaryProfile &&
          permissions.permissions.hasDelete === 1)
    );

    setProfile(user.data.user.profile.name.toLowerCase());
    // El escribano puede editarla cuando esta en espera o cuando no esta el vehicle_id (!= null) para evitar errores de consistencia de datos
    setDisabledPlateNumber(
      (user.data.user.profile.name.toLowerCase() === constants.notaryProfile &&
        current_status !== status08.WAITING.value) ||
        vehicle_id !== null
    );

    if (issuer_entity_tentative_id != null) {
      setSelectedIssuer([
        {
          value: issuer_entity_tentative_id,
          text: issuer_entity_tentative,
        },
      ]);
    }

    // Verifica si se cargo la fecha de toma
    if (plate_number) {
      PurchaseReceptionService.findOneByPlateNumber(plate_number).then(
        (responseP) => {
          if (responseP.data.success) {
            setHasReceptionDate(
              moment(responseP.data.data.is_recived_date).format("DD/MM/YYYY")
            );
          } else if (vehicle_id) {
            ManagementService.getById(vehicle_id).then((responseM) => {
              if (responseM.data.success) {
                setHasReceptionDate(
                  responseM.data.response.recived_date ?? false
                );
              }
            });
          } else {
            setHasReceptionDate(false);
          }
        }
      );
      // Busqueda de comentarios historicos
      AuditService.getFormComments(id, "comment_summary").then((response) => {
        setComments(response.data.response);
      });
      // Motivos de los comentarios
      StatusForm08Service.getCancelReasons().then((response) => {
        if (response.data.success) {
          setReasons(response.data.response);
        }
      });
    }
    if (current_status === status08.WAITING.value) {
      NotaryService.getAll({ sortField: "name", sortType: "ASC" }).then(
        (response) => {
          if (response.data.count > 0) {
            setNotaryOffices(response.data.response);
          }
        }
      );
    }

    //here we are
    statusButtonRef.current = finalStatus.includes(current_status);

    statusRef.current = !finalStatus.includes(current_status);

    if (purchase_file_status != null) {
      statusRef.current =
        !disabledUpdate.includes(purchase_file_status) &&
        !finalStatus.includes(current_status);

      statusButtonRef.current =
        disabledUpdate.includes(purchase_file_status) &&
        finalStatus.includes(current_status);

      issuerEntityUpdateRef.current =
        statusRef.current &&
        allowUpdateIssuerEntity.includes(purchase_file_status);
    }

    // eslint-disable-next-line
    NotaryCollegeService.getAllNotaryCollege().then((response) => {
      if (response.data.response.length > 0) {
        setNotaryColleges(response.data.response);
      }
    });

    const notary_office_id =
      user.data.user.notary_office_users[0]?.notary_office_id;
  }, []);

  // Al presionar un botón
  useEffect(() => {
    // Validacion de campos
    const simplesFields =
      Object.entries(fields).filter(
        (f) =>
          !avoidFieldsValidation.includes(f[0]) &&
          (f[1] === null ||
            f[1] === "null" ||
            f[1] === "" ||
            f[1] === undefined)
      ).length === 0;
    // Validaciones adicionales
    // Proximo estado Observado y sin motivo o comentario
    const allowObserved =
      !finalStatus.includes(current_status) &&
      nextStatus.value === status08.OBSERVED.value &&
      fields.cancel_reason_id !== null &&
      fields.observation?.length > 0 &&
      !excludeReasons.includes(parseInt(fields.cancel_reason_id));
    // Criterio de legalizado para pasar a entregado
    // const blockDelivery =
    //   nextStatus && nextStatus.value === status08.DELIVERED.value;

    const blockNotary =
      profile === constants.notaryProfile &&
      nextStatus &&
      nextStatus.value === status08.RECEIVED.value &&
      fields.sworn_blue_status;

    const blockAgentExt =
      nextStatus &&
      nextStatus.value === status08.OBSERVED.value &&
      constants.agentExternalProfile === profile;

    setAllowSubmit(
      simplesFields &&
        allowProfile &&
        !allowObserved &&
        // !blockDelivery &&
        !blockNotary &&
        !blockAgentExt
    );
    if (submitAction) {
      setSubmitAction(false);

      let dataForm = {
        ...fields,
        sworn_blue_status: fields.sworn_blue_status === "si" ? 1 : 0,
      };

      const arrPromises = [];

      if (fields.images.length > 0) {
        const images = {
          vehicle_id,
          plate_number: fields.plate_number,
          section: fileSections.form08,
          images: [...fields.images],
        };

        delete dataForm.images;

        const promise_images = new Promise((resolve, reject) => {
          if (fields.images.length > 0) {
            UploadService.save(images).then((response) => {
              if (response.success) {
                resolve("Se guardaron los documentos");
              }
              reject("Fallo el guardado de documentos");
            });
          }
        });

        arrPromises.push(promise_images);
      }

      const promise_form = new Promise((resolve, reject) => {
        StatusForm08Service.edit(dataForm).then((response) => {
          if (response.success) {
            resolve("Se actualizaron los datos");
          }
          reject("Fallo la actualización de los datos");
        });
      });

      arrPromises.push(promise_form);

      Promise.all(arrPromises)
        .then((values) => {
          values.map((v) =>
            addToast({
              variant: "success",
              header: "Formularios 08",
              body: v,
            })
          );
          setRefresh(Date.now());
          handleClose();
        })
        .catch((reason) => {
          addToast({
            variant: "danger",
            header: "Formularios 08",
            body: reason,
          });
        });
    }
  }, [
    submitAction,
    fields,
    allowProfile,
    current_status,
    nextStatus,
    vehicle_id,
    // remaining,
    handleClose,
    setRefresh,
    profile,
  ]);

  useEffect(() => {
    if (fields?.plate_number?.length > 5) {
      const params = {
        page: 0,
        limit: 10,
        filters: {
          plateNumber: {
            name: "plateNumber",
            placeholder: "Dominio",
            size: 2,
            type: "text",
            value: fields.plate_number,
          },
        },
        sortField: null,
        sortType: null,
        sortModel: null,
      };
      StatusForm08Service.getAll(params).then((response) => {
        setPlateNumberAvailable(
          response.data.totalItems === 0
            ? badgeGeneric("success", "disponible")
            : badgeGeneric("warning", "utilizada - verificar")
        );
      });
    } else {
      setPlateNumberAvailable("");
    }
  }, [fields.plate_number]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const handleFiles = async (e) => {
    const newImages = await processFiles(e.target.files);
    setFields({
      ...fields,
      images: [...newImages],
    });
  };

  const handleReceptionButton = async (e) => {
    e.preventDefault();
    // si ya esta relacionado a la toma, debo actualizar la toma y no guardar el dato temporalmente
    const response = vehicle_id
      ? await ManagementService.edit({
          vehicle_id,
          is_recived: true,
          is_recived_date: new Date(),
        })
      : await PurchaseReceptionService.add({
          plate_number,
          is_recived_date: new Date(),
        });

    if (response.success) {
      setHasReceptionDate(moment(new Date()).format("DD/MM/YYYY"));
      addToast({
        variant: "success",
        header: "Formularios 08",
        body: "Se guardó con exito la fecha de recepción de la toma",
      });
    } else {
      addToast({
        variant: "danger",
        header: "Formularios 08",
        body: "Ocurrió un error en el guardado de la fecha de recepción de la toma",
      });
    }
  };

  const handleChangeStatus = (status_name, value) => {
    onSimpleFieldChange(status_name, value);
    setSubmitAction(true);
  };

  const receptionComponent = (hasReceptionDate, plate_number) => {
    if (hasReceptionDate) {
      return (
        <div>
          <span>Recepción Toma: {hasReceptionDate}</span>
        </div>
      );
    }
    return profile !== constants.notaryProfile &&
      !hasReceptionDate &&
      plate_number ? (
      <div>
        <Button
          className="btn-fill btn btn-info btn-sm"
          onClick={(e) => {
            handleReceptionButton(e);
          }}
        >
          Informar Recepción Toma
        </Button>
      </div>
    ) : (
      <div>&nbsp;</div>
    );
  };

  const notaryOfficeComponent = () => {
    if (profile === constants.notaryProfile) {
      return null;
    }
    return current_status !== status08.WAITING.value ? (
      <BadgeField
        label={constants.notaryOffice}
        text={notary_office?.name}
        badgeClass={`capitalize plate_number`}
        variant={"secondary"}
      />
    ) : (
      <div className="col">
        <label className="capitalize">
          <strong>{constants.notaryOffice}</strong>
        </label>
        <div>
          <Select
            className="form-control"
            name="notary_office_id"
            onChange={(e) =>
              onSimpleFieldChange(
                "notary_office_id",
                parseInt(e.target.value, 10)
              )
            }
            value={notary_office?.id}
          >
            <option value="null">Seleccione</option>
            {notaryOffices.map((prop, key) => {
              return (
                <option key={key} value={prop.id}>
                  {prop.name}
                </option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };

  const inputNumber = ({ label, value, fieldName, variant = "dark" }) =>
    !statusRef.current ? (
      <BadgeField
        label={label}
        text={value || "-"}
        badgeClass={`capitalize plate_number`}
        variant={variant}
        containerClass="col"
      />
    ) : (
      <div className="col">
        <label className="capitalize">
          <strong>{label}</strong>
        </label>
        <div>
          <span>
            <Input
              type="number"
              className="form-control"
              name={fieldName}
              placeholder={label}
              autoComplete="false"
              value={value}
              onChange={(e) => onSimpleFieldChange(fieldName, e.target.value)}
            />
          </span>
        </div>
      </div>
    );

  return (
    <div className="modalChecklist">
      <Form>
        <div className="container">
          <div className="row">
            <BadgeField
              label={constants.number}
              text={formatAmount(form_number)}
              badgeClass={`capitalize plate_number`}
              variant={"success"}
              containerClass="col"
            />
            {status08BadgeField(current_status)}
            {notaryOfficeComponent()}
          </div>
          <div className="row">
            {!statusRef.current ||
            (disabledPlateNumber &&
              !disabledUpdate.includes(purchase_file_status)) ? (
              <BadgeField
                label={constants.domain}
                text={fields.plate_number}
                badgeClass={`capitalize plate_number`}
                variant={"warning"}
                containerClass="col"
              />
            ) : (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.domain}</strong>
                  <span>{plateNumberAvailable}</span>
                </label>
                <div>
                  <span>
                    <Input
                      type="text"
                      className="form-control"
                      name="plate_number"
                      placeholder={constants.domain}
                      autoComplete="false"
                      value={fields.plate_number}
                      onChange={(e) =>
                        onSimpleFieldChange("plate_number", e.target.value)
                      }
                      style={{ textTransform: "uppercase" }}
                    />
                  </span>
                </div>
              </div>
            )}

            {current_status !== status08.WAITING.value ? (
              <BadgeField
                label={constants.transactionType}
                text={form_type?.description}
                badgeClass={`capitalize plate_number`}
                variant={"secondary"}
                containerClass="col"
              />
            ) : (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.transactionType}</strong>
                </label>
                <div>
                  <Select
                    className="form-control"
                    name="form_type_id"
                    onChange={(e) =>
                      onSimpleFieldChange("form_type_id", e.target.value)
                    }
                    value={fields.form_type_id}
                  >
                    <option value="null">Seleccione</option>
                    {formType.map((f) => (
                      <option
                        key={f.text}
                        value={f.value}
                        className="capitalize"
                      >
                        {f.text}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
            )}
            {!statusRef.current ? (
              <BadgeField
                label={constants.enterpriseSignature}
                text={
                  enterprise_signature === null
                    ? "-"
                    : enterprise_signature
                    ? "Si"
                    : "No"
                }
                badgeClass={`capitalize plate_number`}
                variant={"dark"}
                containerClass="col"
              />
            ) : (
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.enterpriseSignature}</strong>
                </label>
                <div>
                  <Select
                    className="form-control"
                    name="enterprise_signature"
                    onChange={(e) =>
                      onSimpleFieldChange(
                        "enterprise_signature",
                        e.target.value === "null" ? null : e.target.value
                      )
                    }
                    value={fields.enterprise_signature}
                  >
                    <option value="null">Seleccione</option>
                    <option value="1">Si</option>
                    <option value="0">No</option>
                  </Select>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            {inputNumber({
              label: `${constants.signatureCount} Check`,
              value: fields.signature_count,
              fieldName: "signature_count",
            })}
            {inputNumber({
              label: `${constants.signatureCount} Cliente`,
              value: fields.signature_count_client,
              fieldName: "signature_count_client",
            })}
            {inputNumber({
              label: constants.swornStatement,
              value: fields.sworn_statement,
              fieldName: "sworn_statement",
            })}
            {inputNumber({
              label: constants.zeroTwo,
              value: fields.zero_two,
              fieldName: "zero_two",
            })}
          </div>
          <div className="row">
            <BadgeField
              label={"fecha última actualización"}
              text={updatedAt}
              badgeClass={`capitalize plate_number`}
              variant={"dark"}
              containerClass="col-4"
            />
            {issuerEntityUpdateRef.current ? (
              <div className="col-4">
                <label className="capitalize">
                  <strong>Registro donde se Presenta</strong>
                </label>
                <Multiselect
                  name="issuer_entity_id"
                  options={issuerEntitiesAll()}
                  selectedValues={selectedIssuer}
                  onSelect={onSelect}
                  onRemove={onRemove}
                  displayValue="text"
                  placeholder=""
                  closeIcon="cancel"
                  selectionLimit="1"
                  showCheckbox={false}
                  singleSelect={false}
                  selectedValueDecorator={(v) => v.substr(0, 40)}
                />
              </div>
            ) : (
              <BadgeField
                label={"Registro de Radicación"}
                text={issuer_entity_tentative || "Sin Datos"}
                badgeClass={`capitalize plate_number`}
                variant={"secondary"}
                containerClass="col-4"
              />
            )}
            <BadgeField
              label={"Ranking"}
              text={ranking || "Sin datos"}
              badgeClass={`capitalize plate_number`}
              variant={"info"}
              containerClass="col-4"
            />
          </div>
          <div className="row">
            <SelectField
              label={"DDJJ Cédula Azul"}
              name={"sworn_blue_status"}
              onChange={(e) =>
                onSimpleFieldChange(
                  "sworn_blue_status",
                  !!e.target.value ? e.target.value : null
                )
              }
              value={fields.sworn_blue_status}
              options={yesNoOptions}
            />
          </div>
          {![
            status08.WAITING.value,
            status08.DELIVERED.value,
            status08.OBSERVED_DELIVERED.value,
            status08.ARCHIVED.value,
            status08.CANCELED.value,
            status08.REMOVED.value,
          ].includes(current_status) ? (
            <>
              <div className="row">
                <SelectField
                  label={"Colegio de escribanos"}
                  name={"notary_college_id"}
                  onChange={(e) =>
                    onSimpleFieldChange(
                      "notary_college_id",
                      !!e.target.value ? e.target.value : null
                    )
                  }
                  value={fields.notary_college_id}
                  options={notaryCollege}
                />
                <DatePickerField
                  label={"Fecha de ingreso legalización"}
                  selected={
                    fields.legalization_date
                      ? new Date(fields.legalization_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("legalization_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <DatePickerField
                  label={"Fecha de retiro legalización"}
                  selected={
                    fields.withdrawal_legalization_date
                      ? new Date(fields.withdrawal_legalization_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("withdrawal_legalization_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <InputField
                  label={"Cantidad de documentos"}
                  name={"document_count"}
                  onChange={(e) =>
                    onSimpleFieldChange("document_count", e.target.value)
                  }
                  value={fields.document_count}
                  type="number"
                />
              </div>
              <div className="row">
                <DatePickerField
                  label={"Fecha de envio de certificación"}
                  selected={
                    fields.send_certification_date
                      ? new Date(fields.send_certification_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("send_certification_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
                <DatePickerField
                  label={"Fecha de recibo de certificación"}
                  selected={
                    fields.received_certification_date
                      ? new Date(fields.received_certification_date)
                      : null
                  }
                  onChange={(date) =>
                    onSimpleFieldChange("received_certification_date", date)
                  }
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </>
          ) : null}
          <hr />
          <Comments
            reasons={reasons}
            comments={comments}
            onChangeSelect={(e) =>
              onSimpleFieldChange(
                "cancel_reason_id",
                e.target.value === constants.dropdownDefault
                  ? null
                  : e.target.value
              )
            }
            onChangeObs={(e) =>
              onSimpleFieldChange("observation", e.target.value)
            }
            hideReasons={
              !plate_number ||
              current_status === status08.WAITING.value ||
              !statusRef.current
            }
            generic={true}
          />

          {profile !== constants.notaryProfile &&
          plate_number &&
          current_status !== status08.WAITING.value &&
          statusRef.current ? (
            <div className="row">
              <div className="col">
                <label className="capitalize">
                  <strong>{constants.files}</strong>
                </label>
                <div>
                  <span>
                    <input
                      type="file"
                      accept="image/*"
                      className="form-control"
                      name="images"
                      encType="multipart/form-data"
                      multiple={true}
                      onChange={handleFiles}
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : null}
        </div>
        <br />
        <div className="modal-footer">
          {statusButtonRef.current ? null : (
            <div>
              <Button
                className="btn-fill pull-right btn btn-primary btn-sm"
                onClick={() => {
                  setSubmitAction(true);
                }}
              >
                {constants.update}
              </Button>
            </div>
          )}
          {!hideReceptionByType.includes(form_type?.id)
            ? receptionComponent(hasReceptionDate, plate_number)
            : null}
          {statusButtonRef.current ? null : (
            <div className="row">
              <Button
                className="btn-fill pull-right btn btn-warning btn-sm"
                onClick={(e) => {
                  handlePreConfirmationPurchaseModal(
                    "current_status",
                    nextStatus.value,
                    nextStatus.text,
                    handleChangeStatus
                  );
                }}
                disabled={!allowSubmit}
              >
                {nextStatus && nextStatus.text}
              </Button>
            </div>
          )}
        </div>
        {confirmationModal.open && (
          <ConfirmationAlert
            show={confirmationModal.open}
            handleAction={confirmationModal.handleAction}
            handleClose={confirmationModal.handleClose}
            message={confirmationModal.message}
            title={confirmationModal.title}
          />
        )}
      </Form>
    </div>
  );
};

export default Content;
