import { useState, useEffect } from "react";

function useGetStatus(status_ns,constants) {
  const [status, setStatus] = useState({});

  useEffect(() => {
    const { color, text } = Object.keys(constants).includes(status_ns)
    ? constants[status_ns]
    : { color: "danger", text: "indefinido" };
    setStatus({ color: color, text: text });
    //eslint-disable-next-line
  }, []);
  return { status };
}

export default useGetStatus;
