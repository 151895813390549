import React from "react";
import { Badge } from "react-bootstrap";

const BadgeField = ({
  label,
  text,
  badgeClass,
  containerClass = null,
  variant = null,
  style = { width: "100%", padding: "10px"},
  textColor = null
}) => {
  return (
    <div className={containerClass ? containerClass : "col"}>
      <label className="capitalize">
        <strong>{label}</strong>
      </label>
      <div>
        <Badge
          bg={variant ? variant : ""}
          className={badgeClass}
          style={style}
          text={textColor}
        >
          {text}
        </Badge>
      </div>
    </div>
  );
};

export default BadgeField;
