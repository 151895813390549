import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters";
import Edit from "../../components/Modal/Sells/financed/edit";
import List from "../../components/Table/List.js";
import ExcelButton from "../../components/ExcelButton";
import ConfirmationAlert from "../../components/ConfirmationAlert/confirmationAlert";
import Cancel from "../../components/Modal/Sells/financed/cancel";
import {
  useConfirmationAlert,
  useFilters,
  useGrid,
  useStatusModal,
  useToast,
} from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import AuthService from "../../services/auth.service";
import SellsFinanced from "../../services/sells/sellsFinanced.service";
import {
  delivery_types,
  sells_status,
  sells_status_select,
  yesNoOptions,
} from "../../variables/constants";
import {
  FINANCED_STATUS_NAME,
  providerOptions,
  financed_status,
} from "../../variables/sells/index";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  sinceDaysBadge,
  twoBadges,
} from "../../utils/Badges";
import { formatAmount } from "../../utils/Numbers";
import { sellEmployees } from "../../utils/Service";
import { headFinanced } from "./constants/constants_columns";
import styles from "../Purchase/General/styles.module.scss";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 2,
  },
  financed_date: {
    type: "select",
    name: "financed_date",
    options: yesNoOptions,
    placeholder: "Recepción Prenda",
    value: "",
    size: 2,
  },
  take_licence_date: {
    type: "select",
    name: "take_licence_date",
    options: yesNoOptions,
    placeholder: "Cédula Recibida",
    value: "",
    size: 2,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 3,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: sellEmployees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 3,
  },
  status_sell: {
    type: "multiSelect",
    name: "status_sell",
    options: sells_status_select,
    placeholder: "Estado Venta",
    value: [],
    size: 6,
  },
  status_financed: {
    type: "multiSelect",
    name: "status_financed",
    options: Object.entries(financed_status).map((s, index) => ({
      value: s[1].value,
      text: s[1].text,
    })),
    placeholder: "Estado Prendado",
    value: [],
    size: 6,
  },
};

const FinancedList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [profile, setProfile] = useState(null);
  const addToast = useToast();
  const {
    showEditModal,
    handleCloseEditModal,
    handleOpenEditModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();
  const {
    body,
    countItems,
    currentPage,
    currentSort,
    refresh,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    totalItems,
    totalPage,
    refreshGrid,
    cleanPage,
  } = useGrid({
    field: "opportunity_id",
    type: "ASC",
    model: null,
  });

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);
  const { confirmationModal, setConfirmationModal } = useConfirmationAlert();
  const [dataModal, setDataModal] = useState([]);

  //PAGINATE
  const next = {
    link: "/admin/general/list",
    text: "General",
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };

    SellsFinanced.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      let arraydata = sortColumns(response.data.response);
      handleBody(arraydata);
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters]);

  const openEditModal = (id) => {
    SellsFinanced.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data.response);
        handleOpenEditModal();
      }
    });
  };

  const createFinanced = async (opportunity_id) => {
    const response = await SellsFinanced.create({ opportunity_id });
    if (response.data.success) {
      addToast({
        variant: "success",
        header: "Prendado",
        body: "Se inició el proceso de prendado correctamente",
      });
      setConfirmationModal({
        open: false,
        title: "",
        message: "",
        handleAction: null,
        handleClose: null,
      });
      refreshGrid();
    } else {
      addToast({
        variant: "danger",
        header: "Prendado",
        body: "Ha ocurrido un error, intente nuevamente",
      });
    }
  };

  const handlerCreateFinanced = (opportunity_id) => {
    setConfirmationModal({
      open: true,
      title: "¿Desea iniciar el flujo de prendado?",
      message: "Al confirmar, se iniciara el flujo de prendado",
      handleAction: () => createFinanced(opportunity_id),
      handleClose: () =>
        setConfirmationModal({
          open: false,
          title: "",
          message: "",
          handleAction: null,
          handleClose: null,
        }),
    });
  };

  const sortColumns = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      stock_id: e.stock_id,
      status: e.status_financed?.description
        ? genericBadge(financed_status, e.status_financed?.description)
        : genericBadge(sells_status, e.status_sell?.description),
      financing_provider: e.financing_provider,
      financing_amount: formatAmount(e.financing_amount),
      signing_form_08_date: e.signing_form_08_date,
      financed_date: e.financed_date,
      presentation_real_date: e.presentation_real_date,
      take_licence_date: e.take_licence_date,
      status_updatedAt: e.status_updatedAt,
      financed_updatedAt: e.financed_updatedAt,
      estimated_date: e.estimated_date,
      person_in_charge: e.person_in_charge,
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
      bacs_id: badgeGenericBoolean(e.bacs_id),
      bacs_desafectado: badgeGenericBoolean(e.bacs_desafectado),
      _status_sell: e.status_sell?.id,
      _status_financed: e.status_financed?.id,
    }));

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("PRENDADOS VENTAS");
    setCurrentPermissions(permissions);
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
  }, []);

  const actions = (props) => {
    return (
      <>
        {currentPermissions &&
        currentPermissions.permissions.hasCreate &&
        // !!props.take_licence_date &&
        !props._status_financed ? (
          <Button
            onClick={() => handlerCreateFinanced(props.opportunity_id)}
            className="btn-simple btn btn-xs btn-warning"
          >
            <i className="fas fa-money-bill-alt" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasSelect &&
        props._status_financed ? (
          <Button
            onClick={() => openEditModal(props.opportunity_id)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions &&
        currentPermissions.permissions.hasDelete &&
        props._status_financed ? (
          <Button
            onClick={() => handleShowRejectModal(props.opportunity_id)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasPrendados" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={headFinanced}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
              currentPermissions={currentPermissions}
              profile={profile}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <Cancel
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default FinancedList;
