import React, { useEffect, useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";

import PrintService from "../../../services/print/print.service";
import AuthService from "../../../services/auth.service";
import { useConfirmationAlert, useToast } from "../../../hooks";
import FormOptions from "../../../components/Modal/PrintForms/formOptions";
import Card from "../../../components/Card/Card.jsx";
import ConfirmationAlert from "../../../components/ConfirmationAlert/confirmationAlert";

const initialFormState = {
  form: null,
  reception_place_id: null,
};

const ConfigKeys = {
  orientation: "Orientación",
  unit: "Unidad",
  format_x: "Ancho (según Unidad)",
  format_y: "Alto (según Unidad)",
  font_size: "Tamaño de la Fuente",
  font_name: "Nombre de la Fuente",
};
const excludeConfigKeys = [
  "id",
  "print_form_id",
  "form",
  "reception_place_id",
  "reception_place",
];

const configRow = (key, value, onChange) => {
  return (
    <tr key={`row-${key}`}>
      <td>{ConfigKeys[key]}</td>
      <td>
        <input
          type="text"
          className="form-control"
          defaultValue={value}
          onChange={(e) => onChange(key, parseFloat(e.target.value))}
        />
      </td>
    </tr>
  );
};

const coordinateRow = (data, onChange) => {
  const { id, field, field_description, pos_x, pos_y, active } = data;
  return (
    <tr key={`row-${id}`}>
      <td className="text-capitalize">
        <strong>
          {field.endsWith("_label") ? "(Texto Fijo) " : "(Dinámico) "}
        </strong>
        {field_description}
      </td>
      <td>
        <input
          type="number"
          step={0.1}
          min={0}
          className="form-control"
          defaultValue={pos_x}
          onChange={(e) => onChange(id, "pos_x", parseFloat(e.target.value))}
        />
      </td>
      <td>
        <input
          type="number"
          step={0.1}
          min={0}
          className="form-control"
          defaultValue={pos_y}
          onChange={(e) => onChange(id, "pos_y", parseFloat(e.target.value))}
        />
      </td>
      <td>
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          defaultChecked={active}
          onChange={(e) => onChange(id, "active", e.target.checked)}
        />
      </td>
    </tr>
  );
};

const PrintConfig = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [fields, setFields] = useState(initialFormState);
  const [coordinates, setCoordinates] = useState([]);
  const [config, setConfig] = useState(null);
  const [loading, setLoading] = useState(false);
  const addToast = useToast();
  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue,
    });
  };

  const onUpdateConfig = (key, value) => {
    setConfig({
      ...config,
      [key]: value,
    });
  };

  const onSaveConfig = () => {
    PrintService.saveConfig(fields.form, fields.reception_place_id, config)
      .then((response) => {
        addToast({
          variant: "success",
          header: "Configuración de Impresiones",
          body: "Se actualizaron los parámetros correctamente",
        });
        handleCloseConfirmationModal();
      })
      .catch((error) => {
        addToast({
          variant: "danger",
          header: "Configuración de Impresiones",
          body: "Ah ocurrido un error, no se actualizaron los parámetros. Intente nuevamente.",
        });
        handleCloseConfirmationModal();
      });
  };

  const onUpdateCoordinates = (id, key, value) => {
    setCoordinates(
      coordinates.map((coord) =>
        coord.id === id ? { ...coord, [key]: value } : coord
      )
    );
  };

  const onSaveCoordinates = () => {
    PrintService.saveCoordinates(fields.form, fields.reception_place_id, {
      coordinates,
    })
      .then((response) => {
        addToast({
          variant: "success",
          header: "Configuración de Impresiones",
          body: "Se actualizaron las coordenadas correctamente",
        });
        handleCloseConfirmationModal();
      })
      .catch((error) => {
        addToast({
          variant: "danger",
          header: "Configuración de Impresiones",
          body: "Ah ocurrido un error, no se actualizaron las coordenadas. Intente nuevamente.",
        });
        handleCloseConfirmationModal();
      });
  };

  useEffect(() => {
    if (fields.form && fields.reception_place_id) {
      setLoading(true);
      console.log(fields.form, fields.reception_place_id);
      PrintService.getConfig(fields.form, fields.reception_place_id)
        .then((response) => {
          const { config: configData, coordinates: coordsData } =
            response?.data?.response || {};
          setConfig(configData);
          setCoordinates(coordsData);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setConfig(null);
          setCoordinates([]);
          setLoading(false);
        });
    } else {
      setConfig(null);
      setCoordinates([]);
    }
  }, [fields.form, fields.reception_place_id]);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions(
      "CONFIGURACION IMPRESIONES"
    );
    setCurrentPermissions(permissions);
  }, []);

  return (
    <div className="content">
      <FormOptions onSimpleFieldChange={onSimpleFieldChange} />
      <br />
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Configuración de Parámetros"
        content={
          loading ? (
            "Loading..."
          ) : !config ? (
            "Sin datos, debe seleccionar otros parámetros de busqueda."
          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <td>Parámetro</td>
                  <td>Valor</td>
                </tr>
              </thead>
              <tbody>
                {Object.keys(config)
                  ?.filter((k) => !excludeConfigKeys.includes(k))
                  .map((key) => configRow(key, config[key], onUpdateConfig))}
              </tbody>
              {currentPermissions?.permissions?.hasUpdate ? (
                <tfoot>
                  <tr>
                    <td colSpan={2}>
                      <Button
                        className="btn-fill btn btn-success capitalize"
                        onClick={() =>
                          handleConfirmationModal(
                            "",
                            () => onSaveConfig(),
                            "¿Desea modificar los parámetros?",
                            "Al confirmar, se actualizarán los parámetros para este formulario y lugar de toma."
                          )
                        }
                      >
                        Guardar Parámetros
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </Table>
          )
        }
      />
      <br />
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Configuración de Coordenadas"
        content={
          loading ? (
            "Loading..."
          ) : !coordinates.length ? (
            "Sin datos, debe seleccionar otros parámetros de busqueda."
          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <td>Etiqueta</td>
                  <td>Posición X</td>
                  <td>Posición Y</td>
                  <td>Visible</td>
                </tr>
              </thead>
              <tbody>
                {coordinates?.map((c) => coordinateRow(c, onUpdateCoordinates))}
              </tbody>
              {currentPermissions?.permissions?.hasUpdate ? (
                <tfoot>
                  <tr>
                    <td colSpan={4}>
                      <Button
                        className="btn-fill btn btn-success capitalize"
                        onClick={() =>
                          handleConfirmationModal(
                            "",
                            () => onSaveCoordinates(),
                            "¿Desea modificar las coordenadas?",
                            "Al confirmar, se actualizarán las coordenadas para este formulario y lugar de toma."
                          )
                        }
                      >
                        Guardar Coordenadas
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </Table>
          )
        }
      />
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default PrintConfig;
