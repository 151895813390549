import React, { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PermissionsService from "../../../services/permission.service";
import AuthService from "../../../services/auth.service";
import List from "../../../components/Table/List.js";
import ButtonIcon from "../../../components/ButtonIcon";
import { badgeGenericBoolean } from "../../../utils/Badges.js";
import { useToast } from "../../../hooks";

const headSort = [
  { text: "Id", field: "id" },
  { text: "Perfil", field: "profile_id" },
  { text: "Función", field: "function_id" },
  { text: "Visualizar", field: null },
  { text: "Crear", field: null },
  { text: "Actualizar", field: null },
  { text: "Eliminar", field: null },
  { text: "", field: null },
];

const PermissionsList = (props) => {
  const [body, setBody] = useState([]);
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const addToast = useToast();
  const navigate = useNavigate();

  const countItems = 15;
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentSort, setCurrentSort] = useState({ field: "id", type: "ASC" });
  const [refresh, setRefresh] = useState(Date.now());

  const handleToPage = (page, e) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  const handleSort = (model, field, type) => {
    setCurrentSort({ model, field, type });
    setCurrentPage(0);
  };

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("PERMISOS");
    setCurrentPermissions(permissions);
  }, []);

  useEffect(() => {
    const params = {
      currentPage,
      countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
    };
    PermissionsService.getAll(params).then((response) => {
      if (response.data.count > 0) {
        setBody(sortColumns(response.data.response));
      }
      setTotalItems(response.data.totalItems);
      const result = Math.ceil(response.data.totalItems / countItems);
      setTotalPage(result);
    });
  }, [currentPage, currentSort, refresh]);

  const sortColumns = (body) =>
    body.map((e) => ({
      id: e.id,
      profile: e.profile,
      function: e.function,
      select: badgeGenericBoolean(e.select),
      create: badgeGenericBoolean(e.create),
      update: badgeGenericBoolean(e.update),
      delete: badgeGenericBoolean(e.delete),
    }));

  const handleDelete = (id, e) => {
    e.preventDefault();
    const del = window.confirm("¿Está seguro que desea eliminar el permiso?");
    if (del) {
      PermissionsService.del(id).then(() => {
        addToast({
          variant: "success",
          header: "Permisos",
          body: "Se eliminó el permiso correctamente",
        });
        setRefresh(Date.now());
      });
    }
  };

  const actions = (props) => {
    return (
      <>
        {props.function === "PERMISOS" &&
        currentPermissions &&
        currentPermissions.permissions.hasUpdate ? null : (
          <ButtonIcon
            onClick={() => navigate(`/admin/permissions/${props.id}/edit`)}
            name="file-alt"
            title="Editar"
          />
        )}
        {props.function === "PERMISOS" &&
        currentPermissions &&
        currentPermissions.permissions.hasDelete ? null : (
          <ButtonIcon
            onClick={(e) => handleDelete(props.id, e)}
            name="times"
            color="danger"
            size="lg"
            title="Eliminar"
          />
        )}
      </>
    );
  };

  return (
    <div className="content">
      <div className="content-filters ps-3 mb-4">
        <Button
          href="/admin/permissions/create"
          className="btn-fill btn btn-primary capitalize"
        >
          Nuevo Permiso
        </Button>
      </div>
      <Container fluid>
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default PermissionsList;
