import React, { useState } from "react";
import { TradeIds, UploadXlsxFile } from "./index";
import CustomAccordion from "../../../components/Accordion";

const options = [
  {
    key: 1,
    title: "Intercambiar IDs",
    hide: false,
    processName: "",
    Component: (props) => <TradeIds {...props} />,
  },
  {
    key: 2,
    title: "Cargar excel de tomas",
    hide: true,
    processName: "1_create_purchase_file",
    Component: (props) => <UploadXlsxFile {...props} />,
    titleField: "Tomas",
  },
  {
    key: 3,
    title: "Cargar excel de cedulas",
    hide: true,
    processName: "4_excel_update_delivery",
    Component: (props) => <UploadXlsxFile {...props} />,
    titleField: "Lugares de entrega de cedula",
  },
  {
    key: 4,
    title: "Cargar excel de oportunidades de ventas",
    hide: true,
    processName: "8_excel_update_sells",
    Component: (props) => <UploadXlsxFile {...props} />,
    titleField: "Oportunidades de ventas",
  },
  {
    key: 5,
    title: "Cargar retiro de documentación",
    hide: true,
    processName: "9_excel_sells_documentation",
    Component: (props) => <UploadXlsxFile {...props} />,
    titleField: "Retiro de documentacion de ventas",
  },
];

const Settings = (props) => {
  const [settingsOptions, setSettingsOptions] = useState(options);

  const hanlderHide = (index) => {
    if (options[index].hide) {
      options[index].hide = false;
    } else {
      options[index].hide = true;
    }
    setSettingsOptions([...options]);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "10px",
        margin: "10px",
        backgroundColor: "white",
      }}
    >
      <div>
        {settingsOptions.map((setting, index) => (
          <div key={index}>
            <CustomAccordion title={setting.title} index={index}>
              <setting.Component
                {...props}
                processName={setting.processName}
                title={setting.titleField}
              />
              {/* {index !== options.length - 1 ? <hr /> : null} */}
            </CustomAccordion>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
