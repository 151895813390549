import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import AdditionalService from "../../services/additional/additional.service";
import CustomAccordion from "../../components/Accordion/index";
import { badgeGenericDiv } from "../../utils/Badges";
import { formatAmount } from "../../utils/Numbers";
import { additional_status } from "../../variables/constants";

const AdditionalBlock = ({ opportunity_id = null, vehicle_id = null }) => {
  const [additionals, setAdditionals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!opportunity_id && !vehicle_id) return;

      setLoading(true);
      try {
        const response = opportunity_id
          ? await AdditionalService.findAllSell(opportunity_id)
          : await AdditionalService.findAllPurchase(vehicle_id);

        if (response.status === 200) {
          setAdditionals(response.data.response);
        }
      } catch (error) {
        console.error("Failed to fetch additionals:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [opportunity_id, vehicle_id]);

  return (
    <div>
      <CustomAccordion title={`ADICIONALES - ${additionals.length}`}>
        {loading ? (
          <h4>Loading...</h4>
        ) : !additionals.length ? (
          <h4>No se encontraron adicionales relacionados.</h4>
        ) : (
          <Table striped hover>
            <thead>
              <tr>
                <td>Estado</td>
                <td>Concepto</td>
                <td>Cantidad</td>
                <td>Total</td>
                <td>Fecha Solicitud</td>
              </tr>
            </thead>
            <tbody>
              {additionals.map((a) => (
                <tr key={a.id}>
                  <td>
                    {badgeGenericDiv(
                      additional_status[a.status_id].color,
                      additional_status[a.status_id].text
                    )}
                  </td>
                  <td>{a.concept}</td>
                  <td>{a.quantity}</td>
                  <td>
                    {formatAmount(
                      parseInt(a.quantity || 0) *
                        (parseFloat(a.amount || 0.0) +
                          parseFloat(a.amount_iva || 0.0))
                    )}
                  </td>
                  <td>{a.request_date}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </CustomAccordion>
    </div>
  );
};

export default AdditionalBlock;
