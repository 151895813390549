import React, { useEffect, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import Filters from "../../components/Filters";
import { Edit } from "../../components/Modal/Sells/folio/index";
import RejectSell from "../../components/Modal/Sells/rejectSell";
import List from "../../components/Table/List.js";
import ExcelButton from "../../components/ExcelButton";
import { useFilters, useGrid, useStatusModal } from "../../hooks/index";
import Modal from "../../layouts/ModalDisplay";
import AuthService from "../../services/auth.service";
import SellsFolioService from "../../services/sells/sellsFolio.service";
import {
  delivery_types,
  sells_status,
  sells_status_select,
  yesNoOptions,
} from "../../variables/constants";
import { FINANCED_STATUS_NAME, providerOptions } from "../../variables/sells/index";
import {
  badgeGenericBoolean,
  diffDateBadge,
  genericBadge,
  sinceDaysBadge,
  twoBadges,
} from "../../utils/Badges";
import { sellEmployees } from "../../utils/Service";
import { headFolio } from "./constants/constants_columns";
import styles from "../Purchase/General/styles.module.scss";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 3,
  },
  financed: {
    type: "select",
    name: "financed",
    options: yesNoOptions,
    placeholder: "Financiado",
    value: "",
    size: 3,
  },
  delivery_type: {
    type: "select",
    name: "delivery_type",
    options: delivery_types,
    placeholder: "Tipo de entrega",
    value: "",
    size: 3,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: sellEmployees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 3,
  },
  provider: {
    type: "select",
    name: "provider",
    options: providerOptions,
    placeholder: "Proveedor",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: sells_status_select.filter((x) => x.group === "FOLIO"), //Crear grupo para folio
    placeholder: "Estado",
    value: [],
    size: 9,
  },
};

const FolioInProgressList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const {
    showEditModal,
    handleCloseEditModal,
    handleOpenEditModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();
  const {
    body,
    countItems,
    currentPage,
    currentSort,
    refresh,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    totalItems,
    totalPage,
    refreshGrid,
    cleanPage,
  } = useGrid({
    field: "opportunity_id",
    type: "ASC",
    model: null,
  });

  const { filters, onFilterChange } = useFilters(filterFields, cleanPage);

  const [dataModal, setDataModal] = useState([]);

  //PAGINATE
  const next = {
    link: "/admin/presentation/list",
    text: "Presentación",
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
      filters: filters,
    };

    SellsFolioService.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      let arraydata = sortColumns(response.data.response);
      handleBody(arraydata);
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, filters]);

  const openEditModal = (id) => {
    SellsFolioService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  const sortColumns = (body) =>
    body.map((e) => ({
      opportunity_id: e.opportunity_id,
      plate_number: twoBadges(
        e.plate_number,
        e.financed ? FINANCED_STATUS_NAME.FINANCED : null
      ),
      stock_id: e.stock_id,
      status: genericBadge(sells_status, e.status),
      financing_provider: e.financing_provider,
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
      week_delivery_sell: e.week_delivery_sell,
      delivery_type: e.delivery_type,
      transaction_type: e.transaction_type,
      folio_start_date: e.folio_start_date,
      person_in_charge_id: e.person_in_charge_id,
      suspended_date: e.suspended_date,
      observed_date: e.observed_date,
      bacs_id: badgeGenericBoolean(e.bacs_id),
      bacs_desafectado: badgeGenericBoolean(e.bacs_desafectado),
    }));

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions(
      "APERTURA VENTAS FOLIO"
    );
    setCurrentPermissions(permissions);
  }, []);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect ? (
          <Button
            onClick={() => openEditModal(props.opportunity_id)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        ) : null}
        {currentPermissions && currentPermissions.permissions.hasDelete ? (
          <Button
            onClick={() => handleShowRejectModal(props.opportunity_id)}
            className="btn-simple btn btn-xs btn-danger"
          >
            <i className="fas fa-times"></i>
          </Button>
        ) : null}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasAperturaFolio" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={headFolio}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
          headerTitle={
            <Badge bg="warning">
              Metodo de pago{" "}
              {dataModal.response.financed
                ? FINANCED_STATUS_NAME.FINANCED
                : FINANCED_STATUS_NAME.CASH}
            </Badge>
          }
        />
      )}
      {showRejectModal.show && (
        <Modal
          content={
            <RejectSell
              handleClose={handleCloseRejectModal}
              refresh={refreshGrid}
              id={showRejectModal.id}
            />
          }
          showModal={showRejectModal.show}
          handleShow={showRejectModal}
          handleClose={handleCloseRejectModal}
          headerTitle={<div>Motivos del rechazo</div>}
        />
      )}
    </div>
  );
};

export default FolioInProgressList;
