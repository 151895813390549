import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import Chart from "react-apexcharts";
import moment from "moment";
import Information from "./information";
import ListStatus from "./listStatus";
import Deadline from "./deadline";
import Dropdown from "../../components/Table/Dropdown";
import GeneralService from "../../services/general.service";
import {
  constants,
  login,
  meses,
  purchaseStatus,
} from "../../variables/constants";
import { pieOptions } from "variables/Variables.jsx";
import { filterFields } from "../Purchase/General/constants/constants_filter";
import { useFilters } from "../../hooks";

const years = [];
for (let index = moment().year(); index >= 2021; index--) {
  years.push({ value: index, text: index });
}

const months = [];
for (let index = 1; index <= 12; index++) {
  months.push({ value: index, text: meses[index] });
}

const weeks = [];
for (let index = 1; index <= 53; index++) {
  weeks.push({ value: index, text: index });
}

const TRANSACTION_TYPE_CONSIGNACION = 5;

// Guardado de los filtros
const DASHBOARD_LOCAL_STORAGE = "dashboard_filters";

const groups = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3,
  3, 3, 3,
];

const donutOptions = {
  labels: ["Abiertos", "Cerradas"],
  ...pieOptions,
};

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState(false);
  const [pieData, setPieData] = useState(false);
  const [series, setSeries] = useState([]);
  const [openCases, setOpenCases] = useState(false);
  const [closedCases, setClosedCases] = useState(false);
  const [deadlines, setDeadlines] = useState(false);
  const [deadlineTotal, setDeadlineTotal] = useState(0);
  const [loadingDeadline, setLoadingDeadline] = useState(false);

  const { filters, onFilterChange, onFilterChangeArray } = useFilters(
    filterFields,
    () => {}
  );

  const handleSearchYear = (e) => {
    onFilterChangeArray({
      year: { value: e.target.value },
      status: { value: [] },
    });
  };

  const handleSearchMonth = (e) => {
    onFilterChangeArray({
      month: { value: e.target.value },
      status: { value: [] },
      week: { value: null },
    });
  };

  const handleSearchWeek = (e) => {
    onFilterChangeArray({
      week: { value: e.target.value },
      status: { value: [] },
      month: { value: null },
    });
  };

  const onChangeStatus = (e) => {
    let newStatusCheck = [...filters.status.value];
    if (e.target.checked) {
      newStatusCheck.push({ value: e.target.name });
    } else {
      newStatusCheck = filters.status.value.filter(
        (f) => f.value !== e.target.name
      );
    }

    onFilterChange("status", newStatusCheck);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    if (!user) {
      window.location.assign(login);
      return;
    }
  }, []);

  useEffect(() => {
    if (filters.year.value) {
      localStorage.setItem(DASHBOARD_LOCAL_STORAGE, JSON.stringify(filters));
      setLoading(true);
      GeneralService.getStatsGlobal({ filters }).then((response) => {
        if (response.data.count > 0) {
          // Lunes de la semana
          const monday = response.data.response[0].monday;
          // Cerrados
          const closedResult = response.data.response.filter(
            (s) =>
              s.transaction_type_id !== TRANSACTION_TYPE_CONSIGNACION &&
              s.purchase_file_status === purchaseStatus.COMPLETED.value
          );
          let closedCount = 0;
          for (let index = 0; index < closedResult.length; index++) {
            closedCount += closedResult[index].count;
          }
          // Abiertos
          const openResult = response.data.response.filter(
            (s) =>
              s.transaction_type_id !== TRANSACTION_TYPE_CONSIGNACION &&
              s.purchase_file_status !== purchaseStatus.COMPLETED.value
          );
          let openCount = 0;
          for (let index = 0; index < openResult.length; index++) {
            openCount += openResult[index].count;
          }
          // Los que son de consignación
          const othersResult = response.data.response.filter(
            (s) => s.transaction_type_id === TRANSACTION_TYPE_CONSIGNACION
          );
          let othersCount = 0;
          for (let index = 0; index < othersResult.length; index++) {
            othersCount += othersResult[index].count;
          }
          setInfo({
            closed: closedCount,
            open: openCount,
            others: othersCount,
            year: filters.year.value,
            month: filters.month.value,
            week: filters.week.value,
            monday,
          });
          setClosedCases(closedResult);
          setOpenCases(openResult);

          // grafico de tortas
          setSeries([openCount, closedCount]);
          setPieData(true);
        } else {
          setPieData(false);
          setInfo(false);
          setClosedCases(false);
          setOpenCases(false);
        }
        setLoading(false);
      });
    }
    //eslint-disable-next-line
  }, [filters.year, filters.week, filters.month]);

  useEffect(() => {
    setLoadingDeadline(true);
    if (filters.status.value.length > 0) {
      const params = {
        page: 0,
        limit: 10000,
        filters,
      };
      GeneralService.getAllGeneral(params).then((response) => {
        const deadlineGroups = [
          { name: "<=10", items: [] },
          { name: "11-14", items: [] },
          { name: "15-21", items: [] },
          { name: "22-28", items: [] },
          { name: ">28", items: [] },
        ];
        const deadlineData = response.data.response.filter(
          (s) => s.transaction_type_id !== TRANSACTION_TYPE_CONSIGNACION
        );
        for (let index = 0; index < deadlineData.length; index++) {
          const element = deadlineData[index];
          const group = groups[element.diff_days] ?? 4;
          deadlineGroups[group].items.push(element);
        }
        setDeadlineTotal(deadlineData.length);
        setDeadlines(deadlineGroups);
        setLoadingDeadline(false);
      });
    } else {
      setDeadlines(false);
      setLoadingDeadline(false);
    }
    //eslint-disable-next-line
  }, [filters.status]);

  return (
    <div className="content content-dashboard">
      <Container fluid>
        <div className="form-group content-search-input mb-3">
          <div>
            <Dropdown
              className="form-control"
              name="year"
              placeholder="Año"
              onSelect={handleSearchYear}
              selected={filters.year.value ?? constants.dropdownDefault}
              options={years}
            />
          </div>
          <div>
            <Dropdown
              className="form-control"
              name="month"
              placeholder="Mes"
              onSelect={handleSearchMonth}
              selected={filters.month.value ?? constants.dropdownDefault}
              options={months}
            />
          </div>
          <div>
            <Dropdown
              className="form-control"
              name="week"
              placeholder="Semana"
              onSelect={handleSearchWeek}
              selected={filters.week.value ?? constants.dropdownDefault}
              options={weeks}
            />
          </div>
          <div>
            <Button
              href="/admin/dashboard-agent"
              className="btn-fill btn btn-info btn-sm capitalize"
            >
              Ver Dashboard Gestores
            </Button>
          </div>
        </div>

        <div className="content-dashboard-information">
          <Information data={info} loading={loading} />
          {pieData ? (
            <Chart
              options={donutOptions}
              series={series}
              type="donut"
              width={500}
              height={320}
            />
          ) : null}
        </div>
        <div className="content-dashboard-information">
          <ListStatus
            title="Abiertas"
            data={openCases}
            onChangeStatus={onChangeStatus}
            loading={loading}
          />
          <ListStatus
            title="Cerradas"
            data={closedCases}
            onChangeStatus={onChangeStatus}
            loading={loading}
          />
        </div>
        <div className="content-dashboard-information">
          <Deadline
            data={deadlines}
            parcial={deadlineTotal}
            total={info ? info.closed + info.open + info.others : 0}
            loading={loadingDeadline}
          />
        </div>
      </Container>
    </div>
  );
};

export default Dashboard;
