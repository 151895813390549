import React, { useEffect, useRef, useState } from "react";
import { Button, Container } from "react-bootstrap";
import ManagementService from "../../../services/management.service";
import List from "../../../components/Table/List";
import Filters from "../../../components/Filters";
import ExcelButton from "../../../components/ExcelButton";
import { useFilters, useGrid } from "../../../hooks/";
import { SDPBadge, statusBadge } from "../../../utils/Badges";
import { constants, serviceTypes } from "../../../variables/constants";
import styles from "../General/styles.module.scss";
import { headSort } from "./constants/constants_columns";
import { filtersRejected } from "./constants/constants_filter";

const RejectedList = (props) => {
  const {
    body,
    cleanPage,
    countItems,
    currentPage,
    currentSort,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    refresh,
    refreshGrid,
    totalItems,
    totalPage,
  } = useGrid({ model: null, field: "vehicle_id", type: "ASC" });

  const { filters, onFilterChange } = useFilters(filtersRejected, cleanPage);
  const [emptyFilters, setEmptyFilters] = useState(true);

  useEffect(() => {
    const rejectedReasonColumn = (data) => (
      <>
        <span>{data.rejected_reason}</span>
        {data.comment && data.comment.length > 0 ? (
          <span>: {data.comment}</span>
        ) : null}
      </>
    );

    const sortColumns = (body) =>
      body.map((e) => ({
        vehicle_id: e.vehicle_id,
        plate_number: SDPBadge(e.plate_number, e.sdp),
        status: statusBadge(e.status),
        reception_date: e.reception_date,
        issuer_entity: e.issuer_entity,
        transaction_type: e.transaction_type,
        agent: e.agent,
        updatedUser: e.updatedUser,
        zone: e.zone,
        rejected_reason: rejectedReasonColumn(e),
      }));

    const params = {
      page: currentPage,
      limit: countItems,
      filters,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
    };

    const emptyFilters = Object.keys(params.filters).every(
      (key) =>
        params.filters[key].value === null ||
        params.filters[key].value === "" ||
        params.filters[key].value === undefined ||
        params.filters[key].value.length === 0
    );
    setEmptyFilters(emptyFilters);
    if (emptyFilters) {
      handleTotalItems(0);
      handleTotalPage(0);
      handleBody(sortColumns([]));
      return;
    }

    ManagementService.getAllRejected(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, filters, refresh]);

  // const actions = () => {
  //   return <></>;
  // };

  return (
    <div className="content">
      <Container fluid>
        <div className={styles.export}>
          <ExcelButton type="comprasRechazados" filters={filters} />
        </div>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          // actions={actions}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
      </Container>
    </div>
  );
};

export default RejectedList;
