import { transactionTypes } from "../../../../utils/Service";
import { purchaseStatus } from "../../../../variables/constants";

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "folio")
  .map((s) => {
    return { text: s[1].text, value: s[1].value };
  });

export const filtersFolioInProgress = {
  plateNumber: {
    type: "text",
    name: "plateNumber",
    placeholder: "Dominio",
    value: null,
    size: 3,
  },
  transactionType: {
    type: "select",
    name: "transactionType",
    options: transactionTypes(),
    placeholder: "Tipo de Operación",
    value: "",
    size: 3,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: dropdownStatus,
    placeholder: "Estado",
    value: [],
    size: 6,
  },
};
