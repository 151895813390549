import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
// import AuthService from "../../services/auth.service";
import HolidayService from "../../../services/holiday.service";
import { useToast } from "../../../hooks";
// import { constants } from "../../variables/constants";

const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const Holidays = (props) => {
  // const [currentPermissions, setCurrentPermissions] = useState(null);
  const [startDate, setStartDate] = useState(new Date().setMonth(0));
  const [activeHolidays, setActiveHolidays] = useState([]);
  const [refresh, setRefresh] = useState(Date.now());
  const [totalItems, setTotalItems] = useState(0);
  const addToast = useToast();

  useEffect(() => {
    const year = new Date(startDate).getFullYear();
    HolidayService.getAll(year).then((response) => {
      setTotalItems(response.data.count);
      setActiveHolidays(
        response.data.response.map((h) => moment(h.holiday_date).toDate())
      );
    });
  }, [startDate, refresh]);

  // useEffect(() => {
  //   const permissions = AuthService.getCurrentPermissions("FERIADOS");
  //   setCurrentPermissions(permissions);
  // }, []);

  const handleUpdate = (date) => {
    const idx = activeHolidays.map(Number).indexOf(+date.setHours(0, 0, 0, 0));
    if (idx !== -1) {
      HolidayService.del(moment(date).format("YYYY-MM-DD")).then((response) => {
        if (response.success) {
          addToast({
            variant: "warning",
            header: "Feriados",
            body: "Se eliminó el feriado correctamente",
          });
          setRefresh(Date.now());
        }
      });
    } else {
      HolidayService.add(date).then((response) => {
        if (response.success) {
          addToast({
            variant: "success",
            header: "Feriado",
            body: "Se agregó el nuevo feriado correctamente",
          });
          setRefresh(Date.now());
        }
      });
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <div className="content-filters row">
          <div className="col-sm-3">
            <span className="bold">Total de Feriados: {totalItems}</span>
          </div>
          <div className="col-sm-2">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showYearPicker
              dateFormat="yyyy"
              yearItemNumber={6}
              className="form-control"
            />
          </div>
        </div>
        <br />
        <div>
          <DatePicker
            openToDate={startDate}
            selected={new Date()}
            monthsShown={12}
            filterDate={isWeekday}
            onChange={(date) => handleUpdate(date)}
            highlightDates={activeHolidays}
            inline
            // locale="es"
          />
        </div>
      </Container>
    </div>
  );
};

export default Holidays;
