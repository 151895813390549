// Acceso
import Login from "./views/Auth/Login";
import Logout from "./views/Auth/Logout";
import Register from "./views/Auth/Register";
// Globales
import AdditionalsList from "./views/Additionals/index";
import BACS from "./views/bacs/index";
import RenditionList from "./views/renditions/index";
import VTV from "./views/vtv/index";
import StatusForm08List from "./views/StatusForm08/StatusForm08List";
// Funcionalidades
import MapIssuerEntity from "./views/Management/MapIssuerEntity";
import PlateValidate from "./views/Plate/PlateValidate";
import PrintForms from "./views/PrintForms";
// Configuración
import {
  FormConfig,
  FunctionsCreate,
  FunctionsEdit,
  FunctionsList,
  GroupsCreate,
  GroupsEdit,
  GroupsList,
  GroupsUsersEdit,
  Holidays,
  IssuerEntity,
  IssuerEntityEdit,
  NotaryOfficesCreate,
  NotaryOfficesEdit,
  NotaryOfficesList,
  PermissionsCreate,
  PermissionsEdit,
  PermissionsList,
  PrintConfig,
  ProfilesCreate,
  ProfilesEdit,
  ProfilesList,
  SettingsConfig,
  UsersCreate,
  UsersEdit,
  UsersList,
  UsersPassEdit,
} from "./views/Config/index";
// Tableros
import {
  Dashboard,
  DashboardAgent,
  DashboardNotary,
} from "./views/Dashboard/index";
// Compras
import {
  FolioInProgressList,
  GeneralList,
  Inquiry,
  PresentationList,
  PurchaseSuspendedList,
  RejectedList,
  TakeCreate,
  TakeList,
} from "./views/Purchase/index";
// Ventas
import {
  AperturaFolioList,
  FinancedList,
  GeneralSellList,
  PresentationSellList,
  RejectedSellList,
  SellsInquiry,
  SellsList,
  SuspendedList,
} from "./views/Sells/index";

import { constants, purchaseStatus } from "variables/constants";

const dashboardRoutes = [
  {
    path: "/logout",
    name: "Logout",
    Component: (props) => <Logout {...props} />,
    layout: "/admin",
  },
  {
    path: "/login",
    name: "Login",
    Component: (props) => <Login {...props} />,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Registro",
    Component: (props) => <Register {...props} />,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    Component: (props) => <Dashboard {...props} />,
    layout: "/admin",
  },
  {
    path: "/dashboard-notary",
    name: "DashboardNotary",
    Component: (props) => <DashboardNotary {...props} />,
    layout: "/admin",
  },
  {
    path: "/dashboard-agent",
    name: "DashboardAgent",
    Component: (props) => <DashboardAgent {...props} />,
    layout: "/admin",
  },
  {
    path: "/users/list",
    name: "Usuarios",
    Component: (props) => <UsersList {...props} />,
    layout: "/admin",
  },
  {
    path: "/users/create",
    name: "Usuarios",
    Component: (props) => <UsersCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/users/:id/edit",
    name: "Usuarios",
    Component: (props) => <UsersEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/users/:id/password",
    name: "Usuarios",
    Component: (props) => <UsersPassEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/functions/list",
    name: "Funciones",
    Component: (props) => <FunctionsList {...props} />,
    layout: "/admin",
  },
  {
    path: "/functions/create",
    name: "Funciones",
    Component: (props) => <FunctionsCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/functions/:id/edit",
    name: "Funciones",
    Component: (props) => <FunctionsEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/profiles/list",
    name: "Perfiles",
    Component: (props) => <ProfilesList {...props} />,
    layout: "/admin",
  },
  {
    path: "/profiles/create",
    name: "Perfiles",
    Component: (props) => <ProfilesCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/profiles/:id/edit",
    name: "Perfiles",
    Component: (props) => <ProfilesEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/permissions/list",
    name: "Permisos",
    Component: (props) => <PermissionsList {...props} />,
    layout: "/admin",
  },
  {
    path: "/permissions/create",
    name: "Permisos",
    Component: (props) => <PermissionsCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/permissions/:id/edit",
    name: "Permisos",
    Component: (props) => <PermissionsEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/groups/list",
    name: "Groups",
    Component: (props) => <GroupsList {...props} />,
    layout: "/admin",
  },
  {
    path: "/groups/create",
    name: "Groups",
    Component: (props) => <GroupsCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/groups/:id/edit",
    name: "Groups",
    Component: (props) => <GroupsEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/groups/:id/edit/users",
    name: "Groups",
    Component: (props) => <GroupsUsersEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/mapa-de-registros",
    name: "Mapa de Registros",
    Component: (props) => <MapIssuerEntity {...props} />,
    layout: "/admin",
  },
  {
    path: "/management/toma-vehiculo",
    name: "Toma",
    Component: (props) => <TakeList {...props} />,
    layout: "/admin",
  },
  {
    path: "/management/validar-patente",
    name: "Patente",
    Component: (props) => <PlateValidate {...props} />,
    layout: "/admin",
  },
  {
    path: "/notary-offices/list",
    name: constants.notaryOffices,
    Component: (props) => <NotaryOfficesList {...props} />,
    layout: "/admin",
  },
  {
    path: "/notary-offices/create",
    name: constants.notaryOffices,
    Component: (props) => <NotaryOfficesCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/notary-offices/:id/edit",
    name: constants.notaryOffices,
    Component: (props) => <NotaryOfficesEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/vehicle/create",
    name: constants.vehicle,
    Component: (props) => <TakeCreate {...props} />,
    layout: "/admin",
  },
  {
    path: "/status-form-08/list",
    name: constants.form08,
    Component: (props) => <StatusForm08List {...props} />,
    layout: "/admin",
  },
  {
    path: "/folio-in-progress/list",
    name: constants.folioInProgress,
    Component: (props) => <FolioInProgressList {...props} />,
    layout: "/admin",
  },
  {
    path: "/general/list",
    name: constants.general,
    Component: (props) => <GeneralList {...props} />,
    layout: "/admin",
  },
  {
    path: "/presentation/list",
    name: constants.presentation,
    Component: (props) => <PresentationList {...props} />,
    layout: "/admin",
  },
  {
    path: "/rejected/list",
    name: purchaseStatus.REJECTED.text,
    Component: (props) => <RejectedList {...props} />,
    layout: "/admin",
  },
  {
    path: "/suspended/list",
    name: purchaseStatus.REJECTED.text,
    Component: (props) => <PurchaseSuspendedList {...props} />,
    layout: "/admin",
  },
  {
    path: "/holidays",
    name: constants.holidays,
    Component: (props) => <Holidays {...props} />,
    layout: "/admin",
  },
  {
    path: "/inquiry",
    name: constants.inquiry,
    Component: (props) => <Inquiry {...props} />,
    layout: "/admin",
  },
  {
    path: "/issuer-entity/list",
    name: "Registros",
    Component: (props) => <IssuerEntity {...props} />,
    layout: "/admin",
  },
  {
    path: "/issuer-entity/:id/edit",
    name: "Registros",
    Component: (props) => <IssuerEntityEdit {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/list",
    name: "ventas",
    Component: (props) => <SellsList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/apertura-folio/list",
    name: "apertura-folio",
    Component: (props) => <AperturaFolioList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/presentacion/list",
    name: "presentación",
    Component: (props) => <PresentationSellList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/general/list",
    name: "general",
    Component: (props) => <GeneralSellList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/suspendidos/list",
    name: "Suspendidos",
    Component: (props) => <SuspendedList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/rechazados/list",
    name: "Rechazados",
    Component: (props) => <RejectedSellList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/inquiry",
    name: constants.inquiry,
    Component: (props) => <SellsInquiry {...props} />,
    layout: "/admin",
  },
  {
    path: "/settings/trade-ids",
    name: "Configuración",
    Component: (props) => <SettingsConfig {...props} />,
    layout: "/admin",
  },
  {
    path: "/vtv/list",
    name: "VTV",
    Component: (props) => <VTV {...props} />,
    layout: "/admin",
  },
  {
    path: "/bacs/list",
    name: "BACS",
    Component: (props) => <BACS {...props} />,
    layout: "/admin",
  },
  {
    path: "/rendiciones/list",
    name: "Rendiciones",
    Component: (props) => <RenditionList {...props} />,
    layout: "/admin",
  },
  {
    path: "/ventas/financed",
    name: "Prendados",
    Component: (props) => <FinancedList {...props} />,
    layout: "/admin",
  },
  {
    path: "/print-forms",
    name: "Impresiones",
    Component: (props) => <PrintForms {...props} />,
    layout: "/admin",
  },
  {
    path: "/print-config",
    name: "Configuración Impresiones",
    Component: (props) => <PrintConfig {...props} />,
    layout: "/admin",
  },
  {
    path: "/form-config",
    name: "Configuración Formularios",
    Component: (props) => <FormConfig {...props} />,
    layout: "/admin",
  },
  {
    path: "/adicionales/list",
    name: "Adicionales",
    Component: (props) => <AdditionalsList {...props} />,
    layout: "/admin",
  },
];

export default dashboardRoutes;
