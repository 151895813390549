import CustomAccordion from "components/Accordion";
import { InputField } from "components/Form";

const DataClient = ({ owner }) => {
  return (
    <CustomAccordion title={"DATOS CLIENTE"}>
      <div className="row">{owner && <OwnerDataClient owner={owner} />}</div>
    </CustomAccordion>
  );
};

const OwnerDataClient = ({ owner }) => {
  return (
    owner.length > 0 &&
    owner.map((client, i) => (
      <div className="col">
        <InputField
          label={`Nombre del titular ${i + 1}`}
          value={client.name}
          disabled={true}
        />
        <InputField
          label={"Porcentaje"}
          value={client.percentage}
          disabled={true}
        />
        <InputField label={"DNI"} value={client.dni} disabled={true} />
        <InputField label={"Tel"} value={client.phone} disabled={true} />
        <InputField label={"Mail"} value={client.email} disabled={true} />
        <InputField
          label={"Estado Civil"}
          value={client.ns_marital_status.description}
          disabled={true}
        />
        {client.partner_name ? (
          <InputField
            label={"Nombre Conyuge"}
            value={client.partner_name}
            disabled={true}
          />
        ) : null}
        {client.partner_dni ? (
          <InputField
            label={"DNI Conyuge"}
            value={client.partner_dni}
            disabled={true}
          />
        ) : null}
        <InputField
          label={"Direccion"}
          value={`${client.addr_street ? client.addr_street : ""} ${
            client.addr_number ? client.addr_number : ""
          } ${client.addr_zip_code ? client.addr_zip_code : ""}`}
          disabled={true}
        />
      </div>
    ))
  );
};

export default DataClient;
