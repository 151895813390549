import React, { useState, useRef, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import NotaryService from "../../../services/notary.service";
import { Card } from "../../../components/Card/Card.jsx";
import NotFound from "../../../components/Errors/NotFound.js";
import { constants } from "../../../variables/constants";
import { useToast } from "../../../hooks";
import { updateLocalStorageCache } from "../../../utils/Service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        El campo es obligatorio
      </div>
    );
  }
};

const NotaryOfficeEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const notary_office_id = params.id;
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState();
  const [resourceExist, setResourceExist] = useState(true);

  const form = useRef();
  const checkBtn = useRef();
  const addToast = useToast();

  useEffect(() => {
    NotaryService.getById(notary_office_id)
      .then((response) => {
        if (response.data.success && response.data.data) {
          setFields(response.data.data);
        }
      })
      .catch((e) => {
        setResourceExist(false);
      });
  }, [notary_office_id]);

  // Manejo generico del cambio de un dato simple de un formulario
  const onSimpleFieldChange = (fieldName, fieldValue) => {
    setFields({
      ...fields,
      [fieldName]: fieldValue === "" ? null : fieldValue,
    });
  };

  // Submit del formulario
  const handSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      NotaryService.edit({ id: notary_office_id, ...fields }).then(
        () => {
          addToast({
            variant: "success",
            header: "Escribanias",
            body: "Se actualizó la información correctamente",
          });
          // Definir a donde va una vez es exitoso el guardado
          updateLocalStorageCache().then(() => {
            navigate("/admin/notary-offices/list");
          });
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setLoading(false);
          addToast({
            variant: "danger",
            header: "Escribanias",
            body: resMessage,
          });
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <Container fluid>
        <Button
          href="/admin/notary-offices/list"
          className="btn-fill pull-left btn btn-info btn-sm capitalize"
        >
          {constants.back}
        </Button>
        <br />
        <br />
        <Row>
          <Col md={12}>
            <Card
              content={
                resourceExist ? (
                  <Form onSubmit={handSubmit} ref={form}>
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <label htmlFor="name">{constants.name}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="name"
                          value={fields?.name}
                          placeholder={constants.name}
                          onChange={(e) =>
                            onSimpleFieldChange("name", e.target.value)
                          }
                          validations={[required]}
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="description">
                          {constants.description}
                        </label>
                        <Input
                          type="text"
                          className="form-control"
                          name="description"
                          value={fields?.description}
                          placeholder={constants.description}
                          onChange={(e) =>
                            onSimpleFieldChange("description", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-4">
                        <label htmlFor="phone">{constants.phone}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="phone"
                          value={fields?.phone}
                          placeholder={constants.phone}
                          onChange={(e) =>
                            onSimpleFieldChange("phone", e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-sm-4">
                        <label htmlFor="address">{constants.address}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="address"
                          value={fields?.address}
                          placeholder={constants.address}
                          onChange={(e) =>
                            onSimpleFieldChange("address", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="number">{constants.number}</label>
                        <Input
                          type="number"
                          step="1"
                          className="form-control"
                          name="number"
                          value={fields?.number}
                          placeholder={constants.number}
                          onChange={(e) =>
                            onSimpleFieldChange("number", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-1">
                        <label htmlFor="floor">{constants.floor}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="floor"
                          value={fields?.floor}
                          placeholder={constants.floor}
                          onChange={(e) =>
                            onSimpleFieldChange("floor", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="apartment">{constants.apartment}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="apartment"
                          value={fields?.apartment}
                          placeholder={constants.apartment}
                          onChange={(e) =>
                            onSimpleFieldChange("apartment", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-2">
                        <label htmlFor="zip_code">{constants.zipCode}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="zip_code"
                          value={fields?.zip_code}
                          placeholder={constants.zipCode}
                          onChange={(e) =>
                            onSimpleFieldChange("zip_code", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-1">
                        <label htmlFor="zone">{constants.zone}</label>
                        <Input
                          type="number"
                          step="1"
                          className="form-control"
                          name="zone"
                          value={fields?.zone}
                          placeholder={constants.zone}
                          onChange={(e) =>
                            onSimpleFieldChange("zone", e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="row form-group">
                      <div className="col-sm-3">
                        <label htmlFor="province">{constants.province}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="province"
                          value={fields?.province}
                          placeholder={constants.province}
                          onChange={(e) =>
                            onSimpleFieldChange("province", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="city">{constants.city}</label>
                        <Input
                          type="text"
                          className="form-control"
                          name="city"
                          value={fields?.city}
                          placeholder={constants.city}
                          onChange={(e) =>
                            onSimpleFieldChange("city", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="latitude">{constants.latitude}</label>
                        <Input
                          type="number"
                          step="0.0000001"
                          className="form-control"
                          name="latitude"
                          value={fields?.latitude}
                          placeholder={constants.latitude}
                          onChange={(e) =>
                            onSimpleFieldChange("latitude", e.target.value)
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="longitude">{constants.longitude}</label>
                        <Input
                          type="number"
                          step="0.0000001"
                          className="form-control"
                          name="longitude"
                          value={fields?.longitude}
                          placeholder={constants.longitude}
                          onChange={(e) =>
                            onSimpleFieldChange("longitude", e.target.value)
                          }
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <button
                        className="btn btn-primary btn-fill pull-right capitalize"
                        disabled={loading}
                      >
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                        <span>Editar {constants.notaryOffice}</span>
                      </button>
                    </div>

                    <CheckButton style={{ display: "none" }} ref={checkBtn} />

                    <div className="clearfix" />
                  </Form>
                ) : (
                  <NotFound />
                )
              }
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotaryOfficeEdit;
