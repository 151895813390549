import CustomAccordion from "../../components/Accordion/index";
import { badgeGeneric } from "../../utils/Badges";

const SeeForms = ({ forms }) => {
  return (
    <div>
      <CustomAccordion
        title={`Formularios - ${forms.length}`}
        defaultOpen={true}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
          {forms &&
            forms.map((form) => {
              return (
                <div
                  key={`form_id-${
                    form?.forms?.form_code ?? `unknown-${+new Date()}`
                  }`}
                >
                  {badgeGeneric("warning", form.forms.description, true)}
                </div>
              );
            })}
        </div>
      </CustomAccordion>
    </div>
  );
};

export default SeeForms;
