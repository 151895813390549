import {constants} from "../../../../variables/constants";

export const headSort = [
    { text: "Id", field: "vehicle_id", model: null },
    { text: constants.domain, field: "plate_number", model: null },
    { text: constants.status, field: "purchase_file_status", model: null },
    // { text: "Remaining", field: null, model: null },
    { text: "Fecha de Folio in Progress", field: "updatedAt", model: "purchase_file_history" },
    { text: "Semana toma", field: "createdAt", model: null },
    { text: constants.transactionType, field: "transaction_type_id", model: null },
    { text: constants.finalPaymentDate, field: "final_payment_date", model: null },
    { text: "Aging", field: null, model: null },
    { text: "Días en tramite", field: null, model: null },
    { text: "", field: null, model: null },
  ];