import React, { useEffect, useState } from "react";
import { Button, Container, Form, Table } from "react-bootstrap";

import FormService from "../../../services/form.service";
import AuthService from "../../../services/auth.service";
import { useConfirmationAlert, useToast } from "../../../hooks";
import Card from "../../../components/Card/Card.jsx";
import ConfirmationAlert from "components/ConfirmationAlert/confirmationAlert";

const formRow = (data, onChange) => {
  const {
    form_code,
    form_id,
    description,
    use_for_purchase,
    use_for_sell,
    available,
    allow_remove,
    initial_for_purchase,
    initial_for_sell,
  } = data;
  return (
    <tr key={`row-${form_id}`}>
      <td className="text-capitalize">
        <strong>{description}</strong>
      </td>
      <td className="text-center">
        <strong>{form_code}</strong>
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={use_for_purchase}
          disabled={form_code === '08'}
          onChange={(e) =>
            onChange(form_id, "use_for_purchase", e.target.checked)
          }
        />
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={initial_for_purchase}
          disabled={form_code === '08'}
          onChange={(e) =>
            onChange(form_id, "initial_for_purchase", e.target.checked)
          }
        />
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={use_for_sell}
          disabled={form_code === '08'}
          onChange={(e) => onChange(form_id, "use_for_sell", e.target.checked)}
        />
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={initial_for_sell}
          disabled={form_code === '08'}
          onChange={(e) =>
            onChange(form_id, "initial_for_sell", e.target.checked)
          }
        />
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={allow_remove}
          disabled={form_code === '08'}
          onChange={(e) => onChange(form_id, "allow_remove", e.target.checked)}
        />
      </td>
      <td className="text-center">
        <input
          className="form-check-input"
          type="checkbox"
          value="1"
          checked={available}
          disabled={form_code === '08'}
          onChange={(e) => onChange(form_id, "available", e.target.checked)}
        />
      </td>
    </tr>
  );
};

const FormConfig = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(false);
  const addToast = useToast();
  const {
    confirmationModal,
    handleConfirmationModal,
    handleCloseConfirmationModal,
  } = useConfirmationAlert();

  const onUpdateForm = (id, key, value) => {
    setForms(
      forms.map((form) =>
        form.form_id === id ? { ...form, [key]: value } : form
      )
    );
  };

  const onSaveForms = () => {
    FormService.saveConfig(forms)
      .then((response) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        addToast({
          variant: "success",
          header: "Configuración de Formularios",
          body: "Se actualizaron los formularios correctamente",
        });
        handleCloseConfirmationModal();
      })
      .catch((error) => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        addToast({
          variant: "danger",
          header: "Configuración de Formularios",
          body: "Ah ocurrido un error, no se actualizaron los formularios. Intente nuevamente.",
        });
        handleCloseConfirmationModal();
      });
  };

  useEffect(() => {
    setLoading(true);
    const permissions = AuthService.getCurrentPermissions(
      "CONFIGURACION FORMULARIOS"
    );
    setCurrentPermissions(permissions);

    const params = {
      currentPage: 0,
      countItems: 1000,
      available: null,
      use_for_purchase: null,
      use_for_sell: null,
    };
    FormService.getAll(params)
      .then((response) => {
        if (response.data.count > 0) {
          setForms(response.data.response);
        } else {
          setForms([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setForms([]);
        setLoading(false);
      });
  }, []);

  return (
    <div className="content">
      <Card
        ctTableFullWidth
        ctTableResponsive
        title="Configuración de Formularios"
        content={
          loading ? (
            "Loading..."
          ) : !forms.length ? (
            "Sin datos disponibles."
          ) : (
            <Table striped hover>
              <thead>
                <tr>
                  <td>Formulario</td>
                  <td className="text-center">Código</td>
                  <td colSpan={2}>
                    <Table>
                      <tr>
                        <td colSpan={2} className="text-center">
                          Compras
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Disponible</td>
                        <td className="text-center">Inicial</td>
                      </tr>
                    </Table>
                  </td>
                  <td colSpan={2}>
                    <Table>
                      <tr>
                        <td colSpan={2} className="text-center">
                          Ventas
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Disponible</td>
                        <td className="text-center">Inicial</td>
                      </tr>
                    </Table>
                  </td>
                  <td className="text-center">Se puede eliminar</td>
                  <td className="text-center">Visible</td>
                </tr>
              </thead>
              <tbody>{forms?.map((f) => formRow(f, onUpdateForm))}</tbody>
              {currentPermissions?.permissions?.hasUpdate ? (
                <tfoot>
                  <tr>
                    <td colSpan={8}>
                      <Button
                        className="btn-fill btn btn-success capitalize"
                        onClick={() =>
                          handleConfirmationModal(
                            "",
                            () => onSaveForms(),
                            "¿Desea modificar los formularios?",
                            "Al confirmar, se actualizarán los formularios."
                          )
                        }
                      >
                        Guardar
                      </Button>
                    </td>
                  </tr>
                </tfoot>
              ) : null}
            </Table>
          )
        }
      />
      {confirmationModal.open && (
        <ConfirmationAlert
          show={confirmationModal.open}
          handleAction={confirmationModal.handleAction}
          handleClose={confirmationModal.handleClose}
          message={confirmationModal.message}
          title={confirmationModal.title}
        />
      )}
    </div>
  );
};

export default FormConfig;
