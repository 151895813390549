import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ExcelButton from "../../../components/ExcelButton";
import Filters from "../../../components/Filters/index";
import Edit from "../../../components/Modal/suspended/index";
import List from "../../../components/Table/List";
import { useGrid, useStatusModal } from "../../../hooks/index";
import Modal from "../../../layouts/ModalDisplay";
import authService from "../../../services/auth.service";
import SuspendedService from "../../../services/purchase/suspended.service";
import {
  badgeGeneric,
  diffDateBadge,
  statusBadge,
  twoBadges,
} from "../../../utils/Badges";
import { employees } from "../../../utils/Service";
import { purchase_status_select } from "../../../variables/constants";
import { FINANCED_STATUS_NAME } from "../../../variables/sells/index";
import styles from "../General/styles.module.scss";
import { headSuspended } from "./constants/columns";

const filterFields = {
  plate_number: {
    type: "text",
    name: "plate_number",
    placeholder: "Dominio",
    value: "",
    size: 2,
  },
  person_in_charge: {
    type: "select",
    name: "person_in_charge",
    options: employees(),
    placeholder: "Persona Responsable",
    value: "",
    size: 2,
  },
  status: {
    type: "multiSelect",
    name: "status",
    options: purchase_status_select.filter((x) => x.suspended === true),
    placeholder: "Estado",
    value: [],
    size: 8,
  },
};

const sortColumns = (body) => {
  return body.map((e) => ({
    plate_number: twoBadges(
      e.plate_number,
      e.financed ? FINANCED_STATUS_NAME.FINANCED : null
    ),
    stock_id: e.vehicle_id,
    status: statusBadge(e.status),
    suspended_date: e.suspended_date,
    person_in_charge_id: e.person_in_charge_id,
    suspended_period: badgeGeneric("info", e.suspended_period),
    aging: diffDateBadge(e.aging),
    diff_days: diffDateBadge(e.diff_days),
    past_suspended_date: e.past_suspended_date,
  }));
};

const sort = {
  model: null,
  field: "vehicle_id",
  type: "ASC",
};

const PurchaseSuspendedList = (props) => {
  const { handleCloseEditModal, handleOpenEditModal, showEditModal } =
    useStatusModal();

  const {
    body,
    countItems,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleSort,
    handleToPage,
    handleTotalItems,
    handleTotalPage,
    cleanPage,
  } = useGrid(sort);

  const [refresh, setRefresh] = useState(new Date());
  const [dataModal, setDataModal] = useState([]);
  const [filters, setFilters] = useState(filterFields);
  const [currentPermissions, setCurrentPermissions] = useState(null);

  const next = {
    link: "/admin/management/toma-vehiculo",
    text: "Toma",
  };

  useEffect(() => {
    const permissions = authService.getCurrentPermissions("VENTAS");
    setCurrentPermissions(permissions);
  }, []);

  const onFilterChange = (filterName, filterValue) => {
    setFilters({
      ...filters,
      [filterName]: { ...filters[filterName], value: filterValue },
    });
    cleanPage();
  };

  const refreshGrid = () => {
    setRefresh(new Date());
  };

  const openEditModal = (id) => {
    SuspendedService.getById(id).then((response) => {
      if (response.data.success) {
        setDataModal(response.data);
        handleOpenEditModal();
      }
    });
  };

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems, // like countItems
      sortModel: currentSort?.model,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      filters,
    };

    SuspendedService.getAll(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, countItems, filters, refresh]);

  const actions = (props) => {
    return (
      <>
        {currentPermissions && currentPermissions.permissions.hasSelect && (
          <Button
            onClick={(e) => openEditModal(props.stock_id, e)}
            className="btn-simple btn btn-xs btn-secondary"
          >
            <i className="fas fa-file-alt" aria-hidden="true"></i>
          </Button>
        )}
      </>
    );
  };

  return (
    <div className="content">
      <div className={styles.export}>
        <ExcelButton type="ventasSuspendidos" filters={filters} />
      </div>
      <Filters filterFields={filters} onFilterChange={onFilterChange} />
      <List
        thead={headSuspended}
        tbody={body}
        actions={actions}
        next={next}
        totalItems={totalItems}
        currentPage={currentPage}
        totalPage={totalPage}
        toPage={handleToPage}
        handleSort={handleSort}
        sortable
        currentSort={currentSort}
      />
      {showEditModal && (
        <Modal
          content={
            <Edit
              data={dataModal}
              handleClose={handleCloseEditModal}
              refresh={refreshGrid}
            />
          }
          showModal={showEditModal}
          handleShow={openEditModal}
          handleClose={handleCloseEditModal}
        />
      )}
    </div>
  );
};

export default PurchaseSuspendedList;
