import React, { useEffect, useRef, useState } from "react";
import { Badge, Button, Container } from "react-bootstrap";
import AuthService from "../../../services/auth.service";
import ManagementService from "../../../services/management.service";
import Filters from "../../../components/Filters";
import ChecklistInfo from "../../../components/Modal/Checklist/Checklist";
import RejectContent from "../../../components/Modal/Reject/content";
import List from "../../../components/Table/List";
import ExcelButton from "../../../components/ExcelButton";
import ButtonIcon from "../../../components/ButtonIcon";
import { useFilters, useGrid, useStatusModal, useToast } from "../../../hooks";
import Modal from "../../../layouts/ModalDisplay";
import {
  alertBadge,
  diffDateBadge,
  // remainingTimeBadge,
  SDPBadge,
  statusBadge,
} from "../../../utils/Badges";
import {
  constants,
  purchaseStatus,
  serviceTypes,
} from "../../../variables/constants";
import styles from "../General/styles.module.scss";
import { headSort } from "./constants/constant_columns";
import { filtersTake } from "./constants/constant_filters";

const dropdownStatus = Object.entries(purchaseStatus)
  .filter((s) => s[1].group === "take")
  .map((s) => s[1]);

const TakeList = (props) => {
  const [currentPermissions, setCurrentPermissions] = useState(null);
  const [dataModal, setDataModal] = useState(null);
  const addToast = useToast();

  const {
    handleCloseAddModal,
    handleOpenAddModal,
    showAddModal,
    handleCloseRejectModal,
    handleShowRejectModal,
    showRejectModal,
  } = useStatusModal();

  const {
    countItems,
    handleToPage,
    handleSort,
    handleTotalPage,
    body,
    currentPage,
    currentSort,
    totalItems,
    totalPage,
    handleBody,
    handleTotalItems,
    cleanPage,
    refresh,
    refreshGrid,
  } = useGrid({
    model: null,
    field: "vehicle_id",
    type: "ASC",
  });

  const { filters, onFilterChange } = useFilters(filtersTake, cleanPage);

  useEffect(() => {
    const permissions = AuthService.getCurrentPermissions("TOMA");
    setCurrentPermissions(permissions);
  }, []);

  useEffect(() => {
    const params = {
      page: currentPage,
      limit: countItems,
      filters,
      sortField: currentSort?.field,
      sortType: currentSort?.type,
      sortModel: currentSort?.model,
    };
    ManagementService.getAllCheckList(params).then((response) => {
      handleTotalItems(response.data.totalItems);
      handleTotalPage(Math.ceil(response.data.totalItems / countItems));
      handleBody(sortColumns(response.data.response));
    });
    //eslint-disable-next-line
  }, [currentPage, currentSort, refresh, countItems, filters]);

  const next = {
    link: "/admin/folio-in-progress/list",
    text: "Apertura Folio",
  };

  const sortColumns = (body) =>
    body.map((e) => ({
      vehicle_id: e.vehicle_id,
      plate_number: SDPBadge(e.plate_number, e.sdp),
      status: statusBadge(e.status),
      week: e.week,
      reception_date: e.reception_date,
      recived_date: e.recived_date,
      signing_form_08_date: e.signing_form_08_date,
      loss_request: e.loss_request,
      final_payment_date: e.final_payment_date,
      // count_validation: e.count_validation,
      // remaining_time: remainingTimeBadge(e.remaining_time),
      current_location: e.current_location,
      issuer_entity: e.issuer_entity,
      zone: e.zone,
      reception_place: e.reception_place,
      distance: e.distance,
      // alert: alertBadge(e.alert),
      aging: diffDateBadge(e.aging),
      diff_days: diffDateBadge(e.diff_days),
    }));

  const handleShowModal = (id, e) => {
    e.preventDefault();
    ManagementService.getChecklistModalDataById(id).then((response) => {
      setDataModal(response.data);
      if (response.data.success) {
        handleOpenAddModal();
      }
    });
  };

  const handleModifiedFormsValidated = (forms) => {
    ManagementService.saveformsValidated(forms).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma",
          body: "Se actualizó la información correctamente",
        });
        refreshGrid();
      }
    });
  };

  const handleModifiedStatus = (s) => {
    ManagementService.edit(s).then((response) => {
      if (response.success) {
        if (
          dropdownStatus
            .map((s) => s.value)
            .concat(purchaseStatus.REJECTED.value)
            .includes(s.purchase_file_status)
        ) {
          addToast({
            variant: "success",
            header: "Toma",
            body: "Se actualizó la información correctamente",
          });
          refreshGrid();
        }
      }
    });
  };

  const handleShowModalReject = (id, e) => {
    e.preventDefault();
    handleShowRejectModal(id);
  };

  const handleDelete = (params) => {
    ManagementService.edit({
      ...params,
      purchase_file_status: purchaseStatus.REJECTED.value,
    }).then((response) => {
      if (response.success) {
        addToast({
          variant: "success",
          header: "Toma",
          body: "Se rechazó el tramite correctamente",
        });
        refreshGrid();
      }
    });
  };

  const actions = (props) => (
    <>
      {currentPermissions && currentPermissions.permissions.hasSelect ? (
        <ButtonIcon
          onClick={(e) => handleShowModal(props.vehicle_id, e)}
          name="file-alt"
          title="Editar"
        />
      ) : null}
      {currentPermissions && currentPermissions.permissions.hasDelete ? (
        <ButtonIcon
          onClick={(e) => handleShowModalReject(props.vehicle_id, e)}
          name="times"
          color="danger"
          size="lg"
          title="Rechazar"
        />
      ) : null}
    </>
  );

  return (
    <div className="content">
      <Container fluid>
        <div className={`content-filters form-group ${styles.lessHeight}`}>
          <Button
            href="/admin/vehicle/create"
            className="btn-fill btn btn-primary capitalize"
          >
            {constants.createVehicleManually}
          </Button>
          <ExcelButton type="comprasToma" filters={filters} />
        </div>
        <Filters filterFields={filters} onFilterChange={onFilterChange} />
        <List
          thead={headSort}
          tbody={body}
          actions={actions}
          next={next}
          totalItems={totalItems}
          currentPage={currentPage}
          totalPage={totalPage}
          toPage={handleToPage}
          handleSort={handleSort}
          sortable
          currentSort={currentSort}
        />
        {showAddModal && (
          <Modal
            content={
              <ChecklistInfo
                data={dataModal}
                handleModifiedStatus={handleModifiedStatus}
                handleModifiedFormsValidated={handleModifiedFormsValidated}
                handleClose={handleCloseAddModal}
              />
            }
            showModal={showAddModal}
            handleShow={handleShowModal}
            handleClose={handleCloseAddModal}
            headerTitle={
              <>
                {dataModal?.response?.sdp ? (
                  <Badge bg="warning">{constants.sdp}</Badge>
                ) : (
                  ""
                )}
                {dataModal?.response?.manual ? (
                  <Badge bg="danger">{constants.vehicleSaveManually}</Badge>
                ) : (
                  ""
                )}
              </>
            }
          />
        )}
        {showRejectModal.show && (
          <Modal
            content={
              <RejectContent
                data={showRejectModal}
                handleDelete={handleDelete}
                handleClose={handleCloseRejectModal}
              />
            }
            showModal={showRejectModal.show}
            handleShow={handleShowModalReject}
            handleClose={handleCloseRejectModal}
          />
        )}
      </Container>
    </div>
  );
};

export default TakeList;
