import React, { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import Information from "./information";
import HistoryStatus from "../../Purchase/Inquiry/historyStatus";
import SellsService from "../../../services/sells/sells.service";
import { status08Badge, genericBadge } from "../../../utils/Badges";
import { constants, sells_status } from "../../../variables/constants";
import { financed_status } from "../../../variables/sells";
import ModalDisplay from "../../../layouts/ModalDisplay";
import InquiryEdit from "../../../components/Modal/Sells/inquiry";
import FormRelationship from "../../../components/Modal/StatusForm08/FormRelationship";

const profileAllowEdit = [
  constants.adminProfile,
  constants.coordinatorProfile,
  constants.sellsBackofficeProfile,
];

const SellsInquiry = (props) => {
  const [info, setInfo] = useState(false);
  const [infoHistory, setInfoHistory] = useState(false);
  const [info08History, setInfo08History] = useState(false);
  const [infoFinanced, setInfoFinanced] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(Date.now());
  const [profile, setProfile] = useState(null);

  const refreshGrid = () => {
    setRefresh(new Date());
  };

  // REGISTROS
  const [domains, setDomains] = useState([]);
  // const [vehicleId, setVehicleId] = useState(null);

  const [open, setOpen] = useState(false);
  const [open08, setOpen08] = useState(false);

  useEffect(() => {
    if (info) {
      getInquiry(info.opportunity_id);
    }
    //eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user")) || null;
    setProfile(user.data.user.profile.name.toLowerCase());
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openModal08 = () => {
    setOpen08(true);
  };

  const onClose08 = () => {
    setOpen08(false);
  };

  const getInquiry = (id) => {
    setLoading(true);
    SellsService.getInquiryById(id).then((response) => {
      const { history, form08History, financed, ...rest } =
        response.data.response;
      setInfo(rest);
      setInfoHistory(
        history.map((h) => ({
          ...h,
          status: genericBadge(sells_status, h.history_status),
        }))
      );
      setInfo08History(
        form08History.map((h) => ({
          ...h,
          status: status08Badge(h.current_status),
        }))
      );
      setInfoFinanced(
        financed.map((h) => ({
          ...h,
          status: genericBadge(financed_status, h.history_status),
        }))
      );
      setLoading(false);
    });
  };

  // Selector de emisor
  const onSelect = (selectedList, selectedItem) => {
    // setVehicleId(selectedItem.opportunity_id);
    getInquiry(selectedItem.opportunity_id);
  };

  // Eliminación del emisor
  const onRemove = () => {
    // setVehicleId(null);
    setDomains([]);
    setInfo(false);
    setInfoHistory(false);
    setInfo08History(false);
    setInfoFinanced(false);
  };

  // Buscador del emisor
  const onSearch = (word) => {
    if (word.length > 1) {
      const params = { limit: 1000, search: word };
      SellsService.getAllDomains(params).then((response) => {
        let rows = [];
        if (response.data.success && response.data.count > 0) {
          rows = response.data.response.map((data) => ({
            opportunity_id: data.opportunity_id,
            name: `${data.opportunity_id} - ${data.plate_number} - ${data.make} ${data.model} - ${data.owner}`,
          }));
        }
        setDomains(rows);
      });
    }
  };

  return (
    <div className="content content-plate-validation">
      <Container fluid>
        <div className="form-group mb-2" style={{ marginRight: "20px" }}>
          <Multiselect
            name="search"
            options={domains}
            onSelect={onSelect}
            onRemove={onRemove}
            onSearch={onSearch}
            displayValue="name"
            placeholder="Seleccione un dominio"
            closeIcon="cancel"
            selectionLimit="1"
            showCheckbox={false}
            singleSelect={false}
          />
        </div>
        {profileAllowEdit.includes(profile) &&
        domains.length > 0 &&
        !loading ? (
          <div style={{ paddingBottom: 10 }}>
            <Button onClick={openModal}>Actualizar información</Button>
            <Button
              style={{ marginLeft: 8 }}
              onClick={openModal08}
              variant="info"
            >
              Formularios 08
            </Button>
          </div>
        ) : null}
        <div className="content-information">
          <Information data={info} loading={loading} />
        </div>
        <div className="content-information">
          <HistoryStatus
            data={infoHistory}
            title="Venta - Cambios Históricos de Estados"
            loading={loading}
          />
        </div>
        <div className="content-information">
          <HistoryStatus
            data={info08History}
            title="Formulario 08 - Cambios Históricos de Estados"
            loading={loading}
          />
        </div>
        {!!infoFinanced.length && (
          <div className="content-information">
            <HistoryStatus
              data={infoFinanced}
              title="Prendado - Cambios Históricos de Estados"
              loading={loading}
            />
          </div>
        )}
      </Container>

      {open ? (
        <ModalDisplay
          content={
            <InquiryEdit
              handleClose={onClose}
              id={info.opportunity_id}
              data={info}
              refresh={refreshGrid}
            />
          }
          showModal={open}
          handleShow={openModal}
          handleClose={onClose}
          headerTitle={
            <span style={{ fontWeight: "bold" }}>Actualizar ventas</span>
          }
        />
      ) : null}

      {open08 ? (
        <ModalDisplay
          content={
            <FormRelationship
              handleClose={onClose08}
              opportunity_id={info.opportunity_id}
              data={info}
              refresh={refreshGrid}
            />
          }
          showModal={open08}
          handleShow={openModal08}
          handleClose={onClose08}
          headerTitle={
            <span
              style={{ fontWeight: "bold" }}
            >{`Actualizar Formulario 08 - ${info.plate_number} (${info.opportunity_id})`}</span>
          }
        />
      ) : null}
    </div>
  );
};

export default SellsInquiry;
